import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'


export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'Dashboard'})} to='/dashboard' />
      <MenuItem title={intl.formatMessage({id: 'Plans' })} to='/price-plan' />
      <MenuItem title={intl.formatMessage({id: 'Services' })} to='/services' />
      <MenuItem title={intl.formatMessage({id: 'Ticket' })} to='/ticket' />
      
    </>
  )
}
