import React from 'react'
import './Loading.css'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
const PageLoading = () => {
  return (
    <div className="loader_nuclei-loader__1w7x9 position-absolute"><span></span><span></span><span></span><span></span></div>
  )
}

export default PageLoading
