import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {FormikErrors, useFormik} from 'formik'
import axios from 'axios'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
}


export function ForgotPassword () {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const intl = useIntl()
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'Wrong email format'}))
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Email is required'})),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, setErrors}) => {
      setLoading(true)

      fetchForgotPassword(values, setStatus, setSubmitting, setErrors)
    },
  })
  const fetchForgotPassword = async (
    values: {email: string},
    setStatus: (status?: any) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (
      errors: FormikErrors<{
        email: string
      }>
    ) => void
  ) => {
    setStatus('')
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/users/reset_password/`,
        {
          email: values.email,
        },
        {withCredentials: false}
      )
      if (data?.status >= 200 && data.status < 300) {
        setStatus('please confirm your email')
        setHasErrors(false)
      } else {
        // console.error(`error in login \n`, data)
        setStatus('The si detail is incorrect')
        setHasErrors(true)
      }
      setLoading(false)
    } catch (e: any) {
      setErrors(e?.response?.data)
      console.log('aaa', e?.response.data)
      setLoading(false)
      setSubmitting(false)
      setStatus(e?.message || 'The sign up detail is incorrect')
      setHasErrors(true)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <div className='card'>
        <div className='card-body py-5 px-md-5'>
          <div className='d-flex justify-content-center flex-column align-items-center mb-10 p-8 rounded'>
            <h1>{intl.formatMessage({id: 'Forgot Password'})}</h1>
            {formik.status ? (
              <div
                className={`font-weight-bold alert-text ${
                  hasErrors ? 'alert-danger' : 'alert-success'
                }`}
              >
                {intl.formatMessage({id: formik.status})}
                
              </div>
            ) : (
              <span className='opacity-75'>
                {intl.formatMessage({id: 'Enter your email address to reset your password.'})}
                </span>
            )}
          </div>
          <form noValidate id='kt_login_password_reset_form' onSubmit={formik.handleSubmit}>
            <div data-mdb-input-init className='form-outline mb-4'>
              <input
              dir="ltr"
                type='email'
                id='form3Example3'
                className={clsx(
                  'form-control',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                {...formik.getFieldProps('email')}
              />

              <div className='d-flex align-items-center justify-content-between py-1'>
                <label className='form-label m-0' htmlFor='form3Example3'>
                {intl.formatMessage({id: 'Email address'})}
                  
                </label>

                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container text-center text-danger'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
            </div>

            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-block btn-primary w-100'
              disabled={loading || !formik.isValid}
              style={{opacity: 0.9}}
            >
              {!loading && <span className='indicator-label'>
                {intl.formatMessage({id: 'Submit'})}
                </span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Please wait'})}...
                  <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
                </span>
              )}
            </button>
          </form>
          <hr className='w-100' />
          <div className='form-group d-flex flex-wrap justify-content-between align-items-center px-8 mt-5'>
            <Link to='/auth' id='kt_login_forgot' className=' font-weight-bold'>
            {intl.formatMessage({id: 'Cancel'})}
              
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
