import React, {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios from 'axios'
import clsx from 'clsx'
import useErrorHandling from '../../../Utils/useErrorHandling'
import * as Yup from 'yup'

export default function ModalDetails ({
  getData,
  setErr,
  setSuccess,
  id,
  setCreateLoading
}: {
  getData: (page?: number) => Promise<void>
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setCreateLoading: React.Dispatch<React.SetStateAction<boolean>>
  setErr: React.Dispatch<React.SetStateAction<string>>
  id: number | string | null
}) {
  const intl = useIntl()
  const [base64, setBase64] = useState<string | ArrayBuffer | null>(null)
  const [loading, setLoading] = useState(false)

  const errorHandling = useErrorHandling()
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        const base64String = reader.result
        setBase64(base64String)
      }
      reader.readAsDataURL(file)
    }
  }
  const loginSchema = Yup.object().shape({
    content: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .required(intl.formatMessage({id: 'Comment is required'})),
  })
  const formik = useFormik({
    initialValues: {content: ''},
    validationSchema: loginSchema,
    onSubmit: (values) => {
      if (id !== null) {
        setLoading(true)
        setCreateLoading(true)
        axios
          .post(`${process.env.REACT_APP_API_ENDPOINT}/ticket/${id}/comment/`, {
            ...values,
            attachment: base64 ? base64 : null,
          })
          .then((res) => {
            errorHandling(res.status)
            if (res.status >= 200 && res.status < 300) {
              getData()
              formik.resetForm()
              setBase64('')
              setSuccess(true)
              setErr('')
            } else {
              setSuccess(false)
              setErr(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
            }
          })
          .catch((e) => {
            setSuccess(false)
            console.log('message \n', e?.response?.data?.error)
            setErr(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
            setLoading(false)
          }).finally(()=>{
            setCreateLoading(false)
          })
      }
    },
  })

  return (
    <>
      <div
        className={`modal fade `}
        id='kt_modal_ticket_details'
        role='dialog'
        aria-bs-labelledby='staticBackdrop'
        
        data-bs-backdrop='static'
        style={{backdropFilter: 'blur(3px)'}}
      >
        <div className='modal-dialog' style={{minWidth: '50%'}}>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'> {intl.formatMessage({id: 'New Comment'})}</h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  formik.resetForm()
                  setBase64('')
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder'>
                      {intl.formatMessage({id: 'Comment'})}
                    </label>
                    <textarea
                      placeholder={intl.formatMessage({id: 'Comment'})}
                      {...formik.getFieldProps('content')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.content && formik.errors.content,
                        },
                        {
                          'is-valid': formik.touched.content && !formik.errors.content,
                        }
                      )}
                      name='content'
                      autoComplete='off'
                    />
                    {formik.touched.content && formik.errors.content && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.content}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <label className='form-label' htmlFor='customFile'>
                    {intl.formatMessage({id: 'Image Attachment'})}
                      
                    </label>
                    <input
                      onChange={handleFileChange}
                      type='file'
                      accept='image/png, image/jpeg'
                      className={clsx('form-control form-control-lg form-control-solid')}
                      id='customFile'
                    />
                  </div>
                  {process.env.REACT_APP_MODE === 'demo' && (
                    <p className='text-danger'>
                      {' '}
                      {intl.formatMessage({id: 'This feature is not available in demo mode'})}
                    </p>
                  )}
                  <button
                    // type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-success'
                    data-bs-dismiss='modal'
                    // aria-label='Close'
                    disabled={process.env.REACT_APP_MODE === 'demo' || loading}
                  >
                    {!loading && (
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'Send Comment'})}
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        {intl.formatMessage({id: 'Please wait'}) + '...'}
                        <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
