import {Edge, Node, Position} from 'react-flow-renderer'
import Bug from '../../../pages/images/Bug'
import Earth from '../../../pages/images/Earth'
import File from '../../../pages/images/File'
import Iphone from '../../../pages/images/Iphone'
import Key from '../../../pages/images/Key'
import List from '../../../pages/images/List'
import Loc from '../../../pages/images/Loc'
import MailAddress from '../../../pages/images/MailAddress'
import Port from '../../../pages/images/Port'
import Tech from '../../../pages/images/Tech'
import {KTIcon} from '../../../../_metronic/helpers'
interface CustomEdge extends Edge {
  markerEnd?: string // Define markerEnd as an optional string
}
const EXP_TIME = 3727004031975
const USER = {
  is_user_new: false,
  is_user_scan: true,
  created_at: '2022-07-14T11:37:39.000000Z',
  email: 'admin@demo.com',
  email_verified_at: '2022-07-14T11:37:39.000000Z',
  first_name: 'German',
  id: 2,
  last_name: 'Stark',
  updated_at: '2022-07-14T11:37:39.000000Z',
  has_new_notification: false,
}
const DASHBOARD_DATA = {
  total_score: 'A',
  dns_score: 82,
  recent_vulnerabilities: [
    {
      id: 42338,
      title: 'Unrestricted File Upload',
      tag: 'A',
      severity: 'critical',
      timestamp: '2024-09-18T15:17:41.651066Z',
    },
    {
      id: 42337,
      title: 'SQL Injection',
      tag: 'A',
      severity: 'critical',
      timestamp: '2024-09-18T15:17:41.651041Z',
    },
  ],
  total_assets: {
    assets: 616,
    assets_over_time: {
      x: ['2022-12-09', '2023-06-17', '2024-09-16', '2024-09-17', '2024-09-18', '2024-09-19', null],
      y: [32, 64, 109, 56, 204, 48, 0],
    },
  },
  total_vulnerabilities: {
    vulnerabilities: 21,
    vulnerabilities_over_time: {
      x: ['2022-12-09', '2023-06-17', '2024-09-18'],
      y: [30, 120, 100],
    },
  },
  severe_vulnerabilities: {
    vulnerabilities: 6,
    vulnerabilities_over_time: {
      x: ['2022-12-09', '2023-06-17', '2024-09-18'],
      y: [1, 2, 3],
    },
  },
  vulnerabilities_based_severity: {
    x: ['2022-12-09', '2023-06-17', '2024-09-18'],
    y: {
      info: [1, 3, 2],
      low: [7, 2, 5],
      medium: [2, 5, 3],
      high: [9, 12, 5],
      critical: [8, 11, 12],
    },
  },
  assets_at_risk: [
    {
      id: 38,
      address: 'cdn.targetA.com',
      total_vulnerabilities: 1,
      vulnerabilities_over_time: {
        x: ['2022-12-09'],
        y: [7],
      },
    },
    {
      id: 39,
      address: 'filmofood.targetB.com',
      total_vulnerabilities: 3,
      vulnerabilities_over_time: {
        x: ['2022-12-09'],
        y: [11],
      },
    },
  ],
  assets_by_grade: [543, 0, 60, 13, 0],
  host_by_country: [
    {
      ticker: 'DE',
      value: 521,
    },
    {
      ticker: 'IR',
      value: 88,
    },
  ],
  ssl_exp: 21,
}
const TARGET_DATA = {
  total_pages: 1,
  current_page: 1,
  results: [
    {
      id: 308,
      score: 'A',
      target: 'targetA.com',
      assets: 48,
      vulnerabilities: {
        low: 0,
        high: 12,
        info: 11,
        medium: 20,
        critical: 4,
      },
      timestamp: '2024-09-19 11:43:56.542230+00:00',
    },
    {
      id: 4,
      score: 'C',
      target: 'targetB.com',
      assets: 2066,
      vulnerabilities: {
        low: 12,
        high: 40,
        info: 10,
        medium: 240,
        critical: 25,
      },
      timestamp: '2024-09-19 09:24:52.608762+00:00',
    },
  ],
}
const ASSET_DATA = {
  total_pages: 1,
  current_page: 1,
  results: [
    {
      id: 8781,
      score: 'A',
      host: 'www.dl.targetA.com',
      total_vulnerabilities: 0,
      vulnerabilities: {
        low: 2,
        high: 12,
        info: 5,
        medium: 11,
        critical: 6,
      },
      timestamp: '2024-09-19 11:43:51.468721+00:00',
    },
    {
      id: 8782,
      score: 'A',
      host: 'mfa.targetA.com',
      total_vulnerabilities: 0,
      vulnerabilities: {
        low: 6,
        high: 1,
        info: 7,
        medium: 12,
        critical: 9,
      },
      timestamp: '2024-09-19 11:43:51.085320+00:00',
    },
    {
      id: 8779,
      score: 'A',
      host: 'timesheet.targetA.com',
      total_vulnerabilities: 0,
      vulnerabilities: {
        low: 10,
        high: 6,
        info: 12,
        medium: 1,
        critical: 2,
      },
      timestamp: '2024-09-19 11:42:42.539537+00:00',
    },
  ],
}
const ASSET_DETAILS_DATA = {
  id: 'ohscuohweouhowech',
  host: 'imap.netbaan.net',
  is_active: false,
  dns: {
    a: null,
    mx: null,
    ns: null,
  },
  score: 'C',
  total_vulnerabilities: 0,
  vulnerabilities: {
    low: 0,
    high: 0,
    info: 0,
    medium: 0,
    critical: 0,
  },
  targets: 'netbaan.net',
  tag: 'A',
  timestamp: '2024-10-19 07:45:50.387709+00:00',
  screen_shot: null,
  ipv4s: [
    {
      ip: '195.201.8.44',
      asn: 'AS24940',
      loc: 'DE',
      cidr: '195.201.0.0/16',
      latitude: 50.477699279785156,
      longitude: 12.364899635314941,
    },
  ],
  ipv6s: [],
  ports: [
    {
      port: 21,
      protocol: 'ftp',
    },
    {
      port: 25,
      protocol: 'smtp',
    },
  ],
  techs: ['Apache HTTP Server'],
  loc: ['DE'],
  vulnerabilities_list: [],
  scan_list: [
    {
      id: 'dascasdcasdcasdc',
      start_time: '2024-10-19T07:06:39.746037Z',
    },
  ],
  selected_scan: null,
  vulnerabilities_over_time: {
    x: ['2024-10-19'],
    y: [3],
  },
  created_at: '2024-10-18T21:32:02.508278Z',
  ssl_id: null,
  ssl_days_to_expiry: null,
  ssl_status: null,
  ssl_grade: null,
}
const ASSET_DETAILS_TIMELINE_DATA = [
  {
    date: '2024-10-11',
    percentage: null,
  },
  {
    date: '2024-10-10',
    percentage: null,
  },
  {
    date: '2024-10-9',
    percentage: null,
  },
  {
    date: '2024-10-8',
    percentage: null,
  },
  {
    date: '2024-10-7',
    percentage: null,
  },
  {
    date: '2024-10-6',
    percentage: null,
  },
  {
    date: '2024-10-5',
    percentage: null,
  },
  {
    date: '2024-10-4',
    percentage: null,
  },
  {
    date: '2024-10-3',
    percentage: null,
  },
  {
    date: '2024-10-2',
    percentage: null,
  },
  {
    date: '2024-10-1',
    percentage: null,
  },
  {
    date: '2023-10-12',
    percentage: null,
  },
  {
    date: '2023-10-11',
    percentage: null,
  },
  {
    date: '2023-10-10',
    percentage: null,
  },
  {
    date: '2023-10-9',
    percentage: null,
  },
  {
    date: '2023-10-8',
    percentage: null,
  },
  {
    date: '2023-10-10',
    percentage: null,
  },
  {
    date: '2023-10-9',
    percentage: null,
  },
  {
    date: '2023-10-8',
    percentage: null,
  },
  {
    date: '2023-10-10',
    percentage: null,
  },
  {
    date: '2023-10-9',
    percentage: null,
  },
  {
    date: '2023-10-8',
    percentage: null,
  },
  {
    date: '2023-10-10',
    percentage: null,
  },
  {
    date: '2023-10-9',
    percentage: null,
  },
  {
    date: '2023-10-8',
    percentage: null,
  },
  {
    date: '2024-10-12',
    percentage: 100,
  },
]
const VUL_DATA = {
  total_pages: 1,
  current_page: 1,
  results: [
    {
      id: 'ewfwefwefwefwefwe',
      host: 'demo.netbaan.net',
      scan_id: 'wefwefwefwef',
      severity: 'low',
      severity_fa: 'کم',
      sensor: 'ssl',
      component: 'SSL/TLS',
      title: 'This server is vulnerable to LUCKY13 attack.',
      description:
        'The SSL LUCKY13 is a cryptographic timing attack that can be used against implementations of the Transport Layer Security (TLS) and Datagram Transport Layer Security (DTLS) protocols using the Cipher Block Chaining (CBC) mode of operation. This can also be considered a type of man-in-the-middle attack. The ТLS protocol, the successor of the Secure Sockets Layer (SSL) protocol, provides privacy, data integrity, and secure traffic between communicating networks or applications.',
      title_fa: 'این سرور در برابر حمله LUCKY13 آسیب‌پذیر است.',
      description_fa:
        'حمله زمانی LUCKY13 SSL یک حمله رمزنگاری است که می‌تواند علیه پیاده‌سازی‌های پروتکل‌های امنیت لایه انتقال (TLS) و امنیت لایه انتقال دیتاگرام (DTLS) استفاده شود که از حالت عملیاتی زنجیره بلوک رمز (CBC) استفاده می‌کنند. این نیز می‌تواند به عنوان نوعی حمله مرد میانی در نظر گرفته شود. پروتکل TLS، جانشین پروتکل لایه سوکت‌های امن (SSL)، حریم خصوصی، یکپارچگی داده‌ها و ترافیک امن بین شبکه‌ها یا برنامه‌های ارتباطی را فراهم می‌کند.',
      timestamp: '2024-10-19 09:10:45.156015+00:00',
      target: 'netbaan.net',
      tag: 'A',
      cvss: null,
      cve: 'CVE-2013-0169',
      detail: null,
      references: ['https://nvd.nist.gov/vuln/detail/CVE-2013-0169'],
      poc: null,
      remediation: null,
      comment: null,
    },
    {
      id: 'wefwefwefwef',
      host: 'demo.netbaan.net',
      scan_id: 'wefwefwefwefwe',
      severity: 'medium',
      severity_fa: 'متوسط',
      sensor: 'ssl',
      component: 'SSL/TLS',
      title: 'This server does not mitigate the BEAST attack.',
      description:
        'Browser Exploit Against SSL/TLS (BEAST) is an attack that exploits a vulnerability in the Transport-Layer Security (TLS) 1.0 and older SSL protocols, using the cipher block chaining (CBC) mode encryption. It allows attackers to capture and decrypt HTTPS client-server sessions and obtain authentication tokens. It does so by combining a man-in-the-middle attack (MITM), along with a record splitting and chosen boundary attack.',
      title_fa: 'این سرور حمله BEAST را کاهش نمی دهد.',
      description_fa:
        'حمله Browser Exploit Against SSL/TLS (BEAST) حمله ای است که از یک آسیب پذیری در پروتکل های امنیتی لایه انتقال (TLS) 1.0 و پروتکل های SSL قدیمی تر استفاده می کند و از رمزنگاری حالت زنجیره بلوک رمز (CBC) استفاده می کند. این امکان را به حمله کنندگان می دهد تا جلسات HTTPS کلاینت-سرور را ضبط و رمزگشایی کنند و توکن های احراز هویت را دریافت کنند. این کار را با ترکیب یک حمله مرد میانسال (MITM) به همراه تقسیم رکورد و حمله مرز انتخابی انجام می دهد.',
      timestamp: '2024-10-19 09:10:45.155868+00:00',
      target: 'netbaan.net',
      tag: 'A',
      cvss: null,
      cve: 'CVE-2011-3389',
      detail: null,
      references: [
        'https://test/',
        'https://nvd.test',
      ],
      poc: null,
      remediation: null,
      comment: null,
    },
    {
      id: 'wtgwrgergwergerg',
      host: 'demo.netbaan.net',
      scan_id: 'yio;io;io;i;',
      severity: 'medium',
      severity_fa: 'متوسط',
      sensor: 'ssl',
      component: 'SSL/TLS',
      title: 'This server does not mitigate the BEAST attack.',
      description:
        'BEAST, or Browser Exploit Against SSL/TLS, was an attack that allowed a man-in-the-middle attacker to uncover information from an encrypted SSL/TLS 1.0 session by exploiting a known theoretical vulnerability.',
      title_fa: 'این سرور حمله BEAST را کاهش نمی‌دهد.',
      description_fa:
        'BEAST، یا حمله سوءاستفاده مرورگر علیه SSL/TLS، حمله‌ای بود که به یک مهاجم مرد میانی اجازه می‌داد اطلاعاتی را از یک جلسه رمزگذاری شده SSL/TLS 1.0 با سوءاستفاده از یک آسیب‌پذیری نظری شناخته شده فاش کند.',
      timestamp: '2024-10-19 09:10:45.155715+00:00',
      target: 'netbaan.net',
      tag: 'A',
      cvss: null,
      cve: null,
      detail: null,
      references: ['https://nvd.test'],
      poc: null,
      remediation: null,
      comment: null,
    },
  ],
}
const VUL_DETAILS_DATA = {
  id: 'wtgwergwerg',
  host: 'demo.netbaan.net',
  scan_id: 'gwergwergwer',
  severity: 'low',
  severity_fa: 'کم',
  sensor: 'ssl',
  component: 'SSL/TLS',
  title: 'This server is vulnerable to LUCKY13 attack.',
  description:
    'The SSL LUCKY13 is a cryptographic timing attack that can be used against implementations of the Transport Layer Security (TLS) and Datagram Transport Layer Security (DTLS) protocols using the Cipher Block Chaining (CBC) mode of operation. This can also be considered a type of man-in-the-middle attack. The ТLS protocol, the successor of the Secure Sockets Layer (SSL) protocol, provides privacy, data integrity, and secure traffic between communicating networks or applications.',
  title_fa: 'این سرور در برابر حمله LUCKY13 آسیب‌پذیر است.',
  description_fa:
    'حمله زمانی LUCKY13 SSL یک حمله رمزنگاری است که می‌تواند علیه پیاده‌سازی‌های پروتکل‌های امنیت لایه انتقال (TLS) و امنیت لایه انتقال دیتاگرام (DTLS) استفاده شود که از حالت عملیاتی زنجیره بلوک رمز (CBC) استفاده می‌کنند. این نیز می‌تواند به عنوان نوعی حمله مرد میانی در نظر گرفته شود. پروتکل TLS، جانشین پروتکل لایه سوکت‌های امن (SSL)، حریم خصوصی، یکپارچگی داده‌ها و ترافیک امن بین شبکه‌ها یا برنامه‌های ارتباطی را فراهم می‌کند.',
  timestamp: '2024-10-19 09:10:45.156015+00:00',
  target: 'netbaan.net',
  tag: 'A',
  cvss: null,
  cve: 'CVE-2013-0169',
  detail: null,
  references: ['https://nvd.test'],
  poc: null,
  remediation: null,
  comment: null,
}
const DNS_DATA = [
  {
    id: 156,
    target: 'targetC.com',
    data: {
      soa: {
        rname: {
          data: ["Contact email for DNS problems is 'hostmaster.targetC.com.'"],
          title: 'SOA Rname',
          detail: 'OK.',
          status: 'pass',
          description:
            'RNAME field defines an administrative email for your zone. RFC2142 recommends using hostmaster e-mail for this purpose, but any valid e-mail address can be used.',
        },
        title: 'SOA',
        serial: {
          data: [
            'master SOA serial --> 1726438694 ',
            '185.143.235.245 --> 1726438694 ',
            '185.143.232.253 --> 1726438694 ',
          ],
          title: 'Name Servers Agreement on Serial Number',
          detail: 'OK. All name servers have the same serial number.',
          status: 'pass',
          description:
            'Having different serials on your name servers may show inconsistencies between name servers configuration (multiple masters), or communication errors (ACL and firewall issues).',
        },
        soa_record: {
          data: [
            'serial --> 1726438694 ',
            'rname --> hostmaster.targetC.com.',
            'refresh --> 86400 ',
            'expire --> 604800 ',
            'minimum --> 7200 ',
            'retry --> 7200 ',
            'mname --> ns1.targetC.com.',
          ],
          title: 'Check SOA record',
          detail: 'OK. Found SOA record :',
          status: 'pass',
          description:
            "The DNS 'start of authority' (SOA) record stores important information about a domain or zone such as the email address of the administrator, when the domain was last updated, and how long the server should wait between refreshes. All DNS zones need an SOA record in order to conform to IETF standards.",
        },
      },
      host: 'targetC.com',
      score: 70,
      whois: {
        org: {
          data: ['REDACTED FOR PRIVACY'],
          title: 'Organization',
          detail: 'OK. Organization:',
          status: 'pass',
          description: '',
        },
        title: 'WHOIS',
        emails: {
          data: ['abuse@key-systems.net - abusereport@key-systems.net - info@domain-contact.org'],
          title: 'Emails',
          detail: 'OK. Founded emails:',
          status: 'pass',
          description: '',
        },
        address: {
          data: [
            'address : REDACTED FOR PRIVACY',
            'city : REDACTED FOR PRIVACY',
            'state : None',
            'country : TR',
          ],
          title: 'Address',
          detail: 'OK. Registered address:',
          status: 'pass',
          description: '',
        },
        registrar: {
          data: ['Key-Systems GmbH'],
          title: 'Registrar',
          detail: 'OK. Registrer:',
          status: 'pass',
          description: '',
        },
        name_servers: {
          data: ['NS1.targetC.com - NS2.targetC.com - ns1.targetC.com - ns2.targetC.com'],
          title: 'Name Servers',
          detail: 'OK. Registered name servers:',
          status: 'pass',
          description: '',
        },
        expiration_date: {
          data: ['2025-04-15 19:03:49'],
          title: 'Expiration Date',
          detail: 'OK. Domain expiration date:',
          status: 'pass',
          description: '',
        },
      },
      mailserver: {
        a: {
          data: ['mail.targetC.com. --> 185.196.150.130'],
          title: 'Mail Servers Have A Records',
          detail: 'OK. Found A records for each mail servers :',
          status: 'pass',
          description:
            'To reach your name servers via IPv4 an A record is needed for each Mail server.',
        },
        ptr: {
          data: ['mail.targetC.com. --> 185.196.150.130 --> mail.targetC.com. --> 185.196.150.130'],
          title: 'Reverse Entries for MX records',
          detail: 'OK. All mail servers have reverse DNS entries configured correctly.',
          status: 'pass',
          description:
            'All mail servers should have a reverse DNS (PTR) entry for each IP address (RFC 1912). Missing reverse DNS entries will make many mail servers to reject your e-mails or mark them as spam. ',
        },
        spf: {
          data: [
            'All nameservers failed to answer the query spf.sendingblue.com. IN TXT: Server 185.143.235.245 UDP port 53 answered NOTAUTH; Server 185.143.232.253 UDP port 53 answered NOTAUTH',
          ],
          title: 'Check SPF record',
          detail: "WARNING: Domain doesn't have SPF record.",
          status: 'fail',
          description:
            "SPF (Sender Policy Framework) record is designed to prevent e-mail spoofing. Typical SPF record would be: 'v=spf1 a mx ~all' ",
        },
        aaaa: {
          data: ['mail.targetC.com. -->  ? '],
          title: 'Mail Servers Have AAAA Records',
          detail: 'We found mail servers without AAAA records:',
          status: 'fail',
          description:
            'To reach your name servers via IPv6 an AAAA record is needed for Mail server.',
        },
        title: 'Mail Server',
        address: {
          data: ['mail.targetC.com.'],
          title: 'MX Records',
          detail: 'Your name servers returned MX records:',
          status: 'pass',
          description:
            'The MX record indicates how email messages should be routed in accordance with the Simple Mail Transfer Protocol',
        },
        private: {
          data: [],
          title: 'Check All IPs are Public',
          detail: 'OK. No private IPs found.',
          status: 'pass',
          description:
            "Mail servers using private IPs can't be reached from the Internet causing DNS delays.",
        },
      },
      nameserver: {
        a: {
          data: ['ns1.targetC.com. --> 185.143.235.245', 'ns2.targetC.com. --> 185.143.232.253'],
          title: 'Name Servers Have A Records',
          detail: 'OK. Found A records for each name servers :',
          status: 'pass',
          description:
            'To reach your name servers via IPv4 an A record is needed for each name server.',
        },
        txt: {
          data: [
            '"v=spf1 a:mail.targetC.com ip4:185.196.150.130 include:spf.sendingblue.com ~all"',
            '"google-site-verification=J-k7IP4SK27Vwu9ZRq80w5XTdF2IYT-2G4FMm3J9QhU"',
            '"a95ca31c7ac9b1912445f3f7aa2ded97d23d6acd22c6ff7f6c9a1e40c9af2ca"',
            '"Sendinblue-code:777419107e8a4647632f5e93061683e9"',
            '"google-site-verification=piOAn-oy470eJT5yu__nbU6o-9PRg3QzXPiPDbupktk"',
            '"google-site-verification=msUvwn8ZUPVOwMgODQQhJ5_RXCA2vrofdBWRk7ipveQ"',
          ],
          title: 'TXT RECORD',
          detail: 'TXT Record has been founded',
          status: 'pass',
          description: 'Found the TXT record for the domain.',
        },
        aaaa: {
          data: ['ns1.targetC.com. -->  ? ', 'ns2.targetC.com. -->  ? '],
          title: 'Name Servers Have AAAA Records',
          detail: 'We found name servers without AAAA records :',
          status: 'fail',
          description:
            'To reach your name servers via IPv6 an AAAA record is needed for each name server.',
        },
        count: {
          data: ['there are 2 name servers'],
          title: 'Name Servers Count',
          detail: 'Ok. More than one name server has been detected:',
          status: 'pass',
          description:
            'Recommended number for name servers is between 2 and 7 name servers (RFC 2182 recommends to have at least 3 authoritative name servers for domains).',
        },
        title: 'Name Server',
        dnssec: {
          data: ['185.143.235.245 --> no DNSSEC records', '185.143.232.253 --> no DNSSEC records'],
          title: 'DNSSEC',
          detail: 'DNSSEC is disabled.',
          status: 'fail',
          description:
            'The Domain Name System Security Extensions (DNSSEC) is a set of specifications that extend the DNS protocol by adding cryptographic authentication for responses received from authoritative DNS servers. Its goal is to defend against techniques that hackers use to direct computers to rogue websites and servers.',
        },
        address: {
          data: ['ns1.targetC.com.', 'ns2.targetC.com.'],
          title: 'NS Records',
          detail: 'OK. NS records Your name servers returned :',
          status: 'pass',
          description:
            'An NS record (or nameserver record) is a DNS record that contains the name of the authoritative name server within a domain or DNS zone.',
        },
        private: {
          data: [],
          title: 'Check All IPs are Public',
          detail: 'OK. No private IPs found.',
          status: 'pass',
          description:
            "Name servers using private IPs can't be reached from the Internet causing DNS delays.",
        },
        recursive: {
          data: [],
          title: 'Allow Recursive Queries',
          detail: 'OK. Domain name servers are not allowing recursive queries.',
          status: 'pass',
          description:
            'On all name servers which acts as caching name servers recursive queries should be restricted to local networks. Having open DNS servers can lead to abuses such as cache poisoning and DOS (denial of service) attacks. Cache poisoning attacks allows under certain conditions to redirect legitimate web traffic, email and other traffic to malicious hosts compromising security.',
        },
        zone_transfer: {
          data: [],
          title: 'Zone Transfer',
          detail: 'OK. Domain not allowing zone transfer.',
          status: 'pass',
          description:
            'DNS zone transfer offers no authentication. So, any client or someone posing as a client can ask a DNS server for a copy of the entire zone.This means that unless some kind of protection is introduced, anyone is capable of getting a list of all hosts for the particular domain, which gives them a lot of potential attack vectors.',
        },
      },
    },
  },
  {
    id: 153,
    target: 'targetI.com',
    data: {
      soa: {
        rname: {
          data: ["Contact email for DNS problems is 'hostmaster.arvancloud.ir.'"],
          title: 'SOA Rname',
          detail: 'OK.',
          status: 'pass',
          description:
            'RNAME field defines an administrative email for your zone. RFC2142 recommends using hostmaster e-mail for this purpose, but any valid e-mail address can be used.',
        },
        title: 'SOA',
        serial: {
          data: [
            'master SOA serial --> 1726031917 ',
            '185.143.232.253 --> 1726031917 ',
            '185.143.235.245 --> 1726031917 ',
          ],
          title: 'Name Servers Agreement on Serial Number',
          detail: 'OK. All name servers have the same serial number.',
          status: 'pass',
          description:
            'Having different serials on your name servers may show inconsistencies between name servers configuration (multiple masters), or communication errors (ACL and firewall issues).',
        },
        soa_record: {
          data: [
            'serial --> 1726031917 ',
            'rname --> hostmaster.arvancloud.ir.',
            'refresh --> 86400 ',
            'expire --> 604800 ',
            'minimum --> 7200 ',
            'retry --> 7200 ',
            'mname --> m.ns.arvancdn.ir.',
          ],
          title: 'Check SOA record',
          detail: 'OK. Found SOA record :',
          status: 'pass',
          description:
            "The DNS 'start of authority' (SOA) record stores important information about a domain or zone such as the email address of the administrator, when the domain was last updated, and how long the server should wait between refreshes. All DNS zones need an SOA record in order to conform to IETF standards.",
        },
      },
      host: 'targetI.com',
      score: 80,
      whois: {
        title: 'WHOIS',
        name_servers: {
          data: ['ns1.targetI.com - ns2.targetI.com - m.ns.arvancdn.ir - t.ns.arvancdn.ir'],
          title: 'Name Servers',
          detail: 'OK. Registered name servers:',
          status: 'pass',
          description: '',
        },
      },
      mailserver: {
        a: {
          data: ['Edge.targetI.com. --> 185.196.150.140'],
          title: 'Mail Servers Have A Records',
          detail: 'OK. Found A records for each mail servers :',
          status: 'pass',
          description:
            'To reach your name servers via IPv4 an A record is needed for each Mail server.',
        },
        ptr: {
          data: ['Edge.targetI.com. --> 185.196.150.140 --> Edge.targetI.com. --> 185.196.150.140'],
          title: 'Reverse Entries for MX records',
          detail: 'OK. All mail servers have reverse DNS entries configured correctly.',
          status: 'pass',
          description:
            'All mail servers should have a reverse DNS (PTR) entry for each IP address (RFC 1912). Missing reverse DNS entries will make many mail servers to reject your e-mails or mark them as spam. ',
        },
        spf: {
          data: [
            'SPF record:',
            'v=spf1 mx a:edge.targetI.com a:EXC813EDG03.targetI.com ip4:185.196.150.0/24 -all',
          ],
          title: 'Check SPF record',
          detail: 'OK. Found SPF record:',
          status: 'pass',
          description:
            "SPF (Sender Policy Framework) record is designed to prevent e-mail spoofing. Typical SPF record would be: 'v=spf1 a mx ~all' ",
        },
        aaaa: {
          data: ['Edge.targetI.com. -->  ? '],
          title: 'Mail Servers Have AAAA Records',
          detail: 'We found mail servers without AAAA records:',
          status: 'fail',
          description:
            'To reach your name servers via IPv6 an AAAA record is needed for Mail server.',
        },
        title: 'Mail Server',
        address: {
          data: ['Edge.targetI.com.'],
          title: 'MX Records',
          detail: 'Your name servers returned MX records:',
          status: 'pass',
          description:
            'The MX record indicates how email messages should be routed in accordance with the Simple Mail Transfer Protocol',
        },
        private: {
          data: [],
          title: 'Check All IPs are Public',
          detail: 'OK. No private IPs found.',
          status: 'pass',
          description:
            "Mail servers using private IPs can't be reached from the Internet causing DNS delays.",
        },
      },
      nameserver: {
        a: {
          data: ['m.ns.arvancdn.ir. --> 185.143.232.253', 't.ns.arvancdn.ir. --> 185.143.235.245'],
          title: 'Name Servers Have A Records',
          detail: 'OK. Found A records for each name servers :',
          status: 'pass',
          description:
            'To reach your name servers via IPv4 an A record is needed for each name server.',
        },
        txt: {
          data: [
            '"v=spf1 mx a:edge.targetI.com a:EXC813EDG03.targetI.com ip4:185.196.150.0/24 -all"',
          ],
          title: 'TXT RECORD',
          detail: 'TXT Record has been founded',
          status: 'pass',
          description: 'Found the TXT record for the domain.',
        },
        aaaa: {
          data: ['m.ns.arvancdn.ir. -->  ? ', 't.ns.arvancdn.ir. -->  ? '],
          title: 'Name Servers Have AAAA Records',
          detail: 'We found name servers without AAAA records :',
          status: 'fail',
          description:
            'To reach your name servers via IPv6 an AAAA record is needed for each name server.',
        },
        count: {
          data: ['there are 2 name servers'],
          title: 'Name Servers Count',
          detail: 'Ok. More than one name server has been detected:',
          status: 'pass',
          description:
            'Recommended number for name servers is between 2 and 7 name servers (RFC 2182 recommends to have at least 3 authoritative name servers for domains).',
        },
        title: 'Name Server',
        dnssec: {
          data: ['185.143.232.253 --> no DNSSEC records', '185.143.235.245 --> no DNSSEC records'],
          title: 'DNSSEC',
          detail: 'DNSSEC is disabled.',
          status: 'fail',
          description:
            'The Domain Name System Security Extensions (DNSSEC) is a set of specifications that extend the DNS protocol by adding cryptographic authentication for responses received from authoritative DNS servers. Its goal is to defend against techniques that hackers use to direct computers to rogue websites and servers.',
        },
        address: {
          data: ['m.ns.arvancdn.ir.', 't.ns.arvancdn.ir.'],
          title: 'NS Records',
          detail: 'OK. NS records Your name servers returned :',
          status: 'pass',
          description:
            'An NS record (or nameserver record) is a DNS record that contains the name of the authoritative name server within a domain or DNS zone.',
        },
        private: {
          data: [],
          title: 'Check All IPs are Public',
          detail: 'OK. No private IPs found.',
          status: 'pass',
          description:
            "Name servers using private IPs can't be reached from the Internet causing DNS delays.",
        },
        recursive: {
          data: [],
          title: 'Allow Recursive Queries',
          detail: 'OK. Domain name servers are not allowing recursive queries.',
          status: 'pass',
          description:
            'On all name servers which acts as caching name servers recursive queries should be restricted to local networks. Having open DNS servers can lead to abuses such as cache poisoning and DOS (denial of service) attacks. Cache poisoning attacks allows under certain conditions to redirect legitimate web traffic, email and other traffic to malicious hosts compromising security.',
        },
        zone_transfer: {
          data: [],
          title: 'Zone Transfer',
          detail: 'OK. Domain not allowing zone transfer.',
          status: 'pass',
          description:
            'DNS zone transfer offers no authentication. So, any client or someone posing as a client can ask a DNS server for a copy of the entire zone.This means that unless some kind of protection is introduced, anyone is capable of getting a list of all hosts for the particular domain, which gives them a lot of potential attack vectors.',
        },
      },
    },
  },
  {
    id: 152,
    target: 'targetH.com',
    data: {
      soa: {
        rname: {
          data: ["Contact email for DNS problems is 'hostmaster.eurodns.com.'"],
          title: 'SOA Rname',
          detail: 'OK.',
          status: 'pass',
          description:
            'RNAME field defines an administrative email for your zone. RFC2142 recommends using hostmaster e-mail for this purpose, but any valid e-mail address can be used.',
        },
        title: 'SOA',
        serial: {
          data: [
            'master SOA serial --> 2021110100 ',
            '104.37.178.108 --> 2021110100 ',
            '104.37.178.107 --> 2021110100 ',
            '199.167.66.107 --> 2021110100 ',
            '199.167.66.108 --> 2021110100 ',
          ],
          title: 'Name Servers Agreement on Serial Number',
          detail: 'OK. All name servers have the same serial number.',
          status: 'pass',
          description:
            'Having different serials on your name servers may show inconsistencies between name servers configuration (multiple masters), or communication errors (ACL and firewall issues).',
        },
        soa_record: {
          data: [
            'serial --> 2021110100 ',
            'rname --> hostmaster.eurodns.com.',
            'refresh --> 86400 ',
            'expire --> 604800 ',
            'minimum --> 86400 ',
            'retry --> 7200 ',
            'mname --> ns1.eurodns.com.',
          ],
          title: 'Check SOA record',
          detail: 'OK. Found SOA record :',
          status: 'pass',
          description:
            "The DNS 'start of authority' (SOA) record stores important information about a domain or zone such as the email address of the administrator, when the domain was last updated, and how long the server should wait between refreshes. All DNS zones need an SOA record in order to conform to IETF standards.",
        },
      },
      host: 'targetH.com',
      score: 90,
      whois: {
        org: {
          data: ['Acunetix Ltd'],
          title: 'Organization',
          detail: 'OK. Organization:',
          status: 'pass',
          description: '',
        },
        title: 'WHOIS',
        emails: {
          data: ['legalservices@eurodns.com - administrator@acunetix.com'],
          title: 'Emails',
          detail: 'OK. Founded emails:',
          status: 'pass',
          description: '',
        },
        address: {
          data: [
            'address : 3rd Floor,, J&C Building,, Road Town',
            'city : Tortola',
            'state : None',
            'country : VG',
          ],
          title: 'Address',
          detail: 'OK. Registered address:',
          status: 'pass',
          description: '',
        },
        registrar: {
          data: ['Eurodns S.A.'],
          title: 'Registrar',
          detail: 'OK. Registrer:',
          status: 'pass',
          description: '',
        },
        name_servers: {
          data: [
            'NS1.EURODNS.COM - NS2.EURODNS.COM - NS3.EURODNS.COM - NS4.EURODNS.COM - ns1.eurodns.com - ns2.eurodns.com - ns3.eurodns.com - ns4.eurodns.com',
          ],
          title: 'Name Servers',
          detail: 'OK. Registered name servers:',
          status: 'pass',
          description: '',
        },
        expiration_date: {
          data: ['2025-06-14 07:50:29 - 2025-06-13 00:00:00'],
          title: 'Expiration Date',
          detail: 'OK. Domain expiration date:',
          status: 'pass',
          description: '',
        },
      },
      mailserver: {
        title: 'Mail Server',
      },
      nameserver: {
        a: {
          data: [
            'ns4.eurodns.com. --> 104.37.178.108',
            'ns2.eurodns.com. --> 104.37.178.107',
            'ns1.eurodns.com. --> 199.167.66.107',
            'ns3.eurodns.com. --> 199.167.66.108',
          ],
          title: 'Name Servers Have A Records',
          detail: 'OK. Found A records for each name servers :',
          status: 'pass',
          description:
            'To reach your name servers via IPv4 an A record is needed for each name server.',
        },
        txt: {
          data: [
            '"v=spf1 -all"',
            '"google-site-verification=4LQORV-lTi-d4GPxtBEQWmFnwff7UAazQc9gZvHukbw"',
          ],
          title: 'TXT RECORD',
          detail: 'TXT Record has been founded',
          status: 'pass',
          description: 'Found the TXT record for the domain.',
        },
        aaaa: {
          data: [
            'ns4.eurodns.com. --> 2610:1c8:b001::108',
            'ns2.eurodns.com. --> 2610:1c8:b001::107',
            'ns1.eurodns.com. --> 2610:1c8:b002::107',
            'ns3.eurodns.com. --> 2610:1c8:b002::108',
          ],
          title: 'Name Servers Have AAAA Records',
          detail: 'OK. Found AAAA records for each name servers.AAAA records :',
          status: 'pass',
          description:
            'To reach your name servers via IPv6 an AAAA record is needed for each name server.',
        },
        count: {
          data: ['there are 4 name servers'],
          title: 'Name Servers Count',
          detail: 'Ok. More than one name server has been detected:',
          status: 'pass',
          description:
            'Recommended number for name servers is between 2 and 7 name servers (RFC 2182 recommends to have at least 3 authoritative name servers for domains).',
        },
        title: 'Name Server',
        dnssec: {
          data: [
            '104.37.178.108 --> no DNSSEC records',
            '104.37.178.107 --> no DNSSEC records',
            '199.167.66.107 --> no DNSSEC records',
            '199.167.66.108 --> no DNSSEC records',
          ],
          title: 'DNSSEC',
          detail: 'DNSSEC is disabled.',
          status: 'fail',
          description:
            'The Domain Name System Security Extensions (DNSSEC) is a set of specifications that extend the DNS protocol by adding cryptographic authentication for responses received from authoritative DNS servers. Its goal is to defend against techniques that hackers use to direct computers to rogue websites and servers.',
        },
        address: {
          data: ['ns4.eurodns.com.', 'ns2.eurodns.com.', 'ns1.eurodns.com.', 'ns3.eurodns.com.'],
          title: 'NS Records',
          detail: 'OK. NS records Your name servers returned :',
          status: 'pass',
          description:
            'An NS record (or nameserver record) is a DNS record that contains the name of the authoritative name server within a domain or DNS zone.',
        },
        private: {
          data: [],
          title: 'Check All IPs are Public',
          detail: 'OK. No private IPs found.',
          status: 'pass',
          description:
            "Name servers using private IPs can't be reached from the Internet causing DNS delays.",
        },
        recursive: {
          data: [],
          title: 'Allow Recursive Queries',
          detail: 'OK. Domain name servers are not allowing recursive queries.',
          status: 'pass',
          description:
            'On all name servers which acts as caching name servers recursive queries should be restricted to local networks. Having open DNS servers can lead to abuses such as cache poisoning and DOS (denial of service) attacks. Cache poisoning attacks allows under certain conditions to redirect legitimate web traffic, email and other traffic to malicious hosts compromising security.',
        },
        zone_transfer: {
          data: [],
          title: 'Zone Transfer',
          detail: 'OK. Domain not allowing zone transfer.',
          status: 'pass',
          description:
            'DNS zone transfer offers no authentication. So, any client or someone posing as a client can ask a DNS server for a copy of the entire zone.This means that unless some kind of protection is introduced, anyone is capable of getting a list of all hosts for the particular domain, which gives them a lot of potential attack vectors.',
        },
      },
    },
  },
]
const SSL_DATA = {
  total_pages: 1,
  current_page: 1,
  results: [
    {
      id: 845,
      host: 'pfiles.targetC.com',
      grade: 'B',
      total_vulnerabilities: 4,
      certificate_status: true,
      expiry_date: 301,
      timestamp: '2024-09-18 15:17:41.545743+00:00',
    },
    {
      id: 844,
      host: 'notification.targetC.com',
      grade: 'B',
      total_vulnerabilities: 5,
      certificate_status: true,
      expiry_date: 301,
      timestamp: '2024-09-18 15:17:32.998854+00:00',
    },
    {
      id: 843,
      host: 'panel.targetC.com',
      grade: 'B',
      total_vulnerabilities: 4,
      certificate_status: true,
      expiry_date: 301,
      timestamp: '2024-09-18 15:13:56.370082+00:00',
    },
  ],
}
const SSL_DETAILS_DATA = {
  id: 845,
  host: 'pfiles.targetC.com',
  data: {
    info: [
      {
        finding: 'Grade capped to B. TLS 1.1 offered',
        severity: 'info',
      },
      {
        finding: 'Grade capped to B. TLS 1.0 offered',
        severity: 'info',
      },
      {
        finding: 'Grade capped to A. HSTS is not offered',
        severity: 'info',
      },
    ],
    score: 'B',
    rating: [
      {
        title: 'Protocol Support',
        finding: '95',
      },
      {
        title: 'Key Exchange',
        finding: '100',
      },
      {
        title: 'Cipher Strength',
        finding: '90',
      },
      {
        title: 'Overall Grade',
        finding: 'B',
      },
      {
        title: 'Certificate',
        finding: '0',
        severity: 'ok',
      },
    ],
    report: [
      {
        title: 'Vulnerabilities',
        detail: [
          {
            title: 'Heartbleed',
            detail:
              'he Heartbleed Bug is a serious vulnerability in the popular OpenSSL cryptographic software library. This weakness allows stealing the information protected, under normal conditions, by the SSL/TLS encryption used to secure the Internet. SSL/TLS provides communication security and privacy over the Internet for applications such as web, email, instant messaging (IM) and some virtual private networks (VPNs). The Heartbleed bug allows anyone on the Internet to read the memory of the systems protected by the vulnerable versions of the OpenSSL software. This compromises the secret keys used to identify the service providers and to encrypt the traffic, the names and passwords of the users and the actual content. This allows attackers to eavesdrop on communications, steal data directly from the services and users and to impersonate services and users.',
            finding: 'not vulnerable, no heartbeat extension',
            severity: 'ok',
          },
          {
            title: 'CCS',
            detail:
              'OpenSSL before 0.9.8za, 1.0.0 before 1.0.0m, and 1.0.1 before 1.0.1h does not properly restrict processing of ChangeCipherSpec messages, which allows man-in-the-middle attackers to trigger use of a zero-length master key in certain OpenSSL-to-OpenSSL communications, and consequently hijack sessions or obtain sensitive information, via a crafted TLS handshake, aka the `CCS Injection` vulnerability.',
            finding: 'not vulnerable',
            severity: 'ok',
          },
          {
            title: 'Ticketbleed ',
            detail:
              'A BIG-IP virtual server configured with a Client SSL profile that has the non-default Session Tickets option enabled may leak up to 31 bytes of uninitialized memory. A remote attacker may exploit this vulnerability to obtain Secure Sockets Layer (SSL) session IDs from other sessions. It is possible that other data from uninitialized memory may be returned as well.',
            finding: 'not vulnerable',
            severity: 'ok',
          },
          {
            title: 'ROBOT',
            detail:
              'ROBOT is the return of a 19-year-old vulnerability that allows performing RSA decryption and signing operations with the private key of a TLS server.',
            finding: 'not vulnerable',
            severity: 'ok',
          },
          {
            title: 'Secure Renegotiation',
            detail:
              'A serious vulnerability has been discovered in the way web servers utilise SSL (and TLS, up to the most recent version, 1.2), effectively allowing an active man-in-the-middle attacker to inject arbitrary content into an encrypted data stream. The problem is with the renegotiation feature, which allows one part of an encrypted connection to be controlled by one party with the other part to be controlled by another. A MITM attacker can open a connection to an SSL server, send some data, request renegotiation and, from that point on, continue to forward to the SSL server the data coming from a genuine user.',
            finding: 'supported',
            severity: 'ok',
          },
          {
            title: 'Secure Client-Initiated Renegotiation',
            detail:
              'Background. SSL/TLS client-initiated renegotiation is a feature that allows the client to renegotiate new encryption parameters for an SSL/TLS connection within a single TCP connection. During the SSL/TLS handshake the server incurs a higher computational cost.',
            finding: 'not vulnerable',
            severity: 'ok',
          },
          {
            title: 'CRIME TLS',
            detail:
              'Compression Ratio Info-leak Made Easy (CRIME) is a security exploit against secret web cookies over connections using the HTTPS and SPDY protocols that also use data compression. When used to recover the content of secret authentication cookies, it allows an attacker to perform session hijacking on an authenticated web session, allowing the launching of further attacks. CRIME is a client-side attack, but the server can protect the client by refusing to use the feature combinations which can be attacked. For CRIME, the weakness is Deflate compression. This alert is issued if the server accepts Deflate compression.',
            finding: 'not vulnerable',
            severity: 'ok',
          },
          {
            title: 'BREACH',
            detail:
              'BREACH (a backronym: Browser Reconnaissance and Exfiltration via Adaptive Compression of Hypertext) is a security vulnerability against HTTPS when using HTTP compression. BREACH is built based on the CRIME security exploit.',
            finding:
              'not vulnerable, no gzip/deflate/compress/br HTTP compression  - only supplied / tested',
            severity: 'ok',
          },
          {
            title: 'POODLE SSL',
            detail:
              'The SSL protocol 3.0, as used in OpenSSL through 1.0.1i and other products, uses nondeterministic CBC padding, which makes it easier for man-in-the-middle attackers to obtain cleartext data via a padding-oracle attack, aka the `POODLE` issue.',
            finding: 'not vulnerable, no SSLv3',
            severity: 'ok',
          },
          {
            title: 'TLS Signaling Cipher Suite Value',
            detail:
              'TLS_FALLBACK_SCSV is a TLS Signaling Cipher Suite Value (SCSV) that can be used to guard against protocol downgrade attacks. The extension can be useful for clients like web browsers, which fall back to a lesser protocol version if attempts to use a higher protocol version fail.',
            finding: 'supported',
            severity: 'ok',
          },
          {
            title: 'SWEET32',
            detail:
              'The Sweet32 attack is a SSL/TLS vulnerability that allows attackers to compromise HTTPS connections using 64-bit block ciphers.',
            finding: 'uses 64 bit block ciphers',
            severity: 'low',
          },
          {
            title: 'FREAK',
            detail:
              'FREAK attack allows an attacker to intercept HTTPS connections between vulnerable clients and servers and force them to use weakened encryption, which the attacker can break to steal or manipulate sensitive data. This site is dedicated to tracking the impact of the attack and helping users test whether they’re vulnerable.',
            finding: 'not vulnerable',
            severity: 'ok',
          },
          {
            title: 'DROWN',
            detail:
              'DROWN is a serious vulnerability that affects HTTPS and other services that rely on SSL and TLS, some of the essential cryptographic protocols for Internet security. These protocols allow everyone on the Internet to browse the web, use email, shop online, and send instant messages without third-parties being able to read the communication. DROWN allows attackers to break the encryption and read or steal sensitive communications, including passwords, credit card numbers, trade secrets, or financial data. At the time of public disclosure on March 2016, our measurements indicated 33% of all HTTPS servers were vulnerable to the attack. Fortunately, the vulnerability is much less prevalent now.',
            finding: 'not vulnerable on this host and port',
            severity: 'ok',
          },
          {
            title: 'DROWN hint',
            detail:
              'DROWN is a serious vulnerability that affects HTTPS and other services that rely on SSL and TLS, some of the essential cryptographic protocols for Internet security. These protocols allow everyone on the Internet to browse the web, use email, shop online, and send instant messages without third-parties being able to read the communication.',
            finding:
              'Make sure you dont use this certificate elsewhere with SSLv2 enabled services, see https://search.censys.io/search?resource=hosts&virtual_hosts=INCLUDE&q=36BBE767F33D34122C8E2CE1DFA1AFDB0B31BC955F20A04E9B00DCFAC880FE47',
            severity: 'info',
          },
          {
            title: 'LOGJAM',
            detail:
              'The Logjam attack allows a man-in-the-middle attacker to downgrade vulnerable TLS connections to 512-bit export-grade cryptography. This allows the attacker to read and modify any data passed over the connection. The attack is reminiscent of the FREAK attack, but is due to a flaw in the TLS protocol rather than an implementation vulnerability, and attacks a Diffie-Hellman key exchange rather than an RSA key exchange. The attack affects any server that supports DHE_EXPORT ciphers, and affects all modern web browsers. 8.4% of the Top 1 Million domains were initially vulnerable.',
            finding: 'not vulnerable, no DH EXPORT ciphers,',
            severity: 'ok',
          },
          {
            title: 'LOGJAM (common primes)',
            detail:
              'The Logjam vulnerability is a security threat that affects the Diffie-Hellman key exchange using 512- to 1024-bit keys. In essence, the threat downgrades the Transport Layer Security (TLS) connection and exploits a weakness caused by using the same prime numbers in the encryption to execute a Man-in-the-Middle attack',
            finding: 'no DH key with <= TLS 1.2',
            severity: 'ok',
          },
          {
            title: 'BEAST CBC TLS1',
            detail:
              'BEAST stands for Browser Exploit Against SSL/TLS. It is an attack against network vulnerabilities in TLS 1.0 and older SSL protocols.',
            finding: 'ECDHE-RSA-AES128-SHA ECDHE-RSA-AES256-SHA AES128-SHA AES256-SHA DES-CBC3-SHA',
            severity: 'medium',
          },
          {
            title: 'BEAST',
            detail:
              'Browser Exploit Against SSL/TLS (BEAST) is an attack that exploits a vulnerability in the Transport-Layer Security (TLS) 1.0 and older SSL protocols, using the cipher block chaining (CBC) mode encryption. It allows attackers to capture and decrypt HTTPS client-server sessions and obtain authentication tokens. It does so by combining a man-in-the-middle attack (MITM), along with a record splitting and chosen boundary attack.',
            finding:
              'VULNERABLE -- but also supports higher protocols  TLSv1.1 TLSv1.2 (likely mitigated)',
            severity: 'low',
          },
          {
            title: 'LUCKY13',
            detail:
              'The SSL LUCKY13 is a cryptographic timing attack that can be used against implementations of the Transport Layer Security (TLS) and Datagram Transport Layer Security (DTLS) protocols using the Cipher Block Chaining (CBC) mode of operation. This can also be considered a type of man-in-the-middle attack. The ТLS protocol, the successor of the Secure Sockets Layer (SSL) protocol, provides privacy, data integrity, and secure traffic between communicating networks or applications.',
            finding: 'potentially vulnerable, uses TLS CBC ciphers',
            severity: 'low',
          },
          {
            title: 'Winshock',
            detail:
              'By sending specially crafted network traffic, a remote attacker could exploit the WinShock flaw and execute arbitrary code on a server or client, allowing the attacker to infect the target with malware.',
            finding: 'not vulnerable',
            severity: 'ok',
          },
          {
            title: 'RC4',
            detail:
              'okThe RC4 algorithm, as used in the TLS protocol and SSL protocol, could allow a remote attacker to obtain sensitive information. An attacker could exploit this vulnerability to remotely expose account credentials.',
            finding: 'not vulnerable',
            severity: 'ok',
          },
        ],
      },
      {
        title: 'Protocols',
        detail: [
          {
            title: 'SSL 2',
            finding: 'not offered',
            severity: 'ok',
          },
          {
            title: 'SSL 3',
            finding: 'not offered',
            severity: 'ok',
          },
          {
            title: 'TLS 1.0',
            finding: 'offered (deprecated)',
            severity: 'low',
          },
          {
            title: 'TLS 1.1',
            finding: 'offered (deprecated)',
            severity: 'low',
          },
          {
            title: 'TLS 1.2',
            finding: 'offered',
            severity: 'ok',
          },
          {
            title: 'TLS 1.3',
            finding: 'offered with final',
            severity: 'ok',
          },
        ],
      },
      {
        title: 'Certificate',
        detail: [
          {
            title: 'Signature algorithm',
            finding: 'SHA256 with RSA',
            severity: 'ok',
          },
          {
            title: 'Key',
            finding: 'RSA 4096 bits (exponent is 65537)',
            severity: 'ok',
          },
          {
            title: 'Common names',
            finding: '*.targetC.com',
            severity: 'ok',
          },
          {
            title: 'Alternative names',
            finding: '*.targetC.com targetC.com',
            severity: 'info',
          },
          {
            title: 'Cert Trusted',
            finding: 'Ok via SAN wildcard and CN wildcard (SNI mandatory)',
            severity: 'ok',
          },
          {
            title: 'Valid from',
            finding: '2024-07-17 08:29',
            severity: 'info',
          },
          {
            title: 'Valid until',
            finding: '2025-07-17 08:29',
            severity: 'ok',
          },
          {
            title: 'DNS CAA',
            finding: '--',
            severity: 'low',
          },
          {
            title: 'Issuer',
            finding: 'Certum Domain Validation CA SHA2 (Unizeto Technologies S.A. from PL)',
            severity: 'info',
          },
          {
            title: 'Cert Expiration',
            finding: '301',
            severity: 'ok',
          },
        ],
      },
      {
        title: 'HTTP Responses',
        detail: [
          {
            title: 'HTTP status code',
            finding: '200 OK (/)',
            severity: 'info',
          },
          {
            title: 'Strict Transport Security (HSTS)',
            finding: 'not offered',
            severity: 'low',
          },
          {
            title: 'HTTP Public Key Pinning',
            finding: 'No support for HTTP Public Key Pinning',
            severity: 'info',
          },
          {
            title: 'HTTP server signature',
            finding: 'targetCServer-Timing: total;dur=168',
            severity: 'info',
          },
          {
            title: 'HTTP application signature',
            finding: 'No application banner found',
            severity: 'info',
          },
        ],
      },
      {
        title: 'Ciphers',
        detail: [
          {
            title: 'NULL cipher suite',
            detail: null,
            finding: 'not offered',
            severity: 'ok',
          },
          {
            title: 'Anonymous NULL cipher suite',
            detail: null,
            finding: 'not offered',
            severity: 'ok',
          },
          {
            title: 'Export cipher suite',
            detail: null,
            finding: 'not offered',
            severity: 'ok',
          },
          {
            title: 'LOW cipher suite',
            detail: null,
            finding: 'not offered',
            severity: 'ok',
          },
          {
            title: 'Triple DES / IDEA cipher suite',
            detail: null,
            finding: 'offered',
            severity: 'medium',
          },
        ],
      },
    ],
    days_left: 301,
    cert_status: 'valid',
    total_vulnerabilities: 4,
  },
}
const SMTP_DATA = [
  {
    id: 147,
    target: 'targetC.com',
    data: {
      host: 'targetC.com',
      score: 40,
      mailserver: {
        ptr: {
          data: ['mail.targetC.com --> 185.196.150.130 --> mail.targetC.com. --> 185.196.150.130'],
          title: 'PTR Record',
          detail: 'OK. All mail servers have reverse DNS entries configured correctly.',
          status: 'pass',
          description:
            'All mail servers should have a reverse DNS (PTR) entry for each IP address (RFC 1912). Missing reverse DNS entries will make many mail servers to reject your e-mails or mark them as SPAM.',
        },
        spf: {
          data: [
            'All nameservers failed to answer the query spf.sendingblue.com. IN TXT: Server 185.143.235.245 UDP port 53 answered NOTAUTH; Server 185.143.232.253 UDP port 53 answered NOTAUTH',
          ],
          title: 'SPF Record',
          detail: "Domain doesn't have SPF record. TXT RECORD :",
          status: 'fail',
          description:
            "SPF (Sender Policy Framework) record is designed to prevent e-mail spoofing. Typical SPF record would be: 'v=spf1 a mx ~all' ",
        },
        auth: {
          data: [],
          title: 'Auth',
          detail: 'Unable to detect authentication method.',
          status: 'fail',
          description:
            'SMTP Authentication, often abbreviated SMTP AUTH, is an extension of the Simple Mail Transfer Protocol (SMTP) whereby a client may log in using any authentication mechanism supported by the server.',
        },
        expn: {
          data: [],
          title: 'EXPN Command',
          detail: "OK. 'EXPN' command is not enable.",
          status: 'pass',
          description:
            "The SMTP 'EXPN' command allows you to expand a mailing list or alias, to see where mail addressed to the alias actually goes.The 'EXPN' command can be used by attackers to learn about valid usernames on the target system.",
        },
        help: {
          data: ['5.5.2 Error: command not recognized'],
          title: 'Help Command',
          detail: "OK. 'HELP' command is not enable.",
          status: 'pass',
          description:
            'HELP command asks for help from the mail server and mail server response could contain information about the mail server and it assets.',
        },
        vrfy: {
          data: ['252 2.0.0 info@targetC.com'],
          title: 'VRFY Command',
          detail: "OK. 'VRFY' command is not enable. details:",
          status: 'pass',
          description:
            "The SMTP 'VRFY' command allows you to verify whether a the system can deliver mail to a particular user. The 'VRFY' command can be used by attackers to learn about valid usernames on the target system.",
        },
        dmarc: {
          data: [
            "parameter 'sp' --> invalid value for sp, value: none",
            'returned DMARC record :',
            '"v=DMARC1; p=none; sp=none; rua=mailto:dmarc@mailinblue.com!10m; ruf=mailto:dmarc@mailinblue.com!10m; rf=afrf; pct=100; ri=86400"',
          ],
          title: 'DMARC Record',
          detail: "Domain doesn't have DMARC record.",
          status: 'fail',
          description:
            'DMARC (Domain-based Message Authentication, Reporting & Conformance) helps reducing potential of email-based abuse such as spam and phishing e-mails.',
        },
        title: 'Mail Server',
        spoofing: {
          data: [],
          title: 'Email spoofing',
          detail: 'mail server is vulnerable to email spoofing.',
          status: 'fail',
          description:
            'Spoofing is the act of disguising a communication or identity so that it appears to be associated with a trusted, authorized source. Spoofing attacks can take many forms, from the common email spoofing attacks that are deployed in phishing campaigns to caller ID spoofing attacks that are often used to commit fraud. Attackers may also target more technical elements of an organization’s network, such as an IP address, domain name system (DNS) server, or Address Resolution Protocol (ARP) service, as part of a spoofing attack.',
        },
        openrelay: {
          data: ["{'info@github.com':(554,b'5.7.1<info@github.com>:Relayaccessdenied')}"],
          title: 'Open Relay',
          detail: 'OK. Seems mail server is not vulnerable to open relay.',
          status: 'pass',
          description:
            "An 'open' SMTP relay is an SMTP server which allows mail to be sent by an offsite user to an offsite user.It is common for spammers to abuse open SMTP relays, sending thousands of untraceable messages through the server. This can seriously impact the performance of the server, possibly rendering the server inoperable.",
        },
        blacklisted: {
          data: ['https://www.spamhaus.org'],
          title: 'Blacklist',
          detail: 'mail server is blacklisted. mail server is blacklisted in below sites:',
          status: 'fail',
          description:
            "Many mail servers block email from IP addresses used by mail servers accused of sending spam. If your mail server's IP address is “blacklisted”, mail sent by your server will bounce back or simply disappear.",
        },
      },
      smtp_address: 'mail.targetC.com',
    },
  },
  {
    id: 144,
    target: 'targetI.com',
    data: {
      host: 'targetI.com',
      score: 75,
      mailserver: {
        ptr: {
          data: ['Edge.targetI.com --> 185.196.150.140 --> Edge.targetI.com. --> 185.196.150.140'],
          title: 'PTR Record',
          detail: 'OK. All mail servers have reverse DNS entries configured correctly.',
          status: 'pass',
          description:
            'All mail servers should have a reverse DNS (PTR) entry for each IP address (RFC 1912). Missing reverse DNS entries will make many mail servers to reject your e-mails or mark them as SPAM.',
        },
        spf: {
          data: [
            'SPF record:',
            'v=spf1 mx a:edge.targetI.com a:EXC813EDG03.targetI.com ip4:185.196.150.0/23 -all',
          ],
          title: 'SPF Record',
          detail: 'OK. Found SPF record:',
          status: 'pass',
          description:
            "SPF (Sender Policy Framework) record is designed to prevent e-mail spoofing. Typical SPF record would be: 'v=spf1 a mx ~all' ",
        },
        auth: {
          data: [],
          title: 'Auth',
          detail: 'Unable to detect authentication method.',
          status: 'pass',
          description:
            'SMTP Authentication, often abbreviated SMTP AUTH, is an extension of the Simple Mail Transfer Protocol (SMTP) whereby a client may log in using any authentication mechanism supported by the server.',
        },
        expn: {
          data: [],
          title: 'EXPN Command',
          detail: "OK. 'EXPN' command is not enable.",
          status: 'pass',
          description:
            "The SMTP 'EXPN' command allows you to expand a mailing list or alias, to see where mail addressed to the alias actually goes.The 'EXPN' command can be used by attackers to learn about valid usernames on the target system.",
        },
        help: {
          data: [
            'The following commands are recognized\nauth\tdata\tehlo\thelo\nhelp\tmail\tnoop\tquit\nrcpt\trset\tvrfy',
          ],
          title: 'Help Command',
          detail: "'HELP' command is enable:",
          status: 'fail',
          description:
            'HELP command asks for help from the mail server and mail server response could contain information about the mail server and it assets.',
        },
        vrfy: {
          data: ['252 ok'],
          title: 'VRFY Command',
          detail: "OK. 'VRFY' command is not enable. details:",
          status: 'pass',
          description:
            "The SMTP 'VRFY' command allows you to verify whether a the system can deliver mail to a particular user. The 'VRFY' command can be used by attackers to learn about valid usernames on the target system.",
        },
        dmarc: {
          data: [
            "parameter 'rf' --> not found",
            'returned DMARC record :',
            '"v=DMARC1; p=reject; rua=mailto:spambox@targetI.com;"',
          ],
          title: 'DMARC Record',
          detail: "Domain doesn't have DMARC record.",
          status: 'fail',
          description:
            'DMARC (Domain-based Message Authentication, Reporting & Conformance) helps reducing potential of email-based abuse such as spam and phishing e-mails.',
        },
        title: 'Mail Server',
        spoofing: {
          data: ['connection refused or timeout'],
          title: 'Email spoofing',
          detail: 'OK. Seems mail server is not vulnerable to  email spoofing.',
          status: 'pass',
          description:
            'Spoofing is the act of disguising a communication or identity so that it appears to be associated with a trusted, authorized source. Spoofing attacks can take many forms, from the common email spoofing attacks that are deployed in phishing campaigns to caller ID spoofing attacks that are often used to commit fraud. Attackers may also target more technical elements of an organization’s network, such as an IP address, domain name system (DNS) server, or Address Resolution Protocol (ARP) service, as part of a spoofing attack.',
        },
        openrelay: {
          data: ["{'info@github.com': (550, b'#5.1.0 Address rejected.')}"],
          title: 'Open Relay',
          detail: 'OK. Seems mail server is not vulnerable to open relay.',
          status: 'pass',
          description:
            "An 'open' SMTP relay is an SMTP server which allows mail to be sent by an offsite user to an offsite user.It is common for spammers to abuse open SMTP relays, sending thousands of untraceable messages through the server. This can seriously impact the performance of the server, possibly rendering the server inoperable.",
        },
        blacklisted: {
          data: [],
          title: 'Blacklist',
          detail: 'OK. mail server is not blacklisted.',
          status: 'pass',
          description:
            "Many mail servers block email from IP addresses used by mail servers accused of sending spam. If your mail server's IP address is “blacklisted”, mail sent by your server will bounce back or simply disappear.",
        },
      },
      smtp_address: 'Edge.targetI.com',
    },
  },
  {
    id: 143,
    target: 'targetH.com',
    data: {
      host: 'targetH.com',
      score: 0,
      mailserver: 'not found',
    },
  },
  {
    id: 118,
    target: 'targetA.com',
    data: {
      host: 'targetA.com',
      score: 0,
      mailserver: 'not found',
    },
  },
]
const SCAN_DATA = [
  {
    id: '3',
    target: 'netbaan.net',
    workflow: 'asset discovery',
    description: 'qwew',
    status: 'running',
    start_time: '2024-10-18T21:31:04.276716Z',
    rate: 'once',
    vulnerabilities: [],
  },
  {
    id: '4',
    target: 'netbaan.net',
    workflow: 'asset discovery',
    description: 'test',
    status: 'running',
    start_time: '2024-10-18T13:46:04.814904Z',
    rate: 'once',
    vulnerabilities: [],
  },
]
const SCAN_DAG_DATA = [
  {
    id: 'passive_scan_once',
    description: 'passive scan',
  },
  {
    id: 'active_scan_once',
    description: 'active scan',
  },
  {
    id: 'asset_discovery',
    description: 'asset discovery',
  },
]
const FILTER_OPTION_ASSETS_OPTIONS = {
  score: [
    {
      name: 'A',
      count: 561,
    },
    {
      name: 'B',
      count: 1,
    },
    {
      name: 'C',
      count: 14,
    },
  ],
  port: [
    {
      name: '80',
      count: 98,
    },
    {
      name: '443',
      count: 99,
    },
  ],
  location: [
    {
      name: 'US',
      count: 9,
    },
    {
      name: 'IR',
      count: 88,
    },
  ],
  ipv4: [
    {
      name: '52.6.51.18',
      count: 3,
    },
    {
      name: '3.18.255.247',
      count: 1,
    },
    {
      name: '34.224.149.186',
      count: 1,
    }
  ],
}
const FILTER_OPTION_VULNERABILITIES_OPTIONS = {
  domain: [
    {
      name: 'secure1.test.com',
      count: 1,
    },
    {
      name: 'www.test.com',
      count: 1,
    },
    {
      name: 'test.com',
      count: 1,
    },
  ],
  sensor: [
    {
      name: 'dns',
      count: 2,
    },
    {
      name: 'pscan',
      count: 31,
    },
    {
      name: 'ssl',
      count: 6,
    },
  ],
  severity: [
    {
      name: 'low',
      count: 4,
    },
    {
      name: 'medium',
      count: 17,
    },
    {
      name: 'info',
      count: 18,
    },
  ],
}
const FILTER_OPTION_BASIC = {
  options: FILTER_OPTION_ASSETS_OPTIONS,
  selected: {},
  sortAsc: '',
  sortDes: '',
  search: [],
}
const FILTER_OPTION_VULNERABILITIES = {
  options: FILTER_OPTION_VULNERABILITIES_OPTIONS,
  selected: {},
  search: '',
}
const FILTER_OPTION_IP_OPTIONS = {
  location: [
    {
      name: 'IR',
      count: 340,
    },
  ],
  asn: [
    {
      name: 'AS205585',
      count: 152,
    },

    {
      name: 'AS24940',
      count: 2,
    },
  ],
  asn_name: [
    {
      name: 'Noyan ABR Arvan Co',
      count: 44,
    },
  ],
  asn_domain: [
    {
      name: 'serverpars.com',
      count: 7,
    },
  ],
  version: [
    {
      name: 'ipv4',
      count: 595,
    },
  ],
  latitude: [
    {
      name: '35.6980018615722660',
      count: 337,
    },

    {
      name: '33.6637001037597660',
      count: 2,
    },
  ],
  longitude: [
    {
      name: '51.4114990234375000',
      count: 337,
    },

    {
      name: '8.6842002868652340',
      count: 16,
    },
  ],
  host_domain: [
    {
      name: 'lp.test1.com',
      count: 2,
    },
    {
      name: 'test2.com',
      count: 4,
    },
    {
      name: 'cdn.test4.com',
      count: 4,
    },
  ],
  cidr: [
    {
      name: '2a01:4f8::/32',
      count: 2,
    },
  ],
}
const FILTER_OPTION_IP = {
  options: FILTER_OPTION_IP_OPTIONS,
  selected: {},
}
const FILTER_OPTION = {
  assets: FILTER_OPTION_BASIC,
  ssl: FILTER_OPTION_BASIC,
  dnsRecord: FILTER_OPTION_BASIC,
  email: FILTER_OPTION_BASIC,
  port: FILTER_OPTION_BASIC,
  ip: FILTER_OPTION_BASIC,
  vulnerabilities: FILTER_OPTION_VULNERABILITIES,
  ticket: 'open',
}
const EXPOSURES = {
  ssl_count: 0,
  expiring_ssl_count: 0,
  techs_count: 132,
  ports_count: 33,
  hosts_count: 129,
  ipv4s_count: 38,
}
const IP = {
  total_pages: 1,
  current_page: 1,
  results: [
    {
      id: 'wefqwefqwefwe',
      host_domain: 'testaspnet.vulnweb.com',
      ip: '424.238.295.244',
      version: 'ipv4',
      loc: 'US',
      latitude: '45.8398590087890600',
      longitude: '-119.7005767822265600',
      cidr: '44.224.0.0/11',
      asn: 'AS16509',
      asn_name: null,
      asn_domain: null,
    },
    {
      id: ';09;90;9;90;',
      host_domain: 'testaspnet.vulnweb.com',
      ip: '44.238.219.244',
      version: 'ipv4',
      loc: 'US',
      latitude: '45.8398590087890600',
      longitude: '-119.7005767822265600',
      cidr: '44.224.0.0/11',
      asn: 'AS16509',
      asn_name: null,
      asn_domain: null,
    },
    {
      id: '345645634568697',
      host_domain: 'rest.test.com',
      ip: '35.281.188.86',
      version: 'ipv4',
      loc: 'US',
      latitude: '45.8398590087890600',
      longitude: '-119.7005767822265600',
      cidr: '35.80.0.0/12',
      asn: 'AS16509',
      asn_name: null,
      asn_domain: null,
    },
  ],
}

const PAYMENT_HISTORY = [
  {
    id: 'string',
    plan_name: 'string',
    amount: 'string',
    discount_amount: 'string',
    final_amount: 'string',
    payment_status: 'pending',
    payment_method: 'zarinpal',
  },
]

///////////////////////Attack surface page//////////////////////
const ATTACK_SURFACE_CARDS = {
  DNS: {
    title: 'DNS Records',
    link: `/attack-surface-explore/DNS Records`,
    count: 100,
    icon: <List className='svg-icon svg-icon-5x image_change_theme2' />,
    footer: [
      {
        name: 'A Records',
        value: '113',
        icon: <List className='svg-icon svg-icon-4x image_change_theme2' />,
      },
      {
        name: 'CNAME Records',
        value: '36',
        icon: <List className='svg-icon svg-icon-4x image_change_theme2' />,
      },
    ],
  },
  port: {
    title: 'Ports',
    link: `/attack-surface-explore/Ports`,
    count: 100,
    icon: <Port className='svg-icon svg-icon-5x image_change_theme2' />,
    chart: [
      {
        title: 'Close',
        count: 444,
        data: [1, 40, 60, 80],
      },
      {
        title: 'Open',
        count: 354,
        data: [100, 4, 6, 8],
      },
    ],
    footer: [
      {
        name: 'Domains',
        value: '113',
        icon: <Earth className='svg-icon svg-icon-4x image_change_theme2' />,
      },
      {
        name: 'IP',
        value: '36',
        icon: <Loc className='svg-icon svg-icon-4x image_change_theme2' />,
      },
    ],
  },
  domains: {
    title: 'Domains',
    link: `/attack-surface-explore/Domains`,
    count: 18,
    icon: <Earth className={'svg-icon svg-icon-5x image_change_theme2'} />,
    chart: [
      {
        title: 'live',
        count: 15,
        data: [22, 40, 90, 80, 20, 40, 30],
      },
      {
        title: 'monitored',
        count: 18,
        data: [60, 40, 90, 80, 20, 40, 30],
      },
    ],
    footer: [
      {
        name: 'IPs',
        value: '111',
        icon: <Loc className='svg-icon svg-icon-4x image_change_theme2' />,
      },
      {
        name: 'Ports',
        value: '23',
        icon: <Port className={'svg-icon svg-icon-4x image_change_theme2'} />,
      },
      {
        name: 'Vulns',
        value: '35',
        icon: <Bug className={'svg-icon svg-icon-4x image_change_theme2'} />,
      },
    ],
  },
  ip: {
    title: 'IP',
    link: `/attack-surface-explore/IP`,
    count: 2,
    icon: <Loc className='svg-icon svg-icon-5x image_change_theme2' />,
    chart: [
      {
        title: 'live',
        count: 2,
        data: [60, 40, 90, 80, 20, 40, 30],
      },
      {
        title: 'monitored',
        count: 3,
        data: [60, 40, 90, 80, 20, 40, 30],
      },
    ],
    footer: [
      {
        name: 'Domains',
        value: '5',
        icon: <Earth className='svg-icon svg-icon-4x image_change_theme2' />,
      },
      {
        name: 'Ports',
        value: '4',
        icon: <Port className={'svg-icon svg-icon-4x image_change_theme2'} />,
      },
      {
        name: 'Vulns',
        value: '8',
        icon: <Bug className={'svg-icon svg-icon-4x image_change_theme2'} />,
      },
    ],
  },
  app: {
    title: 'Applications',
    link: `/attack-surface-explore/Applications`,
    count: 1,
    icon: <Iphone className={'svg-icon svg-icon-5x image_change_theme2'} />,
    footer: [
      {
        name: 'IPs',
        value: '1',
        icon: <Loc className='svg-icon svg-icon-4x image_change_theme2' />,
      },
      {
        name: 'Vulns',
        value: '5',
        icon: <Bug className={'svg-icon svg-icon-4x image_change_theme2'} />,
      },
    ],
  },
  ssl: {
    link: `/attack-surface-explore/SSL`,
    icon: <Key className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'SSL',
    count: 100,
  },
  mail: {
    link: `/attack-surface-explore/Email Address`,
    icon: <MailAddress className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'Email Address',
    count: 100,
  },
  technologies: {
    link: `/attack-surface-explore/Technologies`,
    icon: <Tech className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'Technologies',
    count: 100,
  },
  leaked: {
    link: `/attack-surface-explore/Leaked Data`,
    icon: <File className='svg-icon svg-icon-5x image_change_theme2' />,
    title: 'Leaked Data',
    count: 100,
  },
}
const EXPLORER_MENU_CARDS = [
  {
    name: 'Domains',
    icon: <Earth className='svg-icon svg-icon-4x image_change_theme2' />,
  },
  {
    name: 'IP',
    icon: <Loc className='svg-icon svg-icon-4x image_change_theme2' />,
  },
  {
    name: 'Applications',
    icon: <Iphone className='svg-icon svg-icon-4x image_change_theme2' />,
  },
  {
    name: 'DNS Records',
    icon: <List className='svg-icon svg-icon-4x image_change_theme2' />,
  },
  {
    name: 'Ports',
    icon: <Port className='svg-icon svg-icon-4x image_change_theme2' />,
  },
  {
    name: 'SSL',
    icon: <Key className='svg-icon svg-icon-4x image_change_theme2' />,
  },
  {
    name: 'Email Address',
    icon: <MailAddress className='svg-icon svg-icon-4x image_change_theme2' />,
  },
  {
    name: 'Technologies',
    icon: <Tech className='svg-icon svg-icon-4x image_change_theme2' />,
  },
  {
    name: 'Leaked Data',
    icon: <File className='svg-icon svg-icon-4x image_change_theme2' />,
  },
]
//////////exlpore/////////
const EXPLORE_IPS_DATA = [
  {
    ip: 'www.example.com',
    dns: 'ce2-18-99-147qwdqwdus-est.2component.amazon.com',
    live: true,
    mod: false,
    id: 1,
  },
  {
    ip: 'www.example2.com',
    dns: 'ce2-18-99-147asdDus-est.2component.amazon.com',
    live: true,
    mod: false,
    id: 2,
  },
  {
    ip: 'www.example3.com',
    dns: 'ce2-18-99-147uU,MUs-est.2component.amazon.com',
    live: true,
    mod: false,
    id: 3,
  },
]
const EXPLORE_IPS_DETAILS_DATA = {
  lat: 51.505,
  lng: -0.09,
  ip: '102.134.533.100',
  status: 'offline',
  ping: '',
  loc: 'IR',
  reverse: 'ce2-18-99-147us-est.ASVASD.amazon.com',
  cloud_account: 'Services Prod',
  cloud_id: 'i-782398',
  ans: 'AS14820',
}
const EXPLORE_DNS_Records_DATA = [
  {
    record: 'www.example.com',
    value: '716.223.54.146',
    type: 'A',
    domain: 'example.com',
    detected_jalali: '1403/06/29 10:10:10',
    detected: '2024-10-11T16:20:45.680682Z',
  },
  {
    record: 'www.example.com',
    value: '768.223.54.146',
    type: 'A',
    domain: 'example.com',
    detected_jalali: '1403/06/29 10:10:10',
    detected: '2024-10-11T16:20:45.680682Z',
  },
  {
    record: 'www.example.com',
    value: '76.223.534.146',
    type: 'A',
    domain: 'example.com',
    detected_jalali: '1403/06/29 10:10:10',
    detected: '2024-10-11T16:20:45.680682Z',
  },
]
const EXPLORE_PORTS_DATA = [
  {
    port_number: 22,
    asset: '922.223.158.163',
    protocol: 'tcp',
    service: 'OpenSSH',
    status: true,
  },
  {
    port_number: 22,
    asset: '314.170.51.118',
    protocol: 'tcp',
    service: 'OpenSSH',
    status: true,
  },
  {
    port_number: 22,
    asset: '169.524.125.3',
    protocol: 'tcp',
    service: 'OpenSSH',
    status: true,
  },
]
const EXPLORE_EMAILS_DATA = [
  {
    email: 'caa-TEST@hackerone.com',
    username: 'caa-TEST',
  },
  {
    email: 'test@hackerone.com',
    username: 'test',
  },
  {
    email: 'test@hackerone.com',
    username: 'test',
  },
]
////////////////////////////Dos Page///////////////////////////

const overview = [
  {
    title: 'Requests (period)',
    value: '68.8 K',
    class: '',
  },
  {
    title: 'success',
    value: '97.0%',
    class: '',
  },
  {
    title: 'conns',
    value: '314',
    class: '',
  },
  {
    title: 'reqs',
    value: '768',
    class: '',
  },
  {
    title: 'success (2m)',
    value: '92.6%',
    class: '',
  },
  {
    title: 'HTTP 1/2xx (2m)',
    value: '496',
    class: 'text-success',
  },
  {
    title: 'HTTP 3xx (2m)',
    value: '200',
    class: 'text-primary',
  },
  {
    title: 'HTTP 4xx (2m)',
    value: '72',
    class: 'text-warning',
  },
  {
    title: 'HTTP 3xx (2m)',
    value: '0',
    class: 'text-danger',
  },
]
const httpRequestsIngress_LineChart = {
  x: [
    '2024/10/01',
    '2024/10/02',
    '2024/10/03',
    '2024/10/04',
    '2024/10/05',
    '2024/10/06',
    '2024/10/07',
    '2024/10/08',
    '2024/10/09',
    '2024/10/10',
  ],
  y: [
    {
      name: 'data1',
      data: [800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887],
    },
    {
      name: 'data2',
      data: [700, 100, 490, 1200, 700, 1200, 944, 172, 612, 987],
    },
    {
      name: 'data3',
      data: [900, 200, 290, 900, 600, 1200, 444, 372, 512, 887],
    },
    {
      name: 'data4',
      data: [600, 100, 90, 1100, 1000, 1200, 544, 772, 212, 487],
    },
    {
      name: 'data5',
      data: [100, 300, 590, 1000, 500, 1200, 644, 672, 912, 287],
    },
  ],
}
const httpStatusCode_LineChart = {
  x: [
    '2024/10/01',
    '2024/10/02',
    '2024/10/03',
    '2024/10/04',
    '2024/10/05',
    '2024/10/06',
    '2024/10/07',
    '2024/10/08',
    '2024/10/09',
    '2024/10/10',
  ],
  y: [
    {
      name: 'data1',
      data: [800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887],
    },
    {
      name: 'data2',
      data: [700, 100, 490, 1200, 700, 1200, 944, 172, 612, 987],
    },
    {
      name: 'data3',
      data: [900, 200, 290, 900, 600, 1200, 444, 372, 512, 887],
    },
    {
      name: 'data4',
      data: [600, 100, 90, 1100, 1000, 1200, 544, 772, 212, 487],
    },
    {
      name: 'data5',
      data: [100, 300, 590, 1000, 500, 1200, 644, 672, 912, 287],
    },
  ],
}
const totalHttpRequest_ColumnChart = {
  x: [
    '2024/10/01',
    '2024/10/02',
    '2024/10/03',
    '2024/10/04',
    '2024/10/05',
    '2024/10/06',
    '2024/10/07',
    '2024/10/08',
    '2024/10/09',
    '2024/10/10',
    '2024/10/11',
    '2024/10/12',
    '2024/10/13',
    '2024/10/14',
    '2024/10/15',
    '2024/10/16',
    '2024/10/17',
    '2024/10/18',
    '2024/10/19',
    '2024/10/20',
  ],
  y: [
    800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672,
    312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200,
    144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887,
  ],
}
const httpStatusCode_tag = [
  {
    color: '#02694a',
    title: 'HTTP 200',
  },
  {
    color: '#255a8f',
    title: 'HTTP 404',
  },
  {
    color: '#9c8705',
    title: 'HTTP 400',
  },
  {
    color: '#c02407',
    title: 'HTTP 500',
  },
  {
    color: '#75041e',
    title: 'HTTP 505',
  },
]
const latencyAveragePercentiles_LineChart = {
  x: [
    '2024/10/01',
    '2024/10/02',
    '2024/10/03',
    '2024/10/04',
    '2024/10/05',
    '2024/10/06',
    '2024/10/07',
    '2024/10/08',
    '2024/10/09',
    '2024/10/10',
  ],
  y: [
    {
      name: 'data1',
      data: [800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887],
    },
    {
      name: 'data2',
      data: [700, 100, 490, 1200, 700, 1200, 944, 172, 612, 987],
    },
    {
      name: 'data3',
      data: [900, 200, 290, 900, 600, 1200, 444, 372, 512, 887],
    },
    {
      name: 'data4',
      data: [600, 100, 90, 1100, 1000, 1200, 544, 772, 212, 487],
    },
    {
      name: 'data5',
      data: [100, 300, 590, 1000, 500, 1200, 644, 672, 912, 287],
    },
  ],
}
const latencyAveragePercentiles_tag = [
  {
    color: '#02694a',
    value: '0.99',
    min: '0.01 s',
    max: '1 s',
    ave: '0.55 s',
  },
  {
    color: '#255a8f',
    value: '0.99',
    min: '0.01 s',
    max: '1 s',
    ave: '0.55 s',
  },
  {
    color: '#9c8705',
    value: '0.99',
    min: '0.01 s',
    max: '1 s',
    ave: '0.55 s',
  },
  {
    color: '#c02407',
    value: '0.99',
    min: '0.01 s',
    max: '1 s',
    ave: '0.55 s',
  },
  {
    color: '#75041e',
    value: 'Average',
    min: '0.01 s',
    max: '1 s',
    ave: '0.55 s',
  },
]
const newConnections_ColumnChart = {
  x: [
    '2024/10/01',
    '2024/10/02',
    '2024/10/03',
    '2024/10/04',
    '2024/10/05',
    '2024/10/06',
    '2024/10/07',
    '2024/10/08',
    '2024/10/09',
    '2024/10/10',
    '2024/10/11',
    '2024/10/12',
    '2024/10/13',
    '2024/10/14',
    '2024/10/15',
    '2024/10/16',
    '2024/10/17',
    '2024/10/18',
    '2024/10/19',
    '2024/10/20',
  ],
  y: [
    800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672,
    312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200,
    144, 672, 312, 887, 800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887,
  ],
}
const totalConnection_LineChart = {
  x: [
    '2024/10/01',
    '2024/10/02',
    '2024/10/03',
    '2024/10/04',
    '2024/10/05',
    '2024/10/06',
    '2024/10/07',
    '2024/10/08',
    '2024/10/09',
    '2024/10/10',
  ],
  y: [
    {
      name: 'data1',
      data: [800, 900, 190, 1100, 200, 1200, 144, 672, 312, 887],
    },
  ],
}

////////////////////////Workflow///////////////////////////////
const WORKFLOW_Drawer_NODES = [
  {
    category: 'Recon',
    nodes: [
      {
        id: 'Asset Discovery',
        label: 'Asset Discovery',
      },
      {
        id: 'Waymore',
        label: 'Waymore',
      },
      {
        id: 'DNS',
        label: 'DNS',
      },
    ],
  },
  {
    category: 'OSINT',
    nodes: [
      {
        id: 'bbot',
        label: 'bbot',
      },
    ],
  },
  {
    category: 'Discovery',
    nodes: [
      {
        id: 'Crawler',
        label: 'Crawler',
      },
      {
        id: 'SMTP',
        label: 'SMTP',
      },
      {
        id: 'Screenshot',
        label: 'Screenshot',
      },
    ],
  },
  {
    category: 'Web Scanner',
    nodes: [
      {
        id: 'Acunetix',
        label: 'Acunetix',
      },
      {
        id: 'Burp Suite',
        label: 'Burp Suite',
      },
      {
        id: 'Invicti',
        label: 'Invicti',
      },
      {
        id: 'Core Impact',
        label: 'Core Impact',
      },
      {
        id: 'Cms Scanner',
        label: 'Cms Scanner',
      },
    ],
  },
  {
    category: 'Vulnerability Assessment',
    nodes: [
      {
        id: 'Greenbone',
        label: 'Greenbone',
      },
      {
        id: 'Nessus',
        label: 'Nessus',
      },
      {
        id: 'Vulners',
        label: 'Vulners',
      },
      {
        id: 'Nexpose',
        label: 'Nexpose',
      },
      {
        id: 'Metasploit',
        label: 'Metasploit',
      },
      {
        id: 'SSL',
        label: 'SSL',
      },
    ],
  },
  {
    category: 'Network',
    nodes: [
      {
        id: 'nmap',
        label: 'nmap',
      },
      {
        id: 'Dns Records',
        label: 'Dns Records',
      },
      {
        id: 'Harvester',
        label: 'Harvester',
      },
    ],
  },
  {
    category: 'Fuzzer',
    nodes: [
      {
        id: 'ffuf',
        label: 'ffuf',
      },
    ],
  },
  {
    category: 'Code Analysis',
    nodes: [
      {
        id: 'secretfinder',
        label: 'secretfinder',
      },
    ],
  },
  {
    category: 'Misconfiguration',
    nodes: [
      {
        id: 'Waf-Analyzer',
        label: 'Waf-Analyzer',
      },
      {
        id: 'DoS',
        label: 'DoS',
      },
    ],
  },
]
const WORKFLOW_TABLE = [
  {
    id: 'passive_scan',
    name: 'Passive Scan',
    description: 'Enumerate all subdomains and check vulnerabilities using passive scan',
    created: '2023-06-17 15:15:12+00:00',
    created_jalali: '1403/7/29 10:10:10',
  },
  {
    id: 'asset_discovery',
    name: ' Asset Discovery',
    description: 'Enumerate all subdomains and asset',
    created: '2023-06-17 15:15:12+00:00',
    created_jalali: '1403/7/29 10:10:10',
  },
  {
    id: 'active_scan',
    name: 'Active Scan',
    description:
      'Enumerate all subdomains and check vulnerabilities using active scan. this workflow could take a while',
    created: '2023-06-17 15:15:12+00:00',
    created_jalali: '1403/7/29 10:10:10',
  },
]
//////passive_scan
const WORKFLOW_INITIAL_NODES: Node[] = [
  {
    id: '1',
    type: 'input',
    data: {label: <div className='label'>Initial</div>, details: 'test 1'},
    position: {y: 250, x: 0},
    sourcePosition: Position.Right, // Use imported Position type
    className: 'large_item',
  },
  {
    id: '2',
    type: 'output',
    data: {label: <div className='label'>DNS</div>, details: 'test 2'},
    position: {y: 100, x: 200},
    targetPosition: Position.Left, // Use imported Position type
    className: 'large_item',
  },
  {
    id: '3',
    type: 'output',
    data: {label: <div className='label'>SMTP</div>, details: 'test 3'},
    position: {y: 400, x: 200},
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '4',
    data: {label: <div className='label'>Assets</div>, details: 'test 4'},
    position: {y: 250, x: 200},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '5',
    type: 'output',
    data: {label: <div className='label'>PScan</div>, details: 'test 5'},
    position: {y: 100, x: 400},
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '6',
    type: 'output',
    data: {label: <div className='label'>IP Info</div>, details: 'test 6'},
    position: {y: 200, x: 400},
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '7',
    data: {label: <div className='label'>lf</div>, details: 'test 7'},
    position: {y: 300, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '8',
    type: 'output',
    data: {label: <div className='label'>SSL</div>, details: 'test 8'},
    position: {y: 400, x: 400},
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '9',
    type: 'output',
    data: {label: <div className='label'>Screen Shot</div>, details: 'test 9'},
    position: {y: 300, x: 700},
    targetPosition: Position.Left,
    className: 'large_item',
  },
]

const WORKFLOW_INITIAL_EDGES: CustomEdge[] = [
  {
    id: '1-2',
    source: '1',
    sourceHandle: null,
    target: '2',
    targetHandle: null,
  },
  {
    id: '1-3',
    source: '1',
    target: '3',
  },
  {
    id: '1-4',
    source: '1',
    target: '4',
  },
  {
    id: '4-5',
    source: '4',
    target: '5',
  },
  {
    id: '4-6',
    source: '4',
    target: '6',
  },
  {
    id: '4-7',
    source: '4',
    target: '7',
  },
  {
    id: '4-8',
    source: '4',
    target: '8',
  },
  {
    id: '7-9',
    source: '7',
    target: '9',
  },
]
/////asset_discovery
const WORKFLOW_ASSET_DISCOVERY_NODES: Node[] = [
  {
    id: '1',
    type: 'input',
    data: {label: <div className='label'>Initial</div>},
    position: {y: 250, x: 0},
    sourcePosition: Position.Right, // Use imported Position type
    className: 'large_item',
  },
  {
    id: '4',
    data: {label: <div className='label'>Assets</div>},
    type: 'output',
    position: {y: 250, x: 200},
    targetPosition: Position.Left,
    className: 'large_item',
  },
]

const WORKFLOW_ASSET_DISCOVERY_EDGES: CustomEdge[] = [
  {
    id: '1-2',
    source: '1',
    sourceHandle: null,
    target: '4',
    targetHandle: null,
  },
]
////active_scan
const WORKFLOW_ACTIVE_SCAN_NODES: Node[] = [
  {
    id: '1',
    type: 'input',
    data: {label: <div className='label'>Initial</div>},
    position: {y: 375, x: -300},
    sourcePosition: Position.Right, // Use imported Position type
    className: 'large_item',
  },
  {
    id: '2',
    data: {label: <div className='label'>Assets</div>},
    position: {y: 375, x: -100},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '3',
    data: {label: <div className='label'>DNS</div>},
    position: {y: -100, x: 300},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '4',
    data: {label: <div className='label'>SSL</div>},
    position: {y: 0, x: 300},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '5',
    data: {label: <div className='label'>lf</div>},
    position: {y: 375, x: 125},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '6',
    data: {label: <div className='label'>PScan</div>},
    position: {y: 750, x: 300},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '7',
    data: {label: <div className='label'>Harvester</div>},
    position: {y: 850, x: 300},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '8',
    data: {label: <div className='label'>SMTP</div>},
    position: {y: 950, x: 300},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '9',
    data: {label: <div className='label'>DNS Recrds</div>},
    position: {y: 75, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '18',
    data: {label: <div className='label'>Nessus</div>},
    position: {y: 150, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '10',
    data: {label: <div className='label'>Acunetix</div>},
    position: {y: 225, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '11',
    data: {label: <div className='label'>Fuzzer</div>},
    position: {y: 300, x: 525},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '19',
    data: {label: <div className='label'>Crawler</div>},
    position: {y: 300, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '12',
    data: {label: <div className='label'>Screenshot</div>},
    position: {y: 375, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '13',
    data: {label: <div className='label'>Tech</div>},
    position: {y: 450, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '17',
    data: {label: <div className='label'>CVE Search</div>},
    position: {y: 450, x: 525},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '20',
    data: {label: <div className='label'>Invicti</div>},
    position: {y: 525, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '14',
    data: {label: <div className='label'>IP Info</div>},
    position: {y: 600, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },
  {
    id: '15',
    data: {label: <div className='label'>SSL</div>},
    position: {y: 675, x: 400},
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    className: 'large_item',
  },

  {
    id: '16',
    type: 'output',
    data: {label: <div className='label'>Finish</div>},
    position: {y: 400, x: 1000},
    targetPosition: Position.Left,
    className: 'large_item',
  },
]

const WORKFLOW_ACTIVE_SCAN_EDGES: CustomEdge[] = [
  {
    id: '1-2',
    source: '1',
    sourceHandle: null,
    target: '2',
    targetHandle: null,
  },
  {
    id: '1-3',
    source: '1',
    sourceHandle: null,
    target: '3',
    targetHandle: null,
  },
  {
    id: '1-7',
    source: '1',
    sourceHandle: null,
    target: '7',
    targetHandle: null,
  },
  {
    id: '1-8',
    source: '1',
    sourceHandle: null,
    target: '8',
    targetHandle: null,
  },

  {
    id: '2-4',
    source: '2',
    sourceHandle: null,
    target: '4',
    targetHandle: null,
  },
  {
    id: '2-5',
    source: '2',
    sourceHandle: null,
    target: '5',
    targetHandle: null,
  },
  {
    id: '2-6',
    source: '2',
    sourceHandle: null,
    target: '6',
    targetHandle: null,
  },
  {
    id: '2-7',
    source: '2',
    sourceHandle: null,
    target: '7',
    targetHandle: null,
  },
  {
    id: '2-9',
    source: '2',
    sourceHandle: null,
    target: '9',
    targetHandle: null,
  },
  {
    id: '2-14',
    source: '2',
    sourceHandle: null,
    target: '14',
    targetHandle: null,
  },
  {
    id: '2-15',
    source: '2',
    sourceHandle: null,
    target: '15',
    targetHandle: null,
  },

  {
    id: '3-16',
    source: '3',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },
  {
    id: '5-20',
    source: '5',
    sourceHandle: null,
    target: '20',
    targetHandle: null,
  },

  {
    id: '4-16',
    source: '4',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '5-10',
    source: '5',
    sourceHandle: null,
    target: '10',
    targetHandle: null,
  },
  {
    id: '5-19',
    source: '5',
    sourceHandle: null,
    target: '19',
    targetHandle: null,
  },
  {
    id: '5-12',
    source: '5',
    sourceHandle: null,
    target: '12',
    targetHandle: null,
  },
  {
    id: '5-13',
    source: '5',
    sourceHandle: null,
    target: '13',
    targetHandle: null,
  },
  {
    id: '5-18',
    source: '5',
    sourceHandle: null,
    target: '18',
    targetHandle: null,
  },
  {
    id: '18-16',
    source: '18',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },
  {
    id: '20-16',
    source: '20',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '6-16',
    source: '6',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '7-16',
    source: '7',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '8-16',
    source: '8',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '9-16',
    source: '9',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '10-16',
    source: '10',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '11-19',
    source: '19',
    sourceHandle: null,
    target: '11',
    targetHandle: null,
  },

  {
    id: '11-16',
    source: '11',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },
  {
    id: '12-16',
    source: '12',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '13-17',
    source: '13',
    sourceHandle: null,
    target: '17',
    targetHandle: null,
  },
  {
    id: '17-16',
    source: '17',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '14-16',
    source: '14',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },

  {
    id: '15-16',
    source: '15',
    sourceHandle: null,
    target: '16',
    targetHandle: null,
  },
]
///////////////////////////price plan//////////////////////////

const PRICE_PLAN_CARD_DATA = [
  {
    name: 'Product 1',
    description: 'this is demo plan',
    features: [
      {
        title: 'OWASP TOP 10',
        status: true,
      },
      {
        title: 'One Scan',
        status: true,
      },
      {
        title: 'One domain',
        status: true,
      },
      {
        title: 'Cloud base',
        status: true,
      },
      {
        title: 'Ticketing',
        status: true,
      },
      {
        title: 'Comprehensive Report',
        status: true,
      },
    ],
    plans: [
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZ2',
        name: 'Basic',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'OWASP TOP 10',
            status: true,
          },
          {
            title: 'One Scan',
            status: true,
          },
          {
            title: 'One domain',
            status: true,
          },
          {
            title: 'Cloud base',
            status: true,
          },
          {
            title: 'Ticketing',
            status: true,
          },
          {
            title: 'Comprehensive Report',
            status: true,
          },
        ],
      },
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZ1',
        name: 'Pro',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'Everything in basic',
            status: true,
          },
          {
            title: 'Asset Discovery',
            status: true,
          },
          {
            title: 'Up to 5 scan',
            status: true,
          },
          {
            title: 'UP to 10 host',
            status: true,
          },
          {
            title: 'Cloud Based',
            status: true,
          },
          {
            title: 'Schedule Scan',
            status: true,
          },
          {
            title: 'Priority Support',
            status: true,
          },
        ],
      },
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZasx',
        name: 'Enterprise',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'Everything in Pro',
            status: true,
          },
          {
            title: 'Unlimited Scan',
            status: true,
          },
          {
            title: 'One domain UP to 10 host Unlimited Domains and IPs',
            status: true,
          },
          {
            title: 'Pentest on Demand',
            status: true,
          },
          {
            title: 'Dedicated Server',
            status: true,
          },
          {
            title: 'Internal & External Scanning',
            status: true,
          },
          {
            title: 'Security Expert Support',
            status: true,
          },
          {
            title: 'Integration to Monitoring Tools',
            status: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Product 2',
    description: 'this is demo plan',
    features: [
      {
        title: 'OWASP TOP 10',
        status: true,
      },
      {
        title: 'One Scan',
        status: true,
      },
      {
        title: 'One domain',
        status: true,
      },
      {
        title: 'Cloud base',
        status: true,
      },
      {
        title: 'Ticketing',
        status: true,
      },
      {
        title: 'Comprehensive Report',
        status: true,
      },
    ],
    plans: [
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZ2',
        name: 'Basic',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'OWASP TOP 10',
            status: true,
          },
          {
            title: 'One Scan',
            status: true,
          },
          {
            title: 'One domain',
            status: true,
          },
          {
            title: 'Cloud base',
            status: true,
          },
          {
            title: 'Ticketing',
            status: true,
          },
          {
            title: 'Comprehensive Report',
            status: true,
          },
        ],
      },
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZ1',
        name: 'Pro',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'Everything in basic',
            status: true,
          },
          {
            title: 'Asset Discovery',
            status: true,
          },
          {
            title: 'Up to 5 scan',
            status: true,
          },
          {
            title: 'UP to 10 host',
            status: true,
          },
          {
            title: 'Cloud Based',
            status: true,
          },
          {
            title: 'Schedule Scan',
            status: true,
          },
          {
            title: 'Priority Support',
            status: true,
          },
        ],
      },
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZasx',
        name: 'Enterprise',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'Everything in Pro',
            status: true,
          },
          {
            title: 'Unlimited Scan',
            status: true,
          },
          {
            title: 'One domain UP to 10 host Unlimited Domains and IPs',
            status: true,
          },
          {
            title: 'Pentest on Demand',
            status: true,
          },
          {
            title: 'Dedicated Server',
            status: true,
          },
          {
            title: 'Internal & External Scanning',
            status: true,
          },
          {
            title: 'Security Expert Support',
            status: true,
          },
          {
            title: 'Integration to Monitoring Tools',
            status: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Product 3',
    description: 'this is demo plan',
    features: [
      {
        title: 'OWASP TOP 10',
        status: true,
      },
      {
        title: 'One Scan',
        status: true,
      },
      {
        title: 'One domain',
        status: true,
      },
      {
        title: 'Cloud base',
        status: true,
      },
      {
        title: 'Ticketing',
        status: true,
      },
      {
        title: 'Comprehensive Report',
        status: true,
      },
    ],
    plans: [
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZ2',
        name: 'Basic',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'OWASP TOP 10',
            status: true,
          },
          {
            title: 'One Scan',
            status: true,
          },
          {
            title: 'One domain',
            status: true,
          },
          {
            title: 'Cloud base',
            status: true,
          },
          {
            title: 'Ticketing',
            status: true,
          },
          {
            title: 'Comprehensive Report',
            status: true,
          },
        ],
      },
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZ1',
        name: 'Pro',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'Everything in basic',
            status: true,
          },
          {
            title: 'Asset Discovery',
            status: true,
          },
          {
            title: 'Up to 5 scan',
            status: true,
          },
          {
            title: 'UP to 10 host',
            status: true,
          },
          {
            title: 'Cloud Based',
            status: true,
          },
          {
            title: 'Schedule Scan',
            status: true,
          },
          {
            title: 'Priority Support',
            status: true,
          },
        ],
      },
      {
        id: '01JBEQ3DABSGMMT85HY811KDRZasx',
        name: 'Enterprise',
        price: '20000000.00',
        discounted_price: '18000000.00',
        duration_days: 90,
        scan_limit: 30,
        description: 'this is demo plan',
        is_active: true,
        features: [
          {
            title: 'Everything in Pro',
            status: true,
          },
          {
            title: 'Unlimited Scan',
            status: true,
          },
          {
            title: 'One domain UP to 10 host Unlimited Domains and IPs',
            status: true,
          },
          {
            title: 'Pentest on Demand',
            status: true,
          },
          {
            title: 'Dedicated Server',
            status: true,
          },
          {
            title: 'Internal & External Scanning',
            status: true,
          },
          {
            title: 'Security Expert Support',
            status: true,
          },
          {
            title: 'Integration to Monitoring Tools',
            status: true,
          },
        ],
      },
    ],
  },
]

const PRICE_PLAN_TABLE = [

  {
    description: '_Tools',
    title: 'Tools',
    group: true,
    data: ['', '', ''],
  },
  {
    description: '_Web Scanner',
    title: 'Web Scanner',
    data: [true, true, true],
  },
  {
    description: '_Vulnerability Assessment',
    title: 'Vulnerability Assessment',
    data: [true, true, true],
  },
  {
    description: '_Mobile App Assessment',
    title: 'Mobile App Assessment',
    data: [false, true, true],
  },
  {
    description: '_Asset Discovery',
    title: 'Asset Discovery',
    data: [false, true, true],
  },
  {
    description: '_Certificate Expiration',
    title: 'Certificate Expiration',
    data: [false, true, true],
  },
  {
    description: '_Mail Vulnerabilities',
    title: 'Mail Vulnerabilities',
    data: [false, true, true],
  },
  {
    description: '_Leaked Credentials',
    title: 'Leaked Credentials',
    data: ['On Demand', 'On Demands', 'On Demands'],
  },
  {
    description: '_DoS/DDoS Simulator',
    title: 'DoS/DDoS Simulator',
    data: ['On Demand', 'On Demands', 'On Demands'],
  },
  {
    description: '_Support',
    title: 'Support',
    group: true,
    data: ['', '', ''],
  },
  {
    description: '_Ticketing',
    title: 'Ticketing',
    data: [true, true, true],
  },
  {
    description: '_AI Assistant',
    title: 'AI Assistant',
    data: [false, true, true],
  },
  {
    description: '_Security Expert',
    title: 'Security Expert',
    data: [false, true, true],
  },
  {
    description: '_Report',
    title: 'Report',
    group: true,
    data: ['', '', ''],
  },
  {
    description: '_PDF and HTML',
    title: 'PDF and HTML',
    data: [true, true, true],
  },
  {
    description: '_AI Report',
    title: 'AI Report',
    data: [false, true, true],
  },
  {
    description: '_Integration',
    title: 'Integration',
    data: [false, false, true],
  },
]
///////////////////////////SERVICES///////////////////////////

const SERVICES_DATA = [
  {
    icon: (
      <KTIcon iconName={'fingerprint-scanning'} className='text-white' style={{fontSize: '35px'}} />
    ),
    title: 'Web Vulnerability Scanner',
    description: 'details Web Vulnerability Scanner',
    status: true,
  },
  {
    icon: <KTIcon iconName={'cross-circle'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Vulnerability Assessment',
    description: 'details Vulnerability Assessment',
    status: true,
  },
  {
    icon: <KTIcon iconName={'graph-3'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Port Monitoring',
    description: 'details Port Monitoring',
    status: true,
  },
  {
    icon: <KTIcon iconName={'magnifier'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Dns Record Monitoring',
    description: 'details Dns Record Monitoring',
    status: true,
  },
  {
    icon: <KTIcon iconName={'archive'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Leaked Credential Reporting',
    description: 'details Leaked Credential Reporting',
    status: false,
  },
  {
    icon: <KTIcon iconName={'geolocation'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'IP Monitoring',
    description: 'details IP Monitoring',
    status: false,
  },
  {
    icon: <KTIcon iconName={'technology'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Technology Monitoring',
    description: 'details Technology Monitoring',
    status: true,
  },
  {
    icon: <KTIcon iconName={'lock-3'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'SSL Monitoring',
    description: 'details SSL Monitoring',
    status: true,
  },
  {
    icon: <KTIcon iconName={'search-list'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Certificate Expiration',
    description: 'details Certificate Expiration',
    status: true,
  },
  {
    icon: <KTIcon iconName={'technology-2'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'OSINT',
    description: 'details OSINT',
    status: true,
  },
  {
    icon: <KTIcon iconName={'sms'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Mail Vulnerabilities',
    description: 'details Mail Vulnerabilities',
    status: true,
  },
  {
    icon: <KTIcon iconName={'data'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'DoS Attack Emulator',
    description: 'details DoS Attack Emulator',
    status: false,
  },
  {
    icon: <KTIcon iconName={'screen'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Change Detection Monitoring',
    description: 'details Change Detection Monitoring',
    status: false,
  },
  {
    icon: <KTIcon iconName={'shield-cross'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Waf Assessment',
    description: 'details Waf Assessment',
    status: false,
  },
  {
    icon: <KTIcon iconName={'phone'} className='text-white' style={{fontSize: '35px'}} />,
    title: 'Mobile App Assessment',
    description: 'details Mobile App Assessment',
    status: false,
  },
  {
    icon: (
      <KTIcon
        iconName={'artificial-intelligence'}
        className='text-white'
        style={{fontSize: '35px'}}
      />
    ),
    title: 'AI Pentest',
    description: 'details AI Pentest',
    status: false,
  },
]
const LEAKED_DATA = {
  breaches: [
    {
      Name: 'AntiPublicComboList',
      Title: 'Anti Public Combo List',
      Domain: '',
      BreachDate: '2016-12-01',
      AddedDate: '2016-12-01T00:00:00Z',
      ModifiedDate: '2016-12-01T00:00:00Z',
      PwnCount: 457962538,
      Description:
        'In December 2016, a huge list of email address and password pairs appeared in a "combo list" referred to as "Anti Public". The list contained 458 million unique email addresses, many with multiple different passwords hacked from various online systems. The list was broadly circulated and used for "credential stuffing", that is attackers employ it in an attempt to identify other online systems where the account owner had reused their password. For detailed background on this incident, read Password reuse, credential stuffing and another billion records in Have I Been Pwned.',
      DataClasses: ['Email addresses', 'Passwords'],
      IsVerified: false,
      IsFabricated: false,
      IsSensitive: false,
      IsRetired: false,
      IsSpamList: false,
      IsMalware: false,
    },
  ],
}
//////////////////////////////Mock Data for setup srtor redux/////////////////////////////////

const demoData = {
  data: {
    dataDashboard: {
      data: DASHBOARD_DATA,
      expireTime: EXP_TIME,
    },
    dataScope: {
      data: TARGET_DATA,
      expireTime: EXP_TIME,
    },
    dataAsset: {
      data: ASSET_DATA,
      expireTime: EXP_TIME,
    },
    dataVulnerability: {
      data: VUL_DATA,
      expireTime: EXP_TIME,
    },
    dataDns: {
      data: DNS_DATA,
      expireTime: EXP_TIME,
    },
    dataSSL: {
      data: SSL_DATA,
      expireTime: EXP_TIME,
    },
    dataSmtp: {
      data: SMTP_DATA,
      expireTime: EXP_TIME,
    },
    dataScanDAG: {
      data: SCAN_DAG_DATA,
      expireTime: EXP_TIME,
    },
    dataScan: {
      data: SCAN_DATA,
      expireTime: EXP_TIME,
    },
    dataExposures: {
      data: EXPOSURES,
      expireTime: EXP_TIME,
    },
    dataDnsRecords: {
      data: EXPLORE_DNS_Records_DATA,
      expireTime: EXP_TIME,
    },
    dataEmails: {
      data: EXPLORE_EMAILS_DATA,
      expireTime: EXP_TIME,
    },
    dataPricePlans: {
      data: PRICE_PLAN_CARD_DATA,
      expireTime: EXP_TIME,
    },
    dataIp: {
      data: IP,
      expireTime: EXP_TIME,
    },
    dataPorts: {
      data: IP,
      expireTime: EXP_TIME,
    },
    dataIntegrations: {
      data: [],
      expireTime: EXP_TIME,
    },
    dataTargetNoPagination: {
      data: [],
      expireTime: EXP_TIME,
    },
    dataPaymentHistory: {
      data: PAYMENT_HISTORY,
      expireTime: EXP_TIME,
    },
    dataTicket: {
      data: [],
      expireTime: EXP_TIME,
    },
    dataServices: {
      data: SERVICES_DATA,
      expireTime: EXP_TIME,
    },
    dataLeaked: {
      data: LEAKED_DATA,
      expireTime: EXP_TIME,
    },
    dataTargetTypes: {
      data: [
        {
          id: '1',
          regex: '',
          name: '',
          exampl: '',
        },
      ],
      expireTime: EXP_TIME,
    },
    dataNotification: [],
  },
  aiAssistant: {
    vulnerabilitiesSelected: {
      id: null,
      title: null,
    },
    questionList: [],
  },
  filterOption: FILTER_OPTION,
  user: USER,
  accessToken: 'true',
  newNotification: false,
}
///////////////////////////////////////////////////////////////////////
const AI = [
  `
Business Logic vulnerability in a web application's shopping cart function allows for negative quantities, leading to credit. Here's the PoC:

HTTP Request:

POST /add-to-cart HTTP/1.1
Host: example.com
User-Agent: Mozilla/5.0
Content-Type: application/x-www-form-urlencoded

product_id=1001&quantity=-1

HTTP Response:

HTTP/1.1 200 OK
Content-Type: text/html
Content-Length: 1337
Server: Apache
`,
  `
Insecure Direct Object Reference (IDOR) vulnerability discovered in a website's user profile page. Here's the PoC:

HTTP Request:


GET /profile?id=2 HTTP/1.1
Host: example.com
User-Agent: Mozilla/5.0
Cookie: session=abcd1234

HTTP Response:

HTTP/1.1 200 OK
Content-Type: text/html
Content-Length: 1337
Server: Apache
`,
  `
Business Logic vulnerability in a web application's VIP subscription function allows users to extend trial period indefinitely. Here's the PoC:

HTTP Request:

POST /extend-trial HTTP/1.1
Host: example.com
User-Agent: Mozilla/5.0
Cookie: session=abcd1234

HTTP Response:

HTTP/1.1 200 OK
Content-Type: text/html
Content-Length: 1337
Server: Apache

<html>
...
<p>Your VIP trial period has been extended by 7 days.</p>
...
</html>
The application does not limit the number of times the trial period can be extended, leading to a Business Logic vulnerability.
`,
]
//////////////////////////////////////////////////////////////

export {
  demoData,
  TARGET_DATA,
  ASSET_DETAILS_DATA,
  VUL_DETAILS_DATA,
  SSL_DETAILS_DATA,
  ATTACK_SURFACE_CARDS,
  EXPLORER_MENU_CARDS,
  WORKFLOW_Drawer_NODES,
  WORKFLOW_TABLE,
  WORKFLOW_INITIAL_NODES,
  WORKFLOW_INITIAL_EDGES,
  ASSET_DETAILS_TIMELINE_DATA,
  EXPLORE_IPS_DATA,
  EXPLORE_IPS_DETAILS_DATA,
  EXPLORE_DNS_Records_DATA as EXPLORE_DNS_DATA,
  EXPLORE_PORTS_DATA,
  EXPLORE_EMAILS_DATA,
  PRICE_PLAN_CARD_DATA,
  PRICE_PLAN_TABLE,
  SERVICES_DATA,
  SCAN_DATA,
  ASSET_DATA,
  SSL_DATA,
  VUL_DATA,
  DNS_DATA,
  SMTP_DATA,
  WORKFLOW_ASSET_DISCOVERY_NODES,
  WORKFLOW_ASSET_DISCOVERY_EDGES,
  WORKFLOW_ACTIVE_SCAN_NODES,
  WORKFLOW_ACTIVE_SCAN_EDGES,
  AI,
}
//////////////////////////////////////////////////////////////
export {
  overview,
  httpRequestsIngress_LineChart,
  httpStatusCode_LineChart,
  httpStatusCode_tag,
  totalHttpRequest_ColumnChart,
  latencyAveragePercentiles_LineChart,
  latencyAveragePercentiles_tag,
  newConnections_ColumnChart,
  totalConnection_LineChart,
}
