import React, {lazy, Suspense, useState} from 'react'

// import {ChartsWidget8 as ChartsWidget} from '../../../../_metronic/partials/widgets/charts/ChartsWidget8'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import {RootState} from '../../../../setup/redux/Store'
import {useSelector} from 'react-redux'
const DefaultData = {
  x: ['start', '2023-06-17'],
  y: {
    critical: [0, 60],
    high: [0, 55],
    info: [0, 69],
    low: [0, 65],
    medium: [0, 50],
  },
}
const NoData = {
  x: ['', ''],
  y: {
    critical: [0, 0],
    high: [0, 0],
    info: [0, 0],
    low: [0, 0],
    medium: [0, 0],
  },
}
interface dataProp {
  x: string[]
  y: {
    critical: number[]
    high: number[]
    info: number[]
    low: number[]
    medium: number[]
  }
}
const MainChart = ({
  title = '',
  data,
  loading,
}: {
  data: dataProp
  title: string
  loading: boolean
}) => {
  const ChartsWidget8 = lazy(
    () => import('../../../../_metronic/partials/widgets/charts/ChartsWidget8')
  )
  const [severityValue, setSeverityValue] = useState({
    info: true,
    low: true,
    medium: true,
    high: true,
    critical: true,
  })
  const intl = useIntl()

  const user = useSelector((state: RootState) => state.root.user)
  return (
    <div className='col-6 h-400px'>
      <div className={`card card-body bg-body ${loading && 'skeleton'}`}>
        <div className='d-flex flex-row align-items-center'>
          <h3 className='card-title align-items-start flex-column   pt-2'>
            {intl.formatMessage({id: title})}
          </h3>
          <span
            data-tooltip-id={'my-tooltip' + title}
            data-tooltip-content={intl.formatMessage({
              id: 'Vulnerabilities Based on Severity tooltip',
            })}
          >
            <InfoIcon />
          </span>
          <ReactTooltip
            id={'my-tooltip' + title}
            style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
          />
        </div>
        <div style={!data?.x?.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}>
          <Suspense fallback={<div className=' h-300px'></div>}>
            <ChartsWidget8
              chartData={data?.x?.length ? data : !user?.is_user_scan ? DefaultData : NoData}
              severityValue={severityValue}
              className=''
            />
          </Suspense>
        </div>
        <div className={`row d-flex flex-row`}>
          <button
            value='info'
            onClick={() => {
              const copy = {...severityValue}
              copy.info = !copy.info
              setSeverityValue(copy)
            }}
            style={{border: `${severityValue?.info && '2px solid'}`}}
            className={`w-auto h-auto  ms-2 me-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body  ${
              severityValue?.info ? 'border border-1 border-gray-900 ' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2' />

            {intl.formatMessage({id: 'info'})}
          </button>
          <button
            value='low'
            onClick={() => {
              const copy = {...severityValue}
              copy.low = !copy.low
              setSeverityValue(copy)
            }}
            style={{border: `${severityValue?.low && '2px solid '}`}}
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
              severityValue?.low ? 'border border-1 border-gray-900' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2' />

            {intl.formatMessage({id: 'low'})}
          </button>
          <button
            value='medium'
            onClick={() => {
              const copy = {...severityValue}
              copy.medium = !copy.medium
              setSeverityValue(copy)
            }}
            style={{border: `${severityValue?.medium && '2px solid '}`}}
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
              severityValue.medium ? 'border border-1 border-gray-900' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2' />

            {intl.formatMessage({id: 'medium'})}
          </button>
          <button
            value='high'
            onClick={() => {
              const copy = {...severityValue}
              copy.high = !copy.high
              setSeverityValue(copy)
            }}
            style={{border: `${severityValue?.high && '2px solid '}`}}
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
              severityValue.high ? 'border border-1 border-gray-900' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2' />

            {intl.formatMessage({id: 'high'})}
          </button>
          <button
            value='critical'
            onClick={() => {
              const copy = {...severityValue}
              copy.critical = !copy.critical
              setSeverityValue(copy)
            }}
            style={{border: `${severityValue?.critical && '2px solid '}`}}
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body ${
              severityValue?.critical ? 'border border-1 border-gray-900' : 'border border-1'
            }`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2' />

            {intl.formatMessage({id: 'critical'})}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MainChart
