/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {lazy, Suspense, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
// import Map from './Map'
// import {EXPLORE_IPS_DETAILS_DATA as IPsDetails} from '../../../modules/auth/redux/DEMO_DATA'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'

interface Props {
  setId: React.Dispatch<React.SetStateAction<number | null>>
  id: number | null
}

export const DetailsDrawer = ({id, setId}: Props) => {
  const Map = lazy(() => import('./Map'))
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>()
  const intl = useIntl()
  const errorHandling = useErrorHandling()
  const fetchIpData = async (query = '?page=1', filterProps?: any) => {
    setLoading(true)
    setData(null)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/ip/${id}/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/ip/${query}${filterProps} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (id !== null) {
      fetchIpData()
    }
  }, [id])
  return (
    <>
      {/* begin::Demos drawer */}
      <div
        id='kt_engage_filters'
        className={`bg-body drawer drawer-end ${id !== null ? 'drawer-on' : ''}`}
        data-kt-drawer-width="{default:'350px', 'lg': '375px'}"
        style={{minWidth: 400, maxWidth: 400}}
      >
        <>
          {/* begin::Card  */}
          <div className='card shadow-none w-100' style={{height: 'fit-content'}}>
            {/* begin::Header */}
            <div className='card-header' id='kt_explore_header' style={{minHeight: 'unset'}}>
              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                  id='kt_engage_filters_close'
                  onClick={() => {
                    setId(null)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon-2 image_change_theme2'
                  />
                </button>
              </div>
            </div>
            <div className={`p-5 ${loading&&'skeleton'}`}>
              <Suspense fallback={<></>}>
                <Map lat={data?.latitude} lng={data?.longitude} />
              </Suspense>
              <h2 className='mt-5'>{data?.ip}</h2>
              <span className=' text-gray-500'>{intl.formatMessage({id: 'IP Address'})}</span>
              <h4 className='mt-10'>{intl.formatMessage({id: 'Information'})}</h4>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>{intl.formatMessage({id: 'Domain'})}</h6>
                <strong className=' text-capitalize text-nowrap mw-75 text_hide_width '>
                  {data?.host_domain}
                </strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>{intl.formatMessage({id: 'Location'})}</h6>
                <strong className=' text-capitalize '>{data?.loc}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>{intl.formatMessage({id: 'Version'})}</h6>
                <strong className=' text-capitalize'>{data?.version}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>{intl.formatMessage({id: 'CIDR'})}</h6>
                <strong className=' text-capitalize'>{data?.cidr}</strong>
              </div>

              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>{intl.formatMessage({id: 'ASN'})}</h6>
                <strong className='text-nowrap mw-75 text_hide_width'>{data?.asn}</strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>{intl.formatMessage({id: 'ASN Name'})}</h6>
                <strong className='text-nowrap mw-75 text_hide_width'>
                  {data?.asn_name || '_'}
                </strong>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between gap-5'>
                <h6 className=' text-gray-500'>{intl.formatMessage({id: 'ASN Domain'})}</h6>
                <strong className='text-nowrap mw-75 text_hide_width'>
                  {data?.asn_domain || '_'}
                </strong>
              </div>
            </div>
            {/* end::Header */}
          </div>
          {/* begin::Card */}
        </>
      </div>
      {/* end::Demos drawer */}
    </>
  )
}
