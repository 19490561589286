import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top/index'

import {Sidebar} from './components/sidebar/index'
import { DrawerMessenger} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import { FooterWrapper } from './components/footer/index'
import useFcmToken from '../../Utils/useFcmToken'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])
const FcmToken = useFcmToken()
  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid p-10 pb-0' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid mx-auto w-100' id='kt_app_main' style={{maxWidth:1320}}>
              <div className='d-flex flex-column flex-column-fluid'>
                <Outlet />
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <InviteUsers />
      <UpgradePlan /> */}
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
