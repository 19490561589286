import React, {useLayoutEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import axios from 'axios'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {Alert, Button} from 'react-bootstrap'
import {Modal} from 'bootstrap'
import {toast} from 'sonner'
const DomainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
const initialValues = {
  title: '',
  description: '',
  type: '',
}
interface Props {
  get: () => Promise<void>
  id: string | null
  targetPage: 1 | 2
  setTargetPage: React.Dispatch<React.SetStateAction<1 | 2>>
  setId: React.Dispatch<React.SetStateAction<string | null>>
  typeOptions: any[]
  needVerify: boolean
}
export default function CreateTargetModal ({
  get,
  id,
  setId,
  targetPage,
  setTargetPage,
  typeOptions = [],
  needVerify,
}: Props) {
  const intl = useIntl()
  const errorHandling = useErrorHandling()
  const [loading, setLoading] = useState(false)
  const [innerId, setInnerId] = useState('')
  const [target, setTarget] = useState('')
  const [description, setDescription] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [type, setType] = useState('')
  const [isAction, setIsAction] = useState(false)
  const [errShow, setErrShow] = useState('')
  function findType (type: string) {
    return typeOptions?.find((item) => item.id === type)
  }
  const verifyTXT = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id || innerId}/dns-verify/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        toast.success('Verification completed successfully.')
        setTimeout(() => {
          closeModal()
          setInnerId('')
          setId(null)
          setTargetPage(1)
          get()
        }, 3000)
      } else {
        toast.error(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/dns-verify/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e: any) {
      toast.error(
        e?.response.data.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
      )
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  const verifyFile = async () => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id || innerId}/file-verify/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        toast.success('Verification completed successfully.')
        setTimeout(() => {
          closeModal()
          setInnerId('')
          setId(null)
          setTargetPage(1)
          get()
        }, 3000)
      } else {
        toast.error(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/file-verify/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e: any) {
      toast.error(
        e?.response.data.detail || intl.formatMessage({id: 'Sorry, an error has occurred'})
      )

      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  const createTarget = async () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/targets/`, {
        title: target,
        description: description.trim() ? description : null,
        type: type,
      })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          toast.success('Create completed successfully.')
          setType('')
          formik.resetForm()
          if (needVerify) {
            setIsAction(true)
            setTargetPage(2)
            setInnerId(res.data.id)
            setVerifyCode(res.data.verification_code)
          } else {
            closeModal()
            setTarget('')
            setDescription('')
            setId(null)
            setTargetPage(1)
            setInnerId('')
            get()
          }
          setLoading(false)
        } else {
          toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data?.error)
        toast.error(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const fetchVerifyCode = async (id: string) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/verification-code/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        setVerifyCode(data.data.verification_code)
        setTargetPage(2)
      } else {
        setErrShow(data?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/targets/${id}/verification-code/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      setErrShow(intl.formatMessage({id: 'Sorry, an error has occurred'}))
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  const loginSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Target is required'}))
      .matches(
        findType(type)?.regex,
        intl.formatMessage({id: `${findType(type)?.name} is not valid`})
      ),
    type: Yup.string().required(intl.formatMessage({id: 'Type is required'})),
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      createTarget()
    },
  })
  const closeModal = () => {
    const modalElement = document.getElementById('kt_modal_create_target')
    if (modalElement) {
      const modalInstance = Modal.getOrCreateInstance(modalElement) // Initialize or get the modal instance
      modalInstance.hide() // Close the modal
    }
    setIsAction(false)
  }
  useLayoutEffect(() => {
    if (id && targetPage === 2) {
      fetchVerifyCode(id)
    }
  }, [id, targetPage])
  return (
    <div
      className={`modal fade pb-0`}
      id='kt_modal_create_target'
      style={{backdropFilter: 'blur(3px)'}}
      role='dialog'
      aria-bs-labelledby='staticBackdrop'
      data-bs-backdrop='static'
    >
      <div className={`modal-dialog ${loading && 'skeleton'}`} style={{minWidth: '50%'}}>
        <div className='modal-content'>
          <div className='modal-header'>
            <h3 className='modal-title'>
              {' '}
              {targetPage === 1
                ? intl.formatMessage({id: 'Create New Target'})
                : intl.formatMessage({id: 'Verify Target'})}
            </h3>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={() => {
                setTarget('')
                setDescription('')
                setId(null)
                setTargetPage(1)
                setInnerId('')
                setType('')
                formik.resetForm()
                if (isAction) {
                  setIsAction(false)
                  get()
                }
                closeModal()
              }}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 image_change_theme2'
              />
            </div>
          </div>
          <div className='modal-body'>
            {targetPage === 2 && (
              <div>
                <div className='card w-100 p-4 bg-gray-400  '>
                  <span style={{lineHeight: 1.6}}>
                    {intl.formatMessage({id: 'config verifyCode to DNS'})}{' '}
                    <mark
                      className=' text-nowrap'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        navigator.clipboard.writeText(verifyCode)
                      }}
                    >
                      {' '}
                      {verifyCode}{' '}
                    </mark>
                  </span>
                </div>
                <div className='d-flex gap-5 mt-5'>
                  <Button
                    onClick={() => {
                      verifyTXT()
                    }}
                  >
                    {intl.formatMessage({id: 'Verify with DNS'})}
                  </Button>
                  <Button
                    onClick={() => {
                      verifyFile()
                    }}
                  >
                    {intl.formatMessage({id: 'Verify with File'})}
                  </Button>
                </div>
              </div>
            )}

            {targetPage === 1 && (
              <form
                className='w-100 form'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className=''>
                  <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder'>
                      {intl.formatMessage({id: 'Type'})}
                    </label>
                    <select
                      className={clsx(
                        'form-select form-select-solid form-control form-control-lg form-control-solid ',
                        {
                          'is-invalid': formik.touched.type && formik.errors.type,
                        },
                        {
                          'is-valid': formik.touched.type && !formik.errors.type,
                        }
                      )}
                      data-kt-select2='true'
                      data-placeholder='Severity Filter'
                      data-allow-clear='true'
                      name='type'
                      onChange={(e) => {
                        if (!e.target.value) {
                          setTarget('')
                          formik.setFieldValue('title', '')
                        }
                        setType(e.target.value)
                        formik.setFieldValue('type', e.target.value)
                      }}
                      value={type}
                    >
                      <option></option>
                      {typeOptions?.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{intl.formatMessage({id: 'Type is required'})}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='form-label fs-6 fw-bolder  '>
                        {intl.formatMessage({id: 'Target'})}{' '}
                      </label>
                    </div>

                    <input
                      placeholder={findType(type)?.example || ''}
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.title && formik.errors.title,
                        },
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                      type='text'
                      name='title'
                      autoComplete='off'
                      value={target}
                      disabled={!type}
                      onChange={(e) => {
                        setTarget(e.target.value)
                        formik.setFieldValue('title', e.target.value)
                      }}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-10'>
                    <div className='d-flex align-items-center justify-content-between'>
                      <label className='form-label fs-6 fw-bolder  '>
                        {intl.formatMessage({id: 'Description'})}{' '}
                      </label>
                    </div>

                    <textarea
                      {...formik.getFieldProps('description')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.description && formik.errors.description,
                        },
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      name='description'
                      autoComplete='off'
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value)
                        formik.setFieldValue('description', e.target.value)
                      }}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container mt-2 text-danger'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-success'
                    // onClick={() => {
                    //   createTarget()
                    // }}

                    // disabled={!target.match(findType(type)?.regex)}
                  >
                    {intl.formatMessage({id: 'Create'})}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
