import React, {useState, useEffect, useLayoutEffect} from 'react'
import axios from 'axios'

import PageLoading from '../loading/PageLoading'
import Section from './components/Section'
import {KTSVG} from '../../../_metronic/helpers'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {SetDataDNS} from '../../modules/auth'
import {useIntl} from 'react-intl'
import EmptyPage from '../emptyPage/EmptyPage'
import {DNS_DATA} from '../../modules/auth/redux/DEMO_DATA'
import usePermission from '../../../Utils/usePermission'
export const DNSInspect = () => {
  const [loading, setLoading] = useState(false)
  const dnsData = useSelector((state: RootState) => state?.root?.data?.dataDns)
  const dispatch = useDispatch()
  const { isPermission, getPermission } = usePermission()
  const [initialIndex, setInitialIndex] = useState(0)
  const [isOpen, setIsOpen] = useState<null | number>(null)
  const errorHandling = useErrorHandling()
  const user = useSelector((state: RootState) => state?.root?.user)
  const intl = useIntl()
  const fetchDnsIdList = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dns/`)
      errorHandling(data?.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data?.status >= 200 && data?.status < 300) {
        dispatch(SetDataDNS(data?.data))
      } else {
        console.error(`error in get data from ${process.env.REACT_APP_API_ENDPOINT}/dns \n`, data)
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (dnsData?.expireTime && dnsData?.expireTime >= nowDate) {
    } else {
      fetchDnsIdList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className={`row d-flex flex-row mt-5  position-relative ${loading && 'skeleton'}`}>
      {isPermission()}
        <div className='w-100 h-100'>
          <div
            className={` h-100  `}
            style={!dnsData.data?.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
          >
            <div className='card card-body w-100 p-3 d-flex h-50px flex-row justify-content-between align-items-center  mb-5'>
              <button
                className={`col-auto border border-0 text-white bg-body px-4 m-0`}
                onClick={() => {
                  if (initialIndex > 0 && dnsData.data?.length) {
                    setInitialIndex(initialIndex - 1)
                  }
                }}
              >
                <KTSVG
                  svgClassName='image_change_theme2'
                  path='/media/icons/duotune/arrows/arr002.svg'
                  className='svg-icon svg-icon-2x ms-2 svg-icon-white '
                />
              </button>

              <h3 className='  text-center m-0'>
                {(!dnsData?.data?.length && !user?.is_user_scan ? DNS_DATA : dnsData?.data || [])[
                  initialIndex
                ]?.data?.host &&
                  `${intl.formatMessage({
                    id: 'DNS Inspect for',
                  })} ${
                    (!dnsData?.data?.length && !user?.is_user_scan
                      ? DNS_DATA
                      : dnsData?.data || [])[initialIndex]?.data?.host
                  }`}
              </h3>

              <button
                className={` col-auto border border-0 text-white bg-body px-4 m-0`}
                onClick={() => {
                  if (
                    dnsData.data?.length &&
                    initialIndex <
                      (!dnsData?.data?.length && !user?.is_user_scan
                        ? DNS_DATA
                        : dnsData?.data || []
                      )?.length -
                        1
                  ) {
                    setInitialIndex(initialIndex + 1)
                  }
                }}
              >
                <KTSVG
                  svgClassName='image_change_theme2'
                  path='/media/icons/duotune/arrows/arr001.svg'
                  className='svg-icon svg-icon-2x ms-2 svg-icon-white '
                />
              </button>
            </div>
            {/* <div className='d-flex wrap gap-5'></div> */}
            {Object.keys(
              (!dnsData?.data?.length && !user?.is_user_scan ? DNS_DATA : dnsData?.data || [])[
                initialIndex
              ]?.data || {}
            )
              ?.filter((item) => item !== 'host' && item !== 'score' && item !== 'timestamp')
              ?.map(
                (item) =>
                  (!dnsData?.data?.length && !user?.is_user_scan ? DNS_DATA : dnsData?.data || [])[
                    initialIndex
                  ]?.data[item]
              )
              ?.map((section, ind) => (
                <Section
                  key={section?.title+ind}
                  data={section}
                  ind={ind}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  )
}
