import React, {useState, useEffect, useLayoutEffect, lazy, Suspense} from 'react'
import axios from 'axios'

import Card from './components/Card'
import MainChart from './components/MainChart'
import RecentVulnerabilities from './components/RecentVulnerabilities'
import Assets from './components/Assets'
import WAFSecurity from './components/WAFSecurity'
import BarChartAsset from './components/BarChartAsset'
import FPlus from './components/FPlus'
import HalfCircleChart from './components/HalfCircleChart'
import IssueByStatus from './components/IssueByStatus'
// import Map from './components/Map'
import {StatisticsWidget3} from '../../../_metronic/partials/widgets'
import sample from '../sample'
import ServerIcon from './imgs/ServerIcon'
import BugIcon from './imgs/BugIcon'
import PadLock from './imgs/PadLock'
import {useDispatch, useSelector} from 'react-redux'
import useErrorHandling from '../../../Utils/useErrorHandling'
import AssetsRisk from './components/AssetsRisk'
import {RootState} from '../../../setup/redux/Store'
import {SetDataDashboard} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'
import {Tooltip} from 'react-tooltip'
import InfoIcon from '../images/InfoIcon'
import {useIntl} from 'react-intl'

export default function Dashboard1 () {
  const Map = lazy(() => import('./components/Map'))
  const intl = useIntl()
  const {heatMap} = sample
  const [loading, setLoading] = useState(false)
  const errorHandling = useErrorHandling()
  const dashboardData = useSelector((state: RootState) => state.root.data.dataDashboard)
  const dispatch = useDispatch()
  const user = useSelector((state: RootState) => state.root.user)
  const navigate = useNavigate()
  const fetchDashboardData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/`, {
        withCredentials: true,
      })
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataDashboard(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/dashboard/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    if (user?.is_user_new) {
      navigate('/services')
      // console.log('user', user?.is_user_new)
    } else {
      const nowDate = new Date().getTime()
      if (dashboardData?.expireTime && dashboardData.expireTime >= nowDate && dashboardData?.data) {
      } else {
        fetchDashboardData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {
    card1,
    card2,
    card3,
    card4,
    vulnerAbilities,
    zeroCard,
    earning,
    asset1,
    asset2,
    barChartAsset,
  } = sample
  return (
    <>
      <div className='mx-auto' style={{maxWidth: 1320}}>
        <div className='row d-flex flex-row g-8 mb-8'>
          <FPlus loading={loading}>
            <HalfCircleChart title={card1.total} percent={dashboardData?.data?.total_score} />
          </FPlus>

          <Card
            loading={loading}
            subColor=' '
            bgColor='bg-Body'
            subHeader={'Total Assets'}
            header={dashboardData.data?.total_assets?.assets}
            icon={<ServerIcon />}
            chartColors={card2.chartColors}
            data={dashboardData.data?.total_assets?.assets_over_time?.y}
            categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            tooltip='Total Assets tooltip'
          />

          <Card
            loading={loading}
            subColor=' '
            bgColor='bg-Body'
            subHeader={card3.subHeader}
            header={dashboardData?.data?.total_vulnerabilities?.vulnerabilities}
            icon={<BugIcon />}
            chartColors={card3.chartColors}
            data={dashboardData?.data?.total_vulnerabilities?.vulnerabilities_over_time?.y}
            categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            tooltip='Total Vulnerabilities tooltip'
          />

          <Card
            loading={loading}
            subColor=' '
            bgColor='bg-Body'
            subHeader={card4.subHeader}
            header={dashboardData?.data?.severe_vulnerabilities?.vulnerabilities}
            icon={<PadLock />}
            chartColors={card4.chartColors}
            data={dashboardData?.data?.severe_vulnerabilities?.vulnerabilities_over_time?.y}
            categories={['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul']}
            tooltip='Severe Vulnerabilities tooltip'
          />
        </div>
        <div className='row d-flex flex-row g-8 mb-8'>
          <MainChart
            loading={loading}
            title={vulnerAbilities?.title}
            data={dashboardData?.data?.vulnerabilities_based_severity}
          />
          <RecentVulnerabilities
            loading={loading}
            recentIssues={dashboardData?.data?.recent_vulnerabilities}
          />
        </div>
        <div className='row d-flex flex-row g-8 mb-8'>
          <AssetsRisk loading={loading} newTechnologies={dashboardData?.data?.assets_at_risk} />
          <div className='col-4 position-relative' style={{height: 270}}>
            <div style={{position: 'absolute', left: 70, top: -11, zIndex: 2}}>
              <BugIcon width={30} height={30} color='#e74c3c' />
            </div>
            <StatisticsWidget3
              loading={loading}
              className='card-xl-stretch mb-xl-8 card card-body h-100'
              color='danger'
              title={zeroCard?.title}
              description={zeroCard?.desc}
              change={zeroCard?.value}
              // data={zeroCard.data}
            />
          </div>
          <IssueByStatus loading={loading} data={dashboardData?.data?.assets_by_grade} />
        </div>
        <div className='row d-flex flex-row g-8 mb-8'>
          <div className='col-6 h-500px'>
            <div className='row d-flex flex-row h-100 gx-8'>
              <div className='col-6 mb-8'>
                {/* <Assets /> */}
                <WAFSecurity loading={loading} data={earning?.data} />
              </div>

              <div className='col-6 mb-8'>
                <Assets
                  loading={loading}
                  data={{...asset1, count: dashboardData?.data?.ssl_exp}}
                  tooltip='EXPIRING SSL CERTIFICATES tooltip'
                />
              </div>

              <div className='col-6'>
                <Assets
                  loading={loading}
                  data={{...asset2, count: dashboardData?.data?.dns_score}}
                  tooltip='EXPIRING SSL CERTIFICATES tooltip'
                />
              </div>

              <div className='col-6'>
                <BarChartAsset loading={loading} data={barChartAsset} />
              </div>
            </div>
          </div>
          <div className='col-6 h-500px'>
            <div className={`card card-body h-100 bg-body p-0 `}>
              <div
                className={`card card-body ${loading && 'skeleton'}`}
                style={{width: '100%', height: '100%'}}
              >
                <div className='d-flex flex-column me-2'>
                  <p className='d-flex   fw-bolder fs-3'>
                    {intl.formatMessage({id: heatMap.title})}
                    <span
                      data-tooltip-id='my-tooltip-map'
                      data-tooltip-content={intl.formatMessage({id: 'Hosts By Country tooltip'})}
                    >
                      <InfoIcon />
                    </span>
                    <Tooltip
                      id='my-tooltip-map'
                      style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
                    />
                  </p>
                </div>
                <Suspense fallback={<></>}>
                  <Map countries={dashboardData?.data?.host_by_country} />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
