import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {RootState} from '../../../../setup/redux/Store'
import PageLoading from '../../loading/PageLoading'
import ConvertDate from '../../../../Utils/ConvertDate'
import Sort from '../../images/Sort'

import {EXPLORE_EMAILS_DATA as EmailsData} from '../../../modules/auth/redux/DEMO_DATA'
import {SetDataEmails} from '../../../modules/auth'
import usePermission from '../../../../Utils/usePermission'

export default function App () {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isPermission, getPermission } = usePermission()
  const emailsData = useSelector((state: RootState) => state.root.data.dataEmails)
  const intl = useIntl()
  // const handlePageClick = (event: {selected: number}) => {
  //   fetchEmailData(`?page=${event.selected + 1}`)
  // }

  // const fetchEmailData = async (query = '?page=1') => {
  //   setLoading(true)
  //   try {
  //     const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/email/${query}`)
  //     errorHandling(data.status) //logout when invalid token && redirect 404
  //     if (data.status >= 200 && data.status < 300) {
  //       dispatch(SetDataEmails(data.data))
  //     } else {
  //       console.error(
  //         `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/email/${query} \n`,
  //         data
  //       )
  //     }
  //     setLoading(false)
  //   } catch (e) {
  //     console.log(e)
  //     setLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   const nowDate = new Date().getTime()
  //   if (emailsData?.expireTime && emailsData.expireTime >= nowDate) {
  //   } else {
  //     fetchEmailData()
  //   }
  // }, [])
  return loading ? (
    <div className='w-100 h-400px'>
      <PageLoading />
    </div>
  ) : (
    <>
      <div className='w-100  p-4 bg-body '>
      {isPermission()}
        <h1>{intl.formatMessage({id: 'Applications'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div className=' w-100 '>
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center' style={{filter: 'blur(3px)'}}>
                {/* start titles */}
                <div className='w-100 m-0  '>
                  <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-50 p-1 h-auto'>
                      <div className='w-100  h-auto d-flex justify-content-center align-items-center pointer'>
                        <span>{intl.formatMessage({id: 'Email'})}</span>
                      </div>
                    </div>
                    <div className='w-50 p-1 h-auto justify-content-center'>
                      <div className='w-100  h-auto d-flex justify-content-center align-items-center pointer'>
                        {' '}
                        <span>{intl.formatMessage({id: 'ID'})}</span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end titles */}
                {(emailsData?.data && emailsData?.data?.results?.length ? emailsData.data.results : EmailsData)?.map(
                  (item: any, index: number) => (
                    <div
                    style={!emailsData?.data?.results?.length ? {filter: 'blur(3px)'} : {}}
                      key={index}
                      className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-start align-items-center  mb-3`}
                    >
                      <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                        <div className='w-50 p-1 d-flex  h-auto justify-content-center'>
                          {' '}
                          {item.email}{' '}
                        </div>
                        <div className='w-50 p-1 h-auto d-flex justify-content-center'>
                          {item.id}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className='d-flex justify-content-end bg-body  pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  // onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={emailsData?.data?.total_pages || 1}
                  forcePage={emailsData?.data?.current_page - 1 || 0}
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
