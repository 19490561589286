import clsx from 'clsx'
import {FC, useContext, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage, I18nContext} from '../../../i18n/Metronici18n'
import {useIntl} from 'react-intl'
interface languagesType {
  lang: 'en' | 'fa'
  name: string
  flag: string
  dir: 'ltr' | 'rtl'
}
const languages: languagesType[] = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('media/flags/united-states.svg'),
    dir: 'ltr',
  },
  {
    lang: 'fa',
    name: 'فارسی',
    flag: toAbsoluteUrl('media/flags/iran.svg'),
    dir: 'rtl',
  },
]
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
  manual?: boolean
}
const Languages: FC<Props> = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'hover', lg: 'hover'}",
  manual = false,
}: Props) => {
  const lang = useLang()?.lang.selectedLang
  const intl = useIntl()
  const currentLanguage = languages.find((x) => lang && x.lang === lang)
  const setLang = useContext(I18nContext)?.setLang

  return (
    <>
      <a
        href='#'
        className={clsx('btn btn-icon ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <img className='w-25px h-25px rounded-1 image_change_theme' src={'/gt.png'} alt='metronic' />
      </a>

      <div
        className={`${
          manual && 'show'
        }  menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px`}
        data-kt-menu='true'
      >
        {languages?.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang, l.dir)
              if (setLang) setLang({selectedLang: l.lang, dir: l.dir})
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {
                active: l.lang === currentLanguage?.lang,
              })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </>
  )
}

export {Languages}
