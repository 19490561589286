import React, {useLayoutEffect, useState} from 'react'
import './registr.scss'
import Check from '../../../pages/images/Ckeck'
import Close from '../../../pages/images/Close'
import {Link, useNavigate, useParams} from 'react-router-dom'
import axios from 'axios'
import useGetUserData from '../../../../Utils/useGetUserData'
import {useIntl} from 'react-intl'
export default function RegistrationByGoogle () {
  const [loading, setLoading] = useState(false)
  const [res, setRes] = useState(false)
  const getUserData = useGetUserData()
  const navigate = useNavigate()
  const intl = useIntl()
  async function registrationGoogleLogin (params: string, bodyData: {state: string; code: string}) {
    setLoading(true)
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/o/google-oauth2/?${params}`,
        bodyData,
        {withCredentials: true}
      )
      if (data?.status >= 200 && data.status < 300) {
        setRes(true)
        getUserData()
        setTimeout(() => {
          navigate('/dashboard')
        }, 5000)
      } else {
        console.error(`error in login \n`, data.data.detail)
        setRes(false)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setRes(false)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    const url = new URL(window.location.href)
    // Get the query parameters
    const stateParam = url.searchParams.get('state')
    const codeParam = url.searchParams.get('code')
    if (stateParam && codeParam)
      registrationGoogleLogin(`state=${stateParam}&code=${codeParam}`, {
        state: stateParam,
        code: codeParam,
      })
  }, [window.location.href])
  return (
    <div className={`${loading && 'skeleton'}`}>
      {loading ? (
        <div className='text-center card bg-gray-400 py-10 h-200px'></div>
      ) : res ? (
        <div className='text-center card py-10'>
          <Check className='svg-icon-6x svg-icon-success' />
          <h1 className=' text-success'>{intl.formatMessage({id: 'Login Successful'})}</h1>
          <p>{intl.formatMessage({id: 'You have successfully signed into your account'})}</p>
        </div>
      ) : (
        <div className='text-center card py-10'>
          <Close className='svg-icon-6x svg-icon-danger' />
          <h1 className=' text-danger'>{intl.formatMessage({id: 'Login Failed'})}</h1>
          <p>{intl.formatMessage({id: 'You have Failed signed into your account'})}</p>
          <Link to={'/auth'}>{intl.formatMessage({id: 'Try again'})}</Link>
        </div>
      )}
    </div>
  )
}
