/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import axios from 'axios'
import {Dispatch} from 'react'
import useGetUserData from '../../../../Utils/useGetUserData'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
  password: '',
}
interface fetchLoginValueProps {
  email: string
  password: string
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login () {
  const [loading, setLoading] = useState(false)
  const [loadingGoogle, setLoadingGoogle] = useState(false)
  const [pass, setPass] = useState(false)
  const getUserData = useGetUserData()
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'Wrong email format'}))
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .max(50, intl.formatMessage({id: 'Maximum 50 symbols'}))
      .required(intl.formatMessage({id: 'Email is required'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'Minimum 3 symbols'}))
      .required(intl.formatMessage({id: 'Password is required'})),
  })
  const fetchLoginToken = async (
    values: fetchLoginValueProps,
    setStatus: Dispatch<any>,
    setSubmitting: Dispatch<any>
  ) => {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      try {
        const data = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/auth/jwt/create/`,
          {
            email: values.email,
            password: values.password,
          },
          {withCredentials: true}
        )
        if (data?.status >= 200 && data.status < 300) {
          getUserData()
        } else {
          console.error(`error in login \n`, data.data.detail)
          setStatus(data.data.detail || 'The login detail is incorrect')
        }
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
        setSubmitting(false)
        setStatus('The login detail is incorrect')
      } finally {
        setLoading(false)
      }
    } else {
      console.log(values)
      if (values.email === ' demo@netbaan.net' && values.password === 'password') {
        getUserData()
      } else {
        setLoading(false)
        setSubmitting(false)
        setStatus('The login detail is incorrect')
      }
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (
      values: fetchLoginValueProps,
      {setStatus, setSubmitting}: {setStatus: Dispatch<any>; setSubmitting: Dispatch<any>}
    ) => {
      setLoading(true)
      fetchLoginToken(values, setStatus, setSubmitting)
    },
  })
  async function SignInByGoogle () {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      setLoadingGoogle(true)
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_SIGNIN_GOOGLE}/auth/sign-in-with-google`,
          {withCredentials: true}
        )
        if (data?.status >= 200 && data.status < 300) {
          window.location.href = data.data.authorization_url
        } else {
          console.error(`error in login \n`, data.data.detail)
        }
        setLoadingGoogle(false)
      } catch (e) {
        console.log(e)
        setLoadingGoogle(false)
      } finally {
        setLoadingGoogle(false)
      }
    }
  }

  return (
    <div className='card'>
      <div className='card-body py-5 px-md-5'>
        <div className='d-flex justify-content-center flex-column align-items-center mb-10 p-8 rounded'>
          <h1>{intl.formatMessage({id: 'Sign In To Panel'})} </h1>
          {formik.status ? (
            <div className='font-weight-bold alert-text text-danger'>
              {intl.formatMessage({id: formik.status || ''})}
              {}
            </div>
          ) : (
            <span className='opacity-75'>
              {intl.formatMessage({id: 'Enter your details to login to your account'})}
            </span>
          )}
        </div>
        <form onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
          <div data-mdb-input-init className='form-outline mb-4'>
            <input
              dir='ltr'
              type='email'
              id='form3Example3'
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              {...formik.getFieldProps('email')}
            />

            <div className='d-flex align-items-center justify-content-between py-1'>
              <label className='form-label m-0' htmlFor='form3Example3'>
                {intl.formatMessage({id: 'Email address'})}
              </label>

              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container text-center text-danger'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
          </div>

          <div data-mdb-input-init className='form-outline mb-4 position-relative'>
            <input
              dir='ltr'
              type={pass ? 'text' : 'password'}
              id='form3Example4'
              className={clsx(
                'form-control',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              placeholder=' '
              {...formik.getFieldProps('password')}
            />
            <span
              className='btn btn-sm btn-icon position-absolute translate-middle   me-n2'
              style={{top: 22, right: 13}}
              data-kt-password-meter-control='visibility'
              onClick={() => {
                setPass((perv) => !perv)
              }}
            >
              {!pass ? (
                <KTIcon
                  iconName={'eye-slash'}
                  className='fs-1  text-white image_change_theme'
                  style={{cursor: 'pointer'}}
                />
              ) : (
                <KTIcon
                  iconName={'eye'}
                  className='fs-1 text-white image_change_theme'
                  style={{cursor: 'pointer'}}
                />
              )}
            </span>
            <div className='d-flex align-items-center justify-content-between py-1'>
              <label className='form-label m-0' htmlFor='form3Example4'>
                {intl.formatMessage({id: 'Password'})}
              </label>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container text-center text-danger'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              )}
            </div>
          </div>

          <div className='form-check d-flex justify-content-center mb-4'>
            <input
              className='form-check-input me-2'
              type='checkbox'
              value=''
              id='form2Example33'
              // checked
            />
            <label className='form-check-label' htmlFor='form2Example33'>
              {intl.formatMessage({id: 'Remember me'})}
            </label>
          </div>

          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-block btn-primary w-100'
            disabled={loading || loadingGoogle || !formik.isValid}
            style={{opacity: 0.9}}
          >
            {!loading && (
              <span className='indicator-label'>{intl.formatMessage({id: 'Sign in'})}</span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Please wait'}) + '...'}

                <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
              </span>
            )}
          </button>

          <div className='text-center'>
            <div className='divider d-flex align-items-center my-4 w-100'>
              <hr className='w-50' />
              <p className='text-center fw-bold mx-3 mb-0 text-muted'>
                {intl.formatMessage({id: 'OR'})}
              </p>
              <hr className='w-50' />
            </div>

            <button
              data-mdb-button-init
              data-mdb-ripple-init
              className='btn btn-lg btn-block btn-primary w-100 d-flex gap-1 align-items-center justify-content-center'
              style={{backgroundColor: '#dd4b39'}}
              onClick={() => {
                SignInByGoogle()
              }}
              disabled={loading || loadingGoogle}
            >
              <i className='fab fa-google me-2'></i>
              {!loadingGoogle && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'Sign in with google'})}
                </span>
              )}
              {loadingGoogle && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Please wait'}) + '...'}
                  <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
        <hr className='w-100' />
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center px-8 mt-5'>
          <Link to='/auth/forgot-password' id='kt_login_forgot' className=' font-weight-bold'>
            {intl.formatMessage({id: 'Forget Password ?'})}
          </Link>
          <Link to='/auth/sign-up' id='kt_login_forgot' className=' font-weight-bold'>
            {intl.formatMessage({id: 'Sign up'})}
          </Link>
        </div>
      </div>
    </div>
  )
}
