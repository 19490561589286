// import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {useNavigate} from 'react-router-dom'
import Arr001 from '../../components/Arr001'
import {KTSVG} from '../../../../_metronic/helpers'
import ConvertDate from '../../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
import {useState} from 'react'
import {Button} from 'react-bootstrap'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import CreateModal from './CreateModal'
import Warning from '../../images/Warning'
import CreateTargetModal from './CreateTargetModal'
const colorConvertor = (grade: string) => {
  let color = ''
  color = grade === 'A' ? '#02694a' : color
  color = grade === 'B' ? '#255a8f' : color
  color = grade === 'C' ? '#9c8705' : color
  color = grade === 'D' ? '#75041e' : color
  color = grade === 'E' ? '#421919' : color
  color = grade === 'F' ? '#565672' : color

  return color
}
const TargetTable = ({get}: {get: (page?: number) => Promise<void>}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const targetData = useSelector((state: RootState) => state?.root?.data?.dataScope)
  const [id, setId] = useState<null | string>(null)
  const [targetPage, setTargetPage] = useState<1 | 2>(1)
  return (
    <div className='w-100 h-100'>
      <div className={`card card-body  bg-body rounded-bottom-0`}>
        {/* start title */}
        <div className='w-100 pe-3 d-flex h-100px flex-row justify-content-between align-items-start pt-2'>
          <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
            <h3 className=' '>{intl.formatMessage({id: 'Targets'})}</h3>
            <Button
              type='button'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_target'
              // disabled={loading}
            >
              {intl.formatMessage({id: 'Create New Target'})}
            </Button>
          </div>
        </div>
        {/* end title */}
        <div className='w-100 m-0 pe-3 '>
          <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
            <div className='w-200px p-1 h-auto text-center'>
              {' '}
              {intl.formatMessage({id: 'Grade'})}{' '}
            </div>
            <div className='w-75 p-1 h-auto'> {intl.formatMessage({id: 'Name'})} </div>
            <div className='w-25 p-1 h-auto d-flex justify-content-center'>
              {intl.formatMessage({id: 'Assets'})}
            </div>
            <div className='w-100 d-flex justify-content-center p-1 ms-2 h-auto'>
              {intl.formatMessage({id: 'Vulnerabilities'})}
            </div>
            <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center'>
              {' '}
              {intl.formatMessage({id: 'Last Seen'})}{' '}
            </div>
            <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-center pe-3'>
              {' '}
              {intl.formatMessage({id: 'Action'})}{' '}
            </div>
          </div>
        </div>
        <div className={`w-100  overflow-visible  m-0 pb-8`}>
          <div className={`w-100 h-100 overflow-visible  pe-3 m-0 pb-8`}>
            {targetData?.data?.results?.map((item: any, index: number) => {
              return (
                <div
                  key={item.id}
                  className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex justify-content-center align-items-center ${
                    index === targetData?.data?.results.length - 1 ? null : 'mb-3'
                  }`}
                >
                  <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-200px d-flex justify-content-center align-items-center p-1 h-auto'>
                      <div
                        className={`d-flex justify-content-center align-items-center position-relative`}
                      >
                        <KTSVG
                          path='/media/hexagonal.svg'
                          className='svg-icon svg-icon-3x ms-2 svg-icon-body'
                          fill={colorConvertor(item.score)}
                        />
                        <span className='position-absolute ms-2 text-black fw-bolder'>
                          {item.score}
                        </span>
                      </div>
                    </div>
                    <div className='w-75 p-1  h-auto'>{item.target}</div>
                    <div className='w-25 p-1 h-auto d-flex justify-content-center'>
                      {item.assets}
                    </div>
                    <div className='w-100 d-flex justify-content-center align-items-center p-1 h-auto'>
                      {/* critical */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center '>
                        <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2 tooltip_tt'>
                          <span className='tooltiptext_tt'>
                            {intl.formatMessage({id: 'critical'})}
                          </span>
                        </div>
                        {item.vulnerabilities.critical}
                      </div>
                      {/* high */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                        <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2 tooltip_tt'>
                          <span className='tooltiptext_tt'>{intl.formatMessage({id: 'high'})}</span>
                        </div>
                        {item.vulnerabilities.high}
                      </div>
                      {/* medium */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                        <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2 tooltip_tt'>
                          <span className='tooltiptext_tt'>
                            {intl.formatMessage({id: 'medium'})}
                          </span>
                        </div>
                        {item.vulnerabilities.medium}
                      </div>
                      {/* low */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                        <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2 tooltip_tt'>
                          <span className='tooltiptext_tt'>{intl.formatMessage({id: 'low'})}</span>
                        </div>
                        {item.vulnerabilities.low}
                      </div>
                      {/* info */}
                      <div className='w-100 h-auto d-flex justify-content-center align-items-center ms-2 '>
                        <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2  tooltip_tt'>
                          <span className='tooltiptext_tt'>{intl.formatMessage({id: 'info'})}</span>
                        </div>
                        {item.vulnerabilities.info}
                      </div>
                    </div>
                    <div className='w-50 p-1 ms-2 h-auto d-flex justify-content-center'>
                      <ConvertDate date={item.timestamp} jalali={item.timestamp_jalali} />
                    </div>
                    <div className='w-25 p-1 ms-2 h-auto d-flex justify-content-center pe-3 gap-2'>
                      <>
                        <span
                          style={{
                            cursor: 'pointer',
                            visibility: item.is_verified ? 'hidden' : 'visible',
                          }}
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_create_target'
                          data-tooltip-id={'my-tooltip' + item.id}
                          data-tooltip-content={intl.formatMessage({
                            id: 'This target is not verified. Click to verify the target.',
                          })}
                          onClick={() => {
                            setTargetPage(2)
                            setId(item.id)
                          }}
                        >
                          <Warning className='svg-icon-warning svg-icon-2x image_change_theme' />
                        </span>
                        <ReactTooltip
                          id={'my-tooltip' + item.id}
                          style={{
                            maxWidth: 250,
                            fontSize: 12,
                            textAlign: 'center',
                            zIndex: 100,
                          }}
                        />
                      </>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={`row d-flex flex-row position-absolute bottom-0 my-2`}>
          <div
            className={`w-auto h-auto  ms-2 me-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2' />
            {intl.formatMessage({id: 'info'})}
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2' />
            {intl.formatMessage({id: 'low'})}
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2' />
            {intl.formatMessage({id: 'medium'})}
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2' />
            {intl.formatMessage({id: 'high'})}
          </div>
          <div
            className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
          >
            <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2' />
            {intl.formatMessage({id: 'critical'})}
          </div>
        </div>
      </div>
      <CreateTargetModal
        get={get}
        id={id}
        setId={setId}
        setTargetPage={setTargetPage}
        targetPage={targetPage}
      />
    </div>
  )
}

export default TargetTable
