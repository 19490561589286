/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {SignUp} from './components/SignUp'
import {SetNewPassword} from './components/SetNewPassword'
import RegistrationByGoogle from './components/RegistrationByGoogle'
import {Languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import clsx from 'clsx'
import { useState } from 'react'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { useIntl } from 'react-intl'
const itemClass = 'ms-1 ms-md-4'

const AuthLayout = () => {
  const [show, setShow] = useState(false)
  const intl = useIntl()
  const dir = useLang()?.lang.dir
  return (
    <section className='h-100 bg-red'>
      <div
        className='app-navbar position-relative px-4 py-5 px-md-5 text-center text-lg-start h-100 d-flex align-items-center justify-content-center gradient'
        
      >
        <div className={clsx(`app-navbar-item gap-5 position-absolute d-flex flex-column ${dir === 'rtl'?'align-items-start':'align-items-end'} `, itemClass)}
        style={{top:10,right:20}}
        onMouseEnter={() => {
          setShow(true)
        }}
        onMouseLeave={() => {
           setShow(false)
        }}
        >
          <Languages manual={show} toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
        </div>
        <div className='container'>
          <div className='row gx-lg-5 align-items-center justify-content-between'>
            <div className='col-lg-6 mb-5 mb-lg-0'>
              <h1 className='my-5 display-3 fw-bold ls-tight text-white ' style={{textAlign:'justify'}} >
              {intl.formatMessage({id: 'Netbaan'})}
               <br />
                <span className='text-primary'>
                {intl.formatMessage({id: 'Cloud Platform'})}
                  </span>
              </h1>
              <p style={{color: 'hsl(217, 10%, 50.8%)' , textAlign:'justify'}}>
              {intl.formatMessage({id: 'Secure your infrastructure with our cutting-edge technologies'})}
              
              </p>
            </div>

            <div className='col-lg-6 py-10 mb-lg-0 ' style={{maxWidth: 500}}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>


    </section>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='sign-in-with-google/*' element={<RegistrationByGoogle />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='new-password/:uid/:token' element={<SetNewPassword />} />
      <Route path='sign-up' element={<SignUp />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
