import React, {useEffect, useLayoutEffect, useState} from 'react'
import prot from '../Card/assets/Conflict.svg'
import bug from '../Card/assets/Bug.svg'
import time from '../Card/assets/time.png'
import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {RootState} from '../../../../setup/redux/Store'
import useGetFilterOptionsDomains from '../../../../Utils/useGetFilterOptionsDomains'
import {FilterSelectedAssets, SearchAsset, SetDataAsset, SortAsset} from '../../../modules/auth'
import PageLoading from '../../loading/PageLoading'
import ConvertDate from '../../../../Utils/ConvertDate'
import {ASSET_DATA} from '../../../modules/auth/redux/DEMO_DATA'
import SearchAndSort from '../../components/SearchAndSort'
import usePermission from '../../../../Utils/usePermission'
const SortOptions = [
  {value: 'domain', name: 'Domain'},
  {value: 'score', name: 'score'},
  {value: 'timestamp', name: 'date'},
  {value: 'total_vulnerabilities', name: 'vulnerabilities'},
]
function findColor (score: string) {
  switch (score) {
    case 'A':
      return 'bg-iinfo'
    case 'B':
      return 'bg-ilow'
    case 'C':
      return 'bg-imedium'
    case 'D':
      return 'bg-ihigh'
    case 'F':
      return 'bg-icritical'
    default:
      return 'bg-imedium'
  }
}
export default function Domains () {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const {isPermission, getPermission} = usePermission()
  const user = useSelector((state: RootState) => state?.root?.user)
  const assetData = useSelector((state: RootState) => state?.root?.data?.dataAsset)
  const sort = useSelector((state: RootState) => state.root.filterOption.assets)
  const search = useSelector((state: RootState) => state.root.filterOption.assets.search)
  function createPropsSort (sortProps: string) {
    if (sortProps) {
      return sortProps
    }
    if (sort.sortAsc) {
      return `&ordering=${sort.sortAsc}`
    } else if (sort.sortDes) {
      return `&ordering=${sort.sortDes}`
    }
    return ''
  }
  function createPropsSearch () {
    return search.map((item) => `&${item.name}=${item.value}`).join('')
  }
  const filterOptionDomains = useSelector(
    (state: RootState) => state?.root?.filterOption?.assets.options
  )
  const selected = useSelector((state: RootState) => state?.root?.filterOption?.assets.selected)
  const getFilterOptionsDomains = useGetFilterOptionsDomains()
  const handlePageClick = (event: {selected: number}) => {
    fetchDomainsData({query: `?page=${event.selected + 1}`})
  }
  function selectedHandel (items: string, item: string) {
    const copy = structuredClone(selected)
    if (selected[items] && selected[items].length && selected[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy[items]?.length) {
        copy[items].push(item)
      } else {
        copy[items] = [item]
      }
    }
    dispatch(FilterSelectedAssets(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
      fetchDomainsData({query: '?page=1', filterProps: copy})
    }
  }
  function createPropsFilter (props?: any) {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    } else {
      const keys = Object.keys(selected)
      const text = keys.map((key) => {
        if (selected[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    }
  }

  const fetchDomainsData = async ({query = '?page=1', filterProps = '', sort = ''}) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/assets/${query}${createPropsFilter(
          filterProps
        )}${createPropsSort(sort)}${createPropsSearch()}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataAsset(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/assets/${query}${filterProps} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (assetData?.expireTime && assetData.expireTime >= nowDate) {
    } else {
      fetchDomainsData({})
    }
    if (!filterOptionDomains && process.env.REACT_APP_MODE !== 'demo') {
      getFilterOptionsDomains()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className={`w-100  p-4 bg-body position-relative overflow-hidden ${loading && 'skeleton'}`}
        style={{maxWidth: 'calc(100% - 100px)'}}
      >
        {isPermission()}
        <h1>{intl.formatMessage({id: 'Domains'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div
            className=' w-25   border-end border-start border-gray pe-4 d-flex flex-column gap-4 '
            style={!filterOptionDomains && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
          >
            {filterOptionDomains ? (
              Object.keys(filterOptionDomains)?.map((items: string, index: number) => {
                return (
                  <div key={index} className='d-flex flex-column'>
                    <h5 className='m-0 ' style={{textTransform: 'capitalize'}}>
                      {intl.formatMessage({id: items})}
                    </h5>
                    {filterOptionDomains[items]?.length ? (
                      filterOptionDomains[items]
                        .slice(
                          0,
                          filterOptionDomains[items]?.length > 10
                            ? open === index
                              ? filterOptionDomains[items]?.length - 1
                              : 10
                            : filterOptionDomains[items]?.length
                        )
                        .map((item: any, ind: number) => (
                          <div key={ind}>
                            <hr className='my-2 mx-0' />
                            <div
                              style={{cursor: 'pointer'}}
                              onClick={(e) => {
                                if (items === 'scores') {
                                  selectedHandel(items, item)
                                } else {
                                  selectedHandel(items, item.name)
                                }
                              }}
                              className='form-check form-check-custom form-check-solid form-check-sm  p-1 rounded item_hover '
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                readOnly
                                checked={
                                  items === 'scores'
                                    ? selected[items]?.includes(item)
                                    : selected[items]?.includes(item.name)
                                }
                              />
                              <div className='form-check-label w-100 d-flex justify-content-between mx-2'>
                                <span>{items === 'scores' ? item : item.name}</span>
                                <span>{items === 'scores' ? '' : item.count}</span>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <span>N/A</span>
                    )}
                    {filterOptionDomains[items]?.length - 1 > 10 &&
                      (index !== open ? (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(index)
                          }}
                        >
                          {intl.formatMessage({id: 'show more'})}
                        </span>
                      ) : (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(null)
                          }}
                        >
                          {intl.formatMessage({id: 'show less'})}
                        </span>
                      ))}
                  </div>
                )
              })
            ) : (
              <PageLoading />
            )}
          </div>
          <div className=' w-75 ps-3'>
            <SearchAndSort
              className={'mb-5'}
              search={search}
              setSearch={SearchAsset}
              sort={sort}
              setSort={SortAsset}
              getData={fetchDomainsData}
              sortOption={SortOptions}
              searchOption={Object.keys(filterOptionDomains || {})}
            />
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center gap-3'>
                {(!assetData.data?.results?.length && !user?.is_user_scan
                  ? ASSET_DATA.results
                  : assetData?.data?.results
                )?.map((item: any, index: number) => (
                  <div
                    style={
                      !assetData.data?.results?.length
                        ? {filter: 'blur(3px)', padding: 1}
                        : {padding: 1}
                    }
                    onClick={() => {
                      if (assetData.data?.results?.length)
                        navigate({
                          pathname: '/asset-details',
                          search: `?id=${item.id}`,
                        })
                    }}
                    key={item.id}
                    className={`  w-100 m-0 bg-gray-200 h-auto  rounded position-relative overflow-hidden `}
                  >
                    <div
                      className={`position-absolute bg-danger w-100px h-75px d-flex align-items-end scoreLabel ${findColor(
                      item.score
                    )}`}
               
                    >
                      <span >{item.score}</span>
                    </div>
                    <div className={`mw-100 bg-gray-200 rounded p-1 hover_Costume`}>
                      <h2 className=' w-100 px-4'>{item.host}</h2>
                      <div className='w-100 h-110px d-flex  p-2 pt-1 pb-1 justify-content-between align-items-start'>
                        <div className='d-flex gap-5 w-330px'>
                          <div className='w-200px h-100px d-flex justify-content-start align-items-center  h-auto rounded-3 bg-secondary overflow-hidden'>
                            <div
                              className={`d-flex justify-content-center position-relative align-items-center w-100 h-100`}
                              style={{
                                backgroundImage: `url(${item.thumbnail})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                              }}
                            ></div>
                          </div>

                          <div
                            className=' p-1 me-2 h-auto d-flex flex-column gap-1 justify-content-start '
                            style={{width: 100}}
                          >
                            <div className='d-flex gap-1 align-items-center'>
                              <img src={prot} alt='port' className='w-30px image_change_theme' />
                              <span>{intl.formatMessage({id: 'Ports'})}</span>
                            </div>
                            <div
                              className='d-flex gap-1 w-61px flex-wrap justify-content-center'
                              style={{minWidth: 61, maxWidth: 100}}
                            >
                              {item.ports?.length ? (
                                item.ports?.length > 4 ? (
                                  <>
                                    {item.ports.slice(0, 4).map((item: any, index: number) => (
                                      <div
                                        className='p-1 px-2 rounded-2 text-center bg-body'
                                        style={{width: 'fit-content'}}
                                        key={index}
                                      >
                                        <span>{item}</span>
                                      </div>
                                    ))}
                                    <span style={{fontSize: 10}}>
                                      {intl.formatMessage({id: 'and more'})}
                                    </span>
                                  </>
                                ) : (
                                  item.ports.map((item: any, index: number) => (
                                    <div
                                      className='p-1 px-2 rounded-2 text-center bg-body'
                                      style={{width: 'fit-content'}}
                                      key={index}
                                    >
                                      <span>{item}</span>
                                    </div>
                                  ))
                                )
                              ) : (
                                <div
                                  className='p-1 px-2 rounded-2 text-center bg-body'
                                  style={{width: 'fit-content'}}
                                  key={index}
                                >
                                  N/A
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='w-300px h-100px p-1 ps-4 d-flex flex-column gap-3 border-start border-end border-white justify-content-start  gap-5'>
                          <div className='d-flex gap-2 align-items-center h-23px '>
                            <img src={bug} alt='port' className='w-20px image_change_theme' />
                            <span>{intl.formatMessage({id: 'Vulnerabilities'})}</span>
                          </div>

                          <div className='w-100 d-flex justify-content-center align-items-center p-1 h-auto'>
                            {/* critical */}
                            <div className='w-100 h-auto d-flex  align-items-center '>
                              <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2 tooltip_tt'>
                                <span className='tooltiptext_tt'>
                                  {intl.formatMessage({id: 'critical'})}
                                </span>
                              </div>
                              {item.vulnerabilities.critical}
                            </div>
                            {/* high */}
                            <div className='w-100 h-auto d-flex  align-items-center ms-2 '>
                              <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2 tooltip_tt'>
                                <span className='tooltiptext_tt'>
                                  {intl.formatMessage({id: 'high'})}
                                </span>
                              </div>
                              {item.vulnerabilities.high}
                            </div>
                            {/* medium */}
                            <div className='w-100 h-auto d-flex  align-items-center ms-2 '>
                              <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2 tooltip_tt'>
                                <span className='tooltiptext_tt'>
                                  {intl.formatMessage({id: 'medium'})}
                                </span>
                              </div>
                              {item.vulnerabilities.medium}
                            </div>
                            {/* low */}
                            <div className='w-100 h-auto d-flex  align-items-center ms-2 '>
                              <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2 tooltip_tt'>
                                <span className='tooltiptext_tt'>
                                  {intl.formatMessage({id: 'low'})}
                                </span>
                              </div>
                              {item.vulnerabilities.low}
                            </div>
                            {/* info */}
                            <div className='w-100 h-auto d-flex  align-items-center ms-2 '>
                              <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2 tooltip_tt'>
                                <span className='tooltiptext_tt'>
                                  {intl.formatMessage({id: 'info'})}
                                </span>
                              </div>
                              {item.vulnerabilities.info}
                            </div>
                          </div>
                        </div>
                        <div className='w-200px h-100px p-1 ps-4 d-flex flex-column gap-3  justify-content-start gap-5'>
                          <div className='d-flex gap-2 align-items-center h-23px '>
                            <img src={time} alt='port' className='w-20px image_change_theme' />
                            <span>{intl.formatMessage({id: 'Last update'})}</span>
                          </div>
                          <div className='w-190 p-1  h-auto d-flex '>
                            <ConvertDate date={item.timestamp} jalali={item.timestamp_jalali} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className={`row d-flex flex-row  my-5 `}>
                  <div
                    className={`w-auto h-auto  ms-2 me-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-iinfo border border-2' />
                    {intl.formatMessage({id: 'info'})}
                  </div>
                  <div
                    className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-ilow border border-2' />
                    {intl.formatMessage({id: 'low'})}
                  </div>
                  <div
                    className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-imedium border border-2' />
                    {intl.formatMessage({id: 'medium'})}
                  </div>
                  <div
                    className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-ihigh border border-2' />
                    {intl.formatMessage({id: 'high'})}
                  </div>
                  <div
                    className={`w-auto h-auto  ms-2 px-3 d-flex align-items-center text-uppercase rounded-pill bg-body border border-1 border-gray-800`}
                  >
                    <div className='w-15px h-15px me-2 rounded-3 border-icritical border border-2' />
                    {intl.formatMessage({id: 'critical'})}
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-end bg-body  pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={assetData?.data?.total_pages || 1}
                  forcePage={assetData?.data?.current_page - 1 || 0}
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
