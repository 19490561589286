import React, {memo} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'
import Warning2 from '../images/Warning2'
import { useLang } from '../../../_metronic/i18n/Metronici18n'

interface Value {
  icon: JSX.Element
  title: string
  description: string
  status: boolean
}

interface Props {
  props: any | null
  setProps: React.Dispatch<React.SetStateAction<Value | null>>
}
const EditModal = memo(({setProps, props}: Props) => {
  const intl = useIntl()
  const lang = useLang()?.lang.selectedLang

  function clearForm () {
    setProps(null)
  }

  return (
    <>
      <div
        className='modal fade '
        id='kt_modal_service_details'
        style={{backdropFilter: 'blur(3px)'}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          clearForm()
        }}
      >
        <div
          className='modal-dialog'
          style={{minWidth: '40%'}}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className='modal-content'>
            <div className='modal-header h-10px'>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={clearForm}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className='modal-body'>
              {props && (
                <div className=' bg-body w-100 '>
                  <div className='d-flex gap-3 align-items-center'>
                    <div className='d-flex align-items-center justify-content-center p-2 bg-primary rounded-3'>
                    <KTIcon
                    iconName={props.icon_name}
                    className='text-white'
                    style={{fontSize: '35px'}}
                  />
                    </div>
                    <h3 className='m-0' style={{width: 'fit-content'}}>
                      <strong>{lang === 'fa' ? props.name_fa : props.name}</strong>
                    </h3>
                  </div>
                  <div className='card border-2 border-gray-500 p-3 d-flex flex-row gap-2 align-items-stretch my-4'>
                    <Warning2 className='svg-icon-2x image_change_theme2' />
                    <div>
                      <span>{lang === 'fa' ? props.description_fa : props.description}</span>
                    </div>
                  </div>
                  <div className='d-flex gap-5 justify-content-center'>
                    <button
                      className='btn btn-outline-secondary border border-gray-500 '
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      onClick={clearForm}
                    >
                      {intl.formatMessage({id: 'I Understand'})}
                    </button>

                    <button className='btn btn-primary '>
                      {intl.formatMessage({id: 'Learn More'})}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
export default EditModal
