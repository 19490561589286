import React, {useLayoutEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useIntl} from 'react-intl'
import {Alert, Button} from 'react-bootstrap'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'

export default function HistoryDetails ({id}: {id: string | number}) {
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>()
  const [err, setErr] = useState('')
  const [loadingPay, setLoadingPay] = useState(false)

  const fetchPaymentHistory = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/payments/${id}/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setData(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/payments/ \n`,
          data
        )
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  function PostPayId (id: string) {
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/subscription/pay/`, {
        payment: id,
      })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          const URL = res.data.payment_url
          if (URL) {
            window.location.href = URL
          }
        } else {
          setErr(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data)
        setErr(
          e?.response?.data?.payment[0]?.replace(/[\[\]"']/g, ' ') ||
            intl.formatMessage({id: 'Sorry, an error has occurred'})
        )
      })
      .finally(() => {
        setLoadingPay(false)
      })
  }
  useLayoutEffect(() => {
    if (id) fetchPaymentHistory()
  }, [id])
  return (
    <>
      <div
        className='modal fade '
        id='kt_modal_invoice'
        style={{backdropFilter: 'blur(3px)'}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <>
          <Alert
            show={!!err}
            variant='danger'
            className=' position-absolute '
            style={{width: 'fit-content', top: 20, left: '50%', transform: 'translateX(-50%)'}}
          >
            <Alert.Heading
              className='w-100  d-flex gap-10  justify-content-between '
              style={{width: 'fit-content'}}
            >
              {err}
              <div onClick={() => setErr('')}>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </Alert.Heading>
          </Alert>
        </>
        <div
          className='modal-dialog '
          style={{minWidth: '550px', top: 80}}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className='modal-content '>
            <div className='modal-header py-2'>
              {/* <h3 className='modal-title'> </h3> */}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className={`modal-body ${loading && 'skeleton'}`}>
              <div className='card'>
                <div className='card-body mx-4'>
                  <div className='container'>
                    <p className='my-5 mx-5' style={{fontSize: '30px'}}>
                      {intl.formatMessage({id: 'Thank for your purchase'})}
                    </p>
                    <div className='row justify-content-between'>
                      <ul className='list-unstyled'>
                        <li className=' mt-1 w-100 d-flex justify-content-between align-items-center'>
                          <div>
                            <span className=''>{intl.formatMessage({id: 'Plan name'})}</span>{' '}
                            <span className='text-muted'>#{data?.plan_name}</span>
                          </div>
                          <strong
                            className={`border ${
                              data?.payment_status === 'pending'
                                ? 'border-warning bg-warning'
                                : data?.payment_status === 'success'
                                ? 'border-success bg-success'
                                : 'border-danger bg-danger'
                            }  rounded bg-opacity-25 p-1 px-3`}
                          >
                            {intl.formatMessage({id: data?.payment_status || 'Failed'})}
                          </strong>
                        </li>
                      </ul>
                      <hr />
                      <div className='col-10'>
                        <p>{intl.formatMessage({id: 'Amount'})}</p>
                      </div>
                      <div className='col-2'>
                        <p className=' text-nowrap' >{FormatAndConvertAmount(data?.amount)}/{intl.formatMessage({id: 'IR-T'})}</p>
                      </div>
                      <hr />
                    </div>
                    <div className='row'>
                      <div className='col-10'>
                        <p>{intl.formatMessage({id: 'Discount amount'})}</p>
                      </div>
                      <div className='col-2'>
                        <p className='float-end text-nowrap'>{FormatAndConvertAmount(data?.discount_amount)}/{intl.formatMessage({id: 'IR-T'})}</p>
                      </div>
                      <hr />
                    </div>

                    <div className='row '>
                      <div className='col-12'>
                        <strong className='float-end fw-bold'>
                          {intl.formatMessage({id: 'Final amount'})}: {FormatAndConvertAmount(data?.final_amount)}/{intl.formatMessage({id: 'IR-T'})}
                        </strong>
                      </div>
                      <hr style={{border: '2px solid var(--bs-gray-500)', marginTop: 12}} />
                    </div>
                    <div className='text-center' style={{marginTop: '90px'}}>
                      {data?.payment_status === 'pending'?<Button
                        disabled={process.env.REACT_APP_MODE === 'demo' || loadingPay}
                        onClick={() => {
                          PostPayId(data.id)
                        }}
                      >
                        {loadingPay
                          ? intl.formatMessage({id: 'Please wait...'})
                          : intl.formatMessage({id: 'Payment'})}
                      </Button>:<span className='text-warning'>{intl.formatMessage({id: 'It is not possible to pay this invoice'})}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
