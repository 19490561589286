import React, {useEffect, useState} from 'react'

import Warning2 from '../images/Warning2'
import {SERVICES_DATA} from '../../modules/auth/redux/DEMO_DATA'
import {useIntl} from 'react-intl'
import EditModal from './Modal'
import {useNavigate} from 'react-router-dom'
import Joyride, {CallBackProps} from 'react-joyride'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch} from 'react-redux'
import axios from 'axios'
import {SetDataServices} from '../../modules/auth'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {KTIcon} from '../../../_metronic/helpers'

interface Value {
  icon: JSX.Element
  title: string
  description: string
  status: boolean
}
const steps = [
  {
    target: '.service-feature-one',
    content: 'این ویژگی 1 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-two',
    content: 'این ویژگی 2 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-three',
    content: 'این ویژگی 3 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-4',
    content: 'این ویژگی 4 در صفحه سرویس است.',
  },
]
export default function Services () {
  const isUserNew = useSelector((state: RootState) => state.root.user?.is_user_new)
  const servicesData = useSelector((state: RootState) => state.root.data.dataServices)
  const [open, setOpen] = useState<null | Value>(null)
  const [ind, setInd] = useState<undefined | number>()
  const [run, setRun] = useState(false)
  const intl = useIntl()
  const lang = useLang()?.lang.selectedLang
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const fetchServices = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/features/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataServices(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/features/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (servicesData?.expireTime && servicesData.expireTime >= nowDate) {
    } else {
      fetchServices()
    }
    if (isUserNew) {
      setRun(true)
      setInd(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data
    if (status === 'finished' || status === 'skipped') {
      setRun(false)
      navigate('/price-plan')
    }
  }

  return (
    <div>
      <div className='service-feature-one'>
        <div className='bg-transparent d-flex gap-5 flex-wrap justify-content-center'>
          {servicesData?.data?.map((item: any, ind: number) => (
            <div className='card bg-body w-350px p-4' key={item.id}>
              <div className='d-flex gap-3 align-items-center'>
                <div className='d-flex align-items-center justify-content-center p-2 bg-primary rounded-3'>
                  <KTIcon
                    iconName={item.icon_name}
                    className='text-white'
                    style={{fontSize: '35px'}}
                  />
                </div>

                <h3 className='m-0' style={{width: 'fit-content'}}>
                  <strong>{lang === 'fa' ? item.name_fa : item.name} </strong>
                </h3>
              </div>
              <div className='service-feature-two'>
                <div className='card border-2 border-gray-500 p-3 d-flex flex-row gap-2 align-items-stretch my-4'>
                  <Warning2 className='svg-icon-2x image_change_theme2' />
                  <div>
                    <span className={`text_hide_3line`}>
                      {lang === 'fa' ? item.description_fa : item.description}
                    </span>
                    <div className='service-feature-three'>
                      <button
                        className={`col-auto border border-0 text-white bg-transparent p-0 m-0 text-primary`}
                        style={{width: 'fit-content'}}
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_service_details'
                        onClick={() => {
                          setOpen(item)
                        }}
                      >
                        {intl.formatMessage({id: 'show more'})}{' '}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='service-feature-4 d-flex justify-content-center'>
                {!item.is_available ? (
                  <button className='btn btn-primary'>{intl.formatMessage({id: 'Request'})}</button>
                ) : (
                  <button className='btn btn-outline-primary ' disabled>
                    {intl.formatMessage({id: 'You have this'})}
                  </button>
                )}
              </div>
            </div>
          ))}
          <EditModal setProps={setOpen} props={open} />
        </div>
      </div>

      {/* Joyride Component */}
      <Joyride
        stepIndex={ind}
        steps={steps}
        run={run}
        disableOverlay={false}
        disableScrolling={true}
        showProgress={true}
        disableOverlayClose={true}
        continuous={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{next: 'بعدی', skip: 'رد کردن'}}
        spotlightPadding={5}
        styles={{
          options: {
            zIndex: 10000, // Ensure Joyride is above other elements
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay on the whole page
          },
        }}
      />
    </div>
  )
}
