import React, {useState, useEffect, useLayoutEffect} from 'react'
import axios from 'axios'
import PageLoading from '../../loading/PageLoading'
import ReactPaginate from 'react-paginate'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import {KTSVG} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import ConvertDate from '../../../../Utils/ConvertDate'
import {RootState} from '../../../../setup/redux/Store'
import {
  FilterSelectedAssets,
  FilterSelectedSSL,
  SearchSSL,
  SetDataSSL,
  SortSSL,
} from '../../../modules/auth'
import {useIntl} from 'react-intl'
import useGetFilterOptionsDomains from '../../../../Utils/useGetFilterOptionsDomains'
import {SSL_DATA} from '../../../modules/auth/redux/DEMO_DATA'
import SearchAndSort from '../../components/SearchAndSort'
import useGetFilterOptionsSSL from '../../../../Utils/useGetFilterOptionsSSL'
import usePermission from '../../../../Utils/usePermission'
const SortOptions = [
  {value: 'name', name: 'name'},
  {value: 'grade', name: 'grade'},
  {value: 'timestamp', name: 'date'},
  {value: 'certificate_status', name: 'certificate'},
  {value: 'total_vulnerabilities', name: 'vulnerabilities'},
]
const colorConvertor = (grade: string) => {
  let color = ''
  color = grade === 'A' ? '#02694a' : color
  color = grade === 'A+' ? '#02694a' : color
  color = grade === 'B' ? '#255a8f' : color
  color = grade === 'C' ? '#9c8705' : color
  color = grade === 'D' ? '#75041e' : color
  color = grade === 'F' ? 'rgb(140 29 29)' : color
  color = grade === 'T' ? 'rgb(92 33 33)' : color
  color = grade === 'M' ? 'rgb(102 21 21)' : color
  color = grade === 'N' ? '#565672' : color

  return color
}
export const SSLExposures = () => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState<number | null>(null)
  const sslData = useSelector((state: RootState) => state?.root?.data?.dataSSL)
  const user = useSelector((state: RootState) => state?.root?.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const getFilterOptionsSSL = useGetFilterOptionsSSL()
  const intl = useIntl()
  const { isPermission, getPermission } = usePermission()
  const filterOptionSSL = useSelector((state: RootState) => state?.root?.filterOption?.ssl.options)
  const selected = useSelector((state: RootState) => state?.root?.filterOption?.ssl.selected)
  const sort = useSelector((state: RootState) => state.root.filterOption.ssl)
  const search = useSelector((state: RootState) => state.root.filterOption.ssl.search)
  function createPropsSort (sortProps: string) {
    if (sortProps) {
      return sortProps
    }
    if (sort.sortAsc) {
      return `&ordering=${sort.sortAsc}`
    } else if (sort.sortDes) {
      return `&ordering=${sort.sortDes}`
    }
    return ''
  }
  function createPropsSearch () {
    return search.map((item) => `&${item.name}=${item.value}`).join('')
  }
  const handlePageClick = (event: {selected: number}) => {
    fetchSslData({query: `?page=${event.selected + 1}`})
  }
  function createPropsFilter (props?: any) {
    if (props) {
      const keys = Object.keys(props)
      const text = keys.map((key) => {
        if (props[key] && props[key].length) {
          return `&${key}=${props[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    } else {
      const keys = Object.keys(selected)
      const text = keys.map((key) => {
        if (selected[key] && selected[key].length) {
          return `&${key}=${selected[key].join(',')}`
        } else {
          return ''
        }
      })

      const prop = text.join('')

      return prop
    }
  }
  const fetchSslData = async ({query = '?page=1', filterProps = '', sort = ''}) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/ssl/${query}${createPropsFilter(
          filterProps
        )}${createPropsSort(sort)}${createPropsSearch()}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataSSL(data.data))
      } else {
        console.error(
          `error in get data from ${
            process.env.REACT_APP_API_ENDPOINT
          }/ssl/${query}${createPropsFilter(filterProps)}${createPropsSort(
            sort
          )}${createPropsSearch()} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  function selectedHandel (items: string, item: string) {
    const copy = structuredClone(selected)
    if (selected[items] && selected[items].length && selected[items].includes(item)) {
      copy[items] = copy[items].filter((it: string | number) => it !== item)
    } else {
      if (copy[items]?.length) {
        copy[items].push(item)
      } else {
        copy[items] = [item]
      }
    }
    dispatch(FilterSelectedSSL(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
      fetchSslData({query: '?page=1', filterProps: copy})
    }
  }
  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (sslData?.expireTime && sslData.expireTime >= nowDate) {
    } else {
      fetchSslData({})
    }
    if (!filterOptionSSL && process.env.REACT_APP_MODE !== 'demo') {
      getFilterOptionsSSL()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div
        className={`w-100  p-4 bg-body overflow-hidden position-relative ${loading && 'skeleton'}`}
        style={{maxWidth: 'calc(100% - 100px)'}}
      >
        {isPermission()}
        <h1>{intl.formatMessage({id: 'SSL Report'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div
            className=' w-25   border-end border-start border-gray pe-4 d-flex flex-column gap-4 '
            style={!filterOptionSSL && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
          >
            {filterOptionSSL ? (
              Object.keys(filterOptionSSL)?.map((items: string, index: number) => {
                return (
                  <div key={index} className='d-flex flex-column'>
                    <h5 className='m-0 ' style={{textTransform: 'capitalize'}}>
                      {intl.formatMessage({id: items})}
                    </h5>
                    {filterOptionSSL[items]?.length ? (
                      filterOptionSSL[items]
                        .slice(
                          0,
                          filterOptionSSL[items]?.length > 10
                            ? open === index
                              ? filterOptionSSL[items]?.length - 1
                              : 10
                            : filterOptionSSL[items]?.length
                        )
                        .map((item: any, ind: number) => (
                          <div key={ind}>
                            <hr className='my-2 mx-0' />
                            <div
                              style={{cursor: 'pointer'}}
                              onClick={(e) => {
                                if (items === 'scores') {
                                  selectedHandel(items, item)
                                } else {
                                  selectedHandel(items, item.name)
                                }
                              }}
                              className='form-check form-check-custom form-check-solid form-check-sm  py-1 rounded item_hover '
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                readOnly
                                checked={
                                  items === 'scores'
                                    ? selected[items]?.includes(item)
                                    : selected[items]?.includes(item.name)
                                }
                              />
                              <div className='form-check-label w-100 d-flex justify-content-between mx-2'>
                                <span>{items === 'scores' ? item : item.name}</span>
                                <span>{items === 'scores' ? '' : item.count}</span>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <span>N/A</span>
                    )}
                    {filterOptionSSL[items]?.length - 1 > 10 &&
                      (index !== open ? (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(index)
                          }}
                        >
                          {intl.formatMessage({id: 'show more'})}
                        </span>
                      ) : (
                        <span
                          style={{alignSelf: 'center', cursor: 'pointer'}}
                          onClick={() => {
                            setOpen(null)
                          }}
                        >
                          {intl.formatMessage({id: 'show less'})}
                        </span>
                      ))}
                  </div>
                )
              })
            ) : (
              <PageLoading />
            )}
          </div>

          <div className=' w-75 ps-3'>
            <div className='row d-flex flex-row  '>
              <SearchAndSort
                className={'mb-5'}
                search={search}
                setSearch={SearchSSL}
                sort={sort}
                setSort={SortSSL}
                getData={fetchSslData}
                sortOption={SortOptions}
                searchOption={Object.keys(filterOptionSSL || {})}
              />
              <div className='w-100 h-100'>
                <div className={` h-100 bg-body rounded-bottom-0`}>
                  <div className='w-100 m-0 pe-3 '>
                    <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 gap-5 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                      <div className=' w-65px py-1  h-auto'>
                        <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                          <span>{intl.formatMessage({id: 'Grade'})}</span>{' '}
                        </div>
                      </div>
                      <div className='w-100 py-1  h-auto'>
                        <div className='w-100  h-auto d-flex justify-content-start align-items-center pointer'>
                          <span>{intl.formatMessage({id: 'Name'})}</span>{' '}
                        </div>
                      </div>
                      <div className='w-75 py-1 h-auto d-flex '>
                        <div className='w-100  h-auto d-flex  align-items-center pointer'>
                          {' '}
                          <span>{intl.formatMessage({id: 'Vulnerabilities'})}</span>{' '}
                        </div>
                      </div>
                      <div className='w-100 d-flex  py-1  h-auto'>
                        <div className='w-100  h-auto d-flex  align-items-center pointer'>
                          {' '}
                          <span className=' text-nowrap'>
                            {intl.formatMessage({id: 'Certificate Status'})}
                          </span>{' '}
                        </div>
                      </div>
                      <div className='w-50 py-1  h-auto d-flex  text-nowrap'>
                        {' '}
                        {intl.formatMessage({id: 'Expiry Date'})}{' '}
                      </div>
                      <div className=' py-1  h-auto d-flex' style={{width: 130, minWidth: 130}}>
                        <div className='w-100 h-auto d-flex  align-items-center pointer'>
                          {' '}
                          <span>{intl.formatMessage({id: 'Last Seen'})}</span>{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end titles */}
                  <div className={`w-100 h-100 d-flex flex-column gap-3 overflow-auto pe-3 m-0`}>
                    {(!sslData?.data?.results?.length && !user?.is_user_scan
                      ? SSL_DATA.results
                      : sslData?.data?.results
                    )?.map((item: any, index: number) => {
                      const {
                        id,
                        grade,
                        host,
                        total_vulnerabilities,
                        timestamp,
                        timestamp_jalali,
                        certificate_status,
                        days_to_expiry,
                      } = item
                      return (
                        <div
                          style={!sslData?.data?.results?.length ? {filter: 'blur(3px)'} : {}}
                          onClick={() => {
                            if (sslData?.data?.results?.length)
                              navigate({
                                pathname: '/ssl-details',
                                search: `?id=${id}`,
                              })
                          }}
                          key={`ssl_list_${index}`}
                          className={`w-100 m-0  h-auto bg-gray-200 rounded-1 d-flex justify-content-center align-items-center pointer  hover_Costume overflow-hidden`}
                        >
                          <div className='w-100 d-flex gap-5 p-2 pt-1 pb-1 justify-content-between align-items-center overflow-hidden'>
                            <div className='w-65px min-w-65px d-flex  align-items-center py-1 h-auto text_hide_width text-nowrap position-relative overflow-visible '>
                              {/* <div
                                className={`d-flex justify-content-center align-items-center position-relative`}
                              >
                                <KTSVG
                                  path='/media/hexagonal.svg'
                                  className='svg-icon svg-icon-3x ms-2 svg-icon-body'
                                  fill={colorConvertor(grade)}
                                />
                                <span className='position-absolute ms-2 text-black fw-bolder'>
                                  {grade}
                                </span>
                              </div> */}
                              <div className=' position-absolute  '
                              style={{
                                backgroundColor:colorConvertor(grade),
                                transform: 'rotate(35deg)',
                                top: -28,
                                left: -8,
                                width: 40,
                                height: 52,
                              }}
                              >
                                <span className=' position-absolute text-white' 
                                style={{
                                  width:24,
                                  top: 17,
                                  right: 4,
                                  transform: 'rotate(-35deg)',
                                  fontWeight: 'bolder',
                                  fontSize: 'large',
                                }}
                                >{grade}</span>
                              </div>
                            </div>
                            <div className='w-100 py-1  h-auto text_hide_width text-nowrap'>
                              {host}
                            </div>
                            <div className='w-75 py-1 h-auto d-flex min-w-129px text_hide_width text-nowrap justify-content-center'>
                              {total_vulnerabilities}
                            </div>
                            <div className='w-100 py-1 h-auto d-flex  text_hide_width text-nowrap justify-content-center'>
                              {certificate_status ? 'Valid' : 'Invalid'}
                            </div>
                            <div className='w-50 py-1 h-auto d-flex  pe-3 text_hide_width text-nowrap justify-content-center'>
                              <div className={`col-auto border border-0  bg-gray-200 p-0 m-0`}>
                                {days_to_expiry
                                  ? days_to_expiry + ' ' + intl.formatMessage({id: 'day'})
                                  : 'N/A'}
                              </div>
                            </div>
                            <div
                              className='w-130px min-w-130px py-1  h-auto d-flex  text_hide_width text-nowrap'
                              style={{width: 130, minWidth: 130}}
                            >
                              <ConvertDate date={timestamp} jalali={timestamp_jalali} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {/* end body */}
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end bg-body pb-4 pe-4 rounded-bottom mt-5'>
              <ReactPaginate
                nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={sslData?.data?.total_pages || 1}
                forcePage={sslData?.data?.current_page - 1 || 0}
                previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
