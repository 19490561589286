import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import {KTSVG} from '../../../../_metronic/helpers'
import Lock from '../../images/Lock'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
import {useDispatch} from 'react-redux'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import {SetDataScan, SetDataScanDAG} from '../../../modules/auth'
import EmptyPage from '../../emptyPage/EmptyPage'
import Trash from '../../images/Trash'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import gregorian from 'react-date-object/calendars/gregorian'
import gregorian_en from 'react-date-object/locales/gregorian_en'
import CreateTargetModal from './CreateTargetModal'
import {Button} from 'react-bootstrap'
import {toast} from 'sonner'
import Warning from '../../images/Warning'
export default function CreateScan () {
  const navigate = useNavigate()
  const intl = useIntl()
  const dispatch = useDispatch()
  const errorHandling = useErrorHandling()
  const dags = useSelector((state: RootState) => state?.root?.data?.dataScanDAG)
  const lang = useLang()?.lang.selectedLang
  const [loading, setLoading] = useState(false)
  const [loadingTarget, setLoadingTarget] = useState(false)
  const [loadingScanProfile, setLoadingScanProfile] = useState(false)
  const [targetList, setTargetList] = useState<any[] | null>()
  const [target, setTarget] = useState<any | null>(null)
  const [start, setStart] = useState<any>(null)
  const [time, setTime] = useState('')
  const [workflow, setWorkflow] = useState<any>(null)
  const [targetPage, setTargetPage] = useState<1 | 2>(1)
  const fetchTargetData = async (id?: string) => {
    setLoadingTarget(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/scan-profile/${
          id || workflow?.id
        }/available-targets/`
      )
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        setTargetList(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan-profile/${
            id || workflow?.id
          }/available-targets/ \n`,
          data
        )
      }
      setLoadingTarget(false)
    } catch (e) {
      setLoadingTarget(false)
    }finally{
      setLoadingScanProfile(false)
    }
  }
  const fetchDagsData = async () => {
    setLoadingScanProfile(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/scan-profile/`)
      errorHandling(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataScanDAG(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/scan-profile/ \n`,
          data
        )
      }
      setLoadingScanProfile(false)
    } catch (e) {
      setLoadingScanProfile(false)
    } finally{
      setLoadingScanProfile(false)
    }
  }
  const create = async () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/scan/`, {
        rate: time || 'once',
        start_time: start?.unix ? new Date(start.unix * 1000).toISOString() : null,
        target: target.id,
        scan_profile: workflow.id,
      })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          navigate('/scan')
          toast.success('Scan created successfully')
          dispatch(SetDataScan(null))
        } else {
          toast.error(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        toast.error(e.massage || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        setLoading(false)
      }).finally(()=>{
        setLoading(false)
      })
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (dags?.expireTime && dags.expireTime >= nowDate && dags?.data) {
    } else {
      fetchDagsData()
    }
  }, [])
  useEffect(() => {
    if (
      (target?.is_verified === true && workflow?.is_verify_required) ||
      (workflow?.is_verify_required === false && target)
    ) {
      document.getElementById('root')?.scrollIntoView({behavior: 'smooth', block: 'end'})
    }
  }, [target])
  return (
    <div className='w-100 d-flex gap-5 flex-column'>
      <div className='d-flex align-items-center justify-content-between w-100'>
        <h1 className='p-0 m-0'>{intl.formatMessage({id: 'Create New Scan'})}</h1>
        <Button
          onClick={() => {
            navigate('/scan')
          }}
          disabled={loading}
        >
          {intl.formatMessage({id: 'Back to Scan'})}
        </Button>
      </div>
      <div className=' d-flex w-100 gap-5'>
        <div className={`card p-3 w-100 ${loadingScanProfile && 'skeleton'}`}>
          <div className='d-flex align-items-center justify-content-between mb-5'>
            <span className='fs-1'>{intl.formatMessage({id: 'Scan profile'})}</span>
            <>
              <Link to={'/workFlow'}>
                <button
                  type='button'
                  className='btn btn-sm btn-info '
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  {intl.formatMessage({id: 'Create workflow'})}
                </button>
              </Link>
            </>
          </div>
          <>
            <div style={{maxHeight: '60vh', overflow: 'auto'}}>
              {dags?.data?.map((item: any, ind: number) => (
                <div
                  key={ind}
                  onClick={() => {
                    if (item.is_available) {
                      if (workflow?.id !== item.id) {
                        setWorkflow(item)
                        fetchTargetData(item?.id)
                        setTarget(null)
                      }
                    }
                  }}
                  className='card w-100 p-4 bg-gray-400 hover mt-4'
                  style={
                    workflow?.id === item.id
                      ? {
                          backgroundColor: '#43c443',
                          cursor: !item.is_available ? 'not-allowed' : 'pointer',
                        }
                      : {cursor: !item.is_available ? 'not-allowed' : 'pointer'}
                  }
                >
                  <div className='d-flex justify-content-between text-capitalize'>
                    <div className=' d-flex align-items-center gap-2'>
                      <h2>{item.name}</h2>
                      {!item.is_available && (
                        <>
                          <span
                            data-tooltip-id={'my-tooltip' + ind}
                            data-tooltip-content={intl.formatMessage({
                              id: 'You do not have permission to access this feature',
                            })}
                            style={{marginBottom: 7}}
                          >
                            <Lock className='svg-icon-2x svg-icon-danger ' />
                          </span>
                          <ReactTooltip
                            id={'my-tooltip' + ind}
                            style={{
                              maxWidth: 250,
                              fontSize: 12,
                              textAlign: 'center',
                              zIndex: 100,
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className='d-flex gap-3' data-bs-dismiss='modal' aria-label='Close'>
                      <Link to={'/workFlow'}>
                        <KTSVG
                          path='/media/visibel.svg'
                          className='svg-icon svg-icon-2x ms-2 svg-icon-white'
                        />
                      </Link>
                    </div>
                  </div>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </>
        </div>

        <div className={`card p-3 w-100 ${loadingTarget && 'skeleton'}`}>
          <div className='d-flex align-items-center justify-content-between mb-5'>
            <span className='fs-1'>{intl.formatMessage({id: 'Target'})}</span>
            {workflow && (
              <Button
                type='button'
                size='sm'
                variant='info'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_target'
                // disabled={loading}
              >
                {intl.formatMessage({id: 'Create New Target'})}
              </Button>
            )}
          </div>
          <>
            <div
              className=' d-flex flex-column '
              style={{maxHeight: '60vh ', overflow: 'auto', height: '100%', width: '100%'}}
            >
              <div
                className=' d-flex flex-column gap-2'
                style={{height: 'calc( 100% - 45px )', overflow: 'auto'}}
              >
                {targetList ? (
                  targetList.length ? (
                    targetList?.map((item: any, ind: number) => (
                      <div
                        key={item.id}
                        className='card w-100 px-4 py-2 bg-gray-400 hover  d-flex gap-2 flex-row align-items-center h-50px'
                        onClick={() => {
                          setTarget(item)
                        }}
                        style={
                          target?.id === item.id
                            ? {
                                backgroundColor:
                                  item.is_verified || workflow?.is_verify_required === false
                                    ? '#43c443'
                                    : '#d18320',
                                cursor: 'pointer',
                              }
                            : {cursor: 'pointer'}
                        }
                      >
                        {item?.title}
                        {!item.is_verified && workflow?.is_verify_required && (
                          <>
                            <span
                              className=' '
                              data-tooltip-id={'my-tooltip' + item.id}
                              data-tooltip-content={intl.formatMessage({
                                id: 'You must first verify this target.',
                              })}
                            >
                              <Warning className='svg-icon-warning svg-icon-2x image_change_theme' />
                            </span>
                            <ReactTooltip
                              id={'my-tooltip' + item.id}
                              style={{
                                maxWidth: 250,
                                fontSize: 12,
                                textAlign: 'center',
                                zIndex: 100,
                              }}
                            />
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <EmptyPage />
                  )
                ) : (
                  <div className=' d-flex align-items-center h-100 w-100'>
                    <h1 className=' text-center w-100'>
                      {intl.formatMessage({id: 'First select Scan profile '})}
                    </h1>
                  </div>
                )}
              </div>
              {target?.is_verified === false && workflow?.is_verify_required && (
                <Button
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_create_target'
                  onClick={() => {
                    setTargetPage(2)
                  }}
                  variant='info'
                  size='sm'
                  style={{width: 'fit-content', marginTop: 10}}
                >
                  {intl.formatMessage({id: 'Verify now'})}
                </Button>
              )}
            </div>
          </>
        </div>
      </div>
      {((target?.is_verified === true && workflow?.is_verify_required) ||
        (workflow?.is_verify_required === false && target)) && (
        <div className={`card p-3 w-100`}>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder'>{intl.formatMessage({id: 'Rate'})}</label>
            <select
              className={`form-select form-select-solid form-control form-control-lg form-control-solid `}
              data-kt-select2='true'
              data-placeholder='Severity Filter'
              data-allow-clear='true'
              name='rate'
              onChange={(e) => {
                setTime(e.target.value)
              }}
              value={time}
            >
              <option></option>
              <option value='once'>{intl.formatMessage({id: 'Once'})}</option>
              <option value='daily'>{intl.formatMessage({id: 'Daily'})}</option>
              <option value='weekly'>{intl.formatMessage({id: 'Weekly'})}</option>
              <option value='monthly'>{intl.formatMessage({id: 'Monthly'})}</option>
            </select>
            {!start && !time && (
              <div className='fv-plugins-message-container mt-2 text-danger'>
                <span role='alert'>{intl.formatMessage({id: 'Rate is required'})}</span>
              </div>
            )}
          </div>

          {workflow?.is_schedule_available && (
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder d-block'>
                {' '}
                {intl.formatMessage({id: 'Schedule'})}
              </label>
              <div className='d-flex'>
                <DatePicker
                  format='YYYY/MM/DD HH:mm'
                  className='bg-dark'
                  value={start}
                  plugins={[<TimePicker position='bottom' />]}
                  render={
                    <input
                      placeholder={intl.formatMessage({id: 'Schedule'})}
                      readOnly
                      className={'form-control form-control-lg form-control-solid'}
                      type='text'
                      name='start_time'
                      autoComplete='off'
                    />
                  }
                  onChange={(e) => {
                    setStart(e)
                  }}
                  calendar={lang === 'fa' ? persian : gregorian}
                  locale={lang === 'fa' ? persian_fa : gregorian_en}
                />
                <button
                  className={`col-auto border border-0 text-white p-0 m-0`}
                  style={{backgroundColor: 'transparent'}}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setStart(null)
                  }}
                >
                  <Trash className='svg-icon-2x svg-icon-danger' />
                </button>
              </div>
              {!start && !time && (
                <div className='fv-plugins-message-container mt-2 text-danger '>
                  <span role='alert'>{intl.formatMessage({id: 'Schedule is required'})}</span>
                </div>
              )}
            </div>
          )}
          <div className='d-flex gap-5 align-items-center'>
            <button className='btn btn-success' disabled={!time || loading} onClick={create}>
              {!loading ? (
                <span className='indicator-label'>{intl.formatMessage({id: 'Save'})}</span>
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Please wait'}) + '...'}

                  <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
                </span>
              )}
            </button>
            {workflow?.is_schedule_available && (
              <button className='btn btn-success' disabled={!start || loading} onClick={create}>
                {!loading ? (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'Start Instantly'})}
                  </span>
                ) : (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Please wait'}) + '...'}
                    <span className='spinner-border-sm spinner-border align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      )}
      <CreateTargetModal
        typeOptions={workflow?.available_types}
        needVerify={workflow?.is_verify_required}
        get={fetchTargetData}
        id={target?.id}
        setId={setTarget}
        setTargetPage={setTargetPage}
        targetPage={targetPage}
      />
    </div>
  )
}
