import React, {memo} from 'react'
import ColumnChart from './ColumnChart'
import InfoIcon from '../../images/InfoIcon'
import {Tooltip} from 'react-tooltip'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup/redux/Store'
interface Props {
  icon: JSX.Element
  header: string
  subHeader: string
  bgColor: string
  subColor: string
  chartColors: string[]
  data: number[]
  categories: string[]
  tooltip?: string
  loading: boolean
}
const Card = memo(
  ({
    icon = <i className='bi bi-bookmarks-fill fs-2x' />,
    header = '55',
    subHeader = 'SAP UI Progress',
    bgColor = 'bg-dark',
    subColor = ' ',
    chartColors = ['#96bded'],
    data = [10, 20, 30, 10],
    categories = ['Jan', 'Feb', 'Mar', 'Apr', 'Oct', 'Jul'],
    tooltip = 'Tooltip...',
    loading,
  }: Props) => {
    const intl = useIntl()
    const user = useSelector((state: RootState) => state.root.user)
    return (
      <div className='d-flex flex-column h-150px col-3'>
        <div className={`h-100px card card-body ${bgColor} ${loading && 'skeleton'} `}>
          <div className='row d-flex flex-row'>
            <div className='col-6'>
              {icon}
              <div
                className={`${subColor} fw-bolder fs-1 mt-6 mb-8`}
                style={!data.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
              >
                {header}
              </div>
            </div>

            <div
              className='col-6 h-100 mt-1'
              style={!data.length && !user?.is_user_scan ? {filter: 'blur(3px)'} : {}}
            >
              <ColumnChart
                categories={categories}
                height={80}
                data={data.length ? data : !user?.is_user_scan ? [60, 40, 60, 30] : []}
                colors={chartColors}
              />
            </div>
          </div>
          <div className='d-flex fw-bold text-igray'>
            {intl.formatMessage({id: subHeader})}
            <span
              data-tooltip-id={'my-tooltip' + subHeader}
              data-tooltip-content={intl.formatMessage({id: tooltip})}
            >
              <InfoIcon />
            </span>
            <Tooltip
              id={'my-tooltip' + subHeader}
              style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
            />
          </div>
        </div>
      </div>
    )
  }
)

export default Card
