import React, {useState, useEffect, useRef, useContext, useLayoutEffect} from 'react'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import PageLoading from '../loading/PageLoading'
import {DetailsDrawer} from './components/DetailsDrawer'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch, useSelector} from 'react-redux'
import Visibel from '../images/Visibel'
import Sort from '../images/Sort'
import {useNavigate} from 'react-router-dom'
import ConvertDate from '../../../Utils/ConvertDate'
import {RootState} from '../../../setup/redux/Store'
import {
  FilterOptionVulnerabilities,
  FilterSelectedVulnerabilities,
  SearchVulnerabilities,
  SetDataVulnerability,
} from '../../modules/auth'
import {useIntl} from 'react-intl'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import EmptyPage from '../emptyPage/EmptyPage'
import {VUL_DATA} from '../../modules/auth/redux/DEMO_DATA'
import usePermission from '../../../Utils/usePermission'

export const Vulnerabilities = () => {
  const [loading, setLoading] = useState(false)
  const lang = useLang()?.lang.selectedLang
  const errorHandling = useErrorHandling()
  const {isPermission, getPermission} = usePermission()
  const [id, setId] = useState<null | number>(null)
  const [sortState, setSortState] = useState('')
  const pageRender = useRef(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const vulnerabilityData = useSelector((state: RootState) => state?.root?.data?.dataVulnerability)
  const user = useSelector((state: RootState) => state?.root?.user)

  const filterOption = useSelector(
    (state: RootState) => state?.root?.filterOption?.vulnerabilities.options
  )
  const selected = useSelector(
    (state: RootState) => state?.root?.filterOption?.vulnerabilities.selected
  )
  const search = useSelector(
    (state: RootState) => state?.root?.filterOption?.vulnerabilities.search
  )
  const intl = useIntl()
  const handlePageClick = (event: {selected: number}) => {
    fetchVulnerabilityData({page: event.selected + 1, sort: sortState})
  }

  const getFilterOptions = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/filter/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(FilterOptionVulnerabilities(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/filter/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchVulnerabilityData = async ({page = 1, sort = '', filterProps = null}) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/?page=${page}${
          sort && '&ordering=' + sort
        }${search && '&host_search=' + search}${createQuery(filterProps)}`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      getPermission(data.status)
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataVulnerability(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/vulnerabilities/?page=${page} \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    const nowDate = new Date().getTime()
    if (vulnerabilityData?.expireTime && vulnerabilityData.expireTime >= nowDate) {
    } else {
      fetchVulnerabilityData({})
    }
    if (!filterOption) {
      getFilterOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function selectedFilter (category: string, value: string) {
    const copy = structuredClone(selected)
    copy[category] = value
    dispatch(FilterSelectedVulnerabilities(copy))
    if (process.env.REACT_APP_MODE !== 'demo') {
      fetchVulnerabilityData({page: 1, sort: sortState, filterProps: copy})
    }
  }
  function createQuery (props?: any) {
    if (props) {
      const keys = Object.keys(props).map((item) => {
        const value = props[item]
        if (value) {
          return `&${item}=${value}`
        } else {
          return ''
        }
      })
      return keys.join('')
    } else {
      const keys = Object.keys(selected).map((item) => {
        const value = selected[item]
        if (value) {
          return `&${item}=${value}`
        } else {
          return ''
        }
      })
      return keys.join('')
    }
  }
  function sort (sortItem: string) {
    if (!(process.env.REACT_APP_MODE === 'demo')) {
      setSortState(sortItem)
      fetchVulnerabilityData({page: vulnerabilityData?.data?.current_page, sort: sortItem})
    }
  }
  function handelSearch (value: string) {
    dispatch(SearchVulnerabilities(value))
  }

  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODE !== 'demo') {
      if (pageRender.current) {
        const delayDebounceFn = setTimeout(() => {
          fetchVulnerabilityData({page: 1, sort: sortState})
        }, 1000)
        return () => {
          clearInterval(delayDebounceFn)
        }
      }

      pageRender.current = true
    }
  }, [search])
  return (
    <>
      <DetailsDrawer id={id} setId={setId} />

      <>
        <div
          className={`row d-flex flex-row  mt-5 overflow-hidden position-relative ${
            loading && 'skeleton'
          }`}
        >
          {isPermission()}
          <div className='w-100 h-100'>
            <div className={`card card-body h-100 bg-body rounded-bottom-0`}>
              {/* start title */}
              <div className='w-100 pe-3 d-flex  flex-row justify-content-between align-items-start pt-2'>
                <h3 className=' '>{intl.formatMessage({id: 'Vulnerabilities'})}</h3>
              </div>
              {/* end title */}
              {/* start filters */}

              <div className='d-flex gap-5 my-10'>
                <div className='w-100'>
                  <label className='form-label fw-bold'>
                    {intl.formatMessage({id: 'Severity Filter'})}:
                  </label>

                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Severity Filter'
                      data-allow-clear='true'
                      value={selected['severity'] || ''}
                      onChange={(e) => {
                        selectedFilter('severity', e.target.value)
                      }}
                    >
                      <option value=''>{intl.formatMessage({id: 'No filter'})}</option>
                      {filterOption &&
                        filterOption?.severity?.map((item: any, ind: number) => (
                          <option key={ind} style={{textTransform: 'capitalize'}} value={item.name}>
                            {intl.formatMessage({id: item.name})}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='w-100'>
                  <label className='form-label fw-bold'>
                    {intl.formatMessage({id: 'Sensor Filter'})}:
                  </label>

                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Sensor Filter'
                      data-allow-clear='true'
                      value={selected['sensor'] || ''}
                      onChange={(e) => {
                        selectedFilter('sensor', e.target.value)
                      }}
                    >
                      <option value={''}>{intl.formatMessage({id: 'No filter'})}</option>
                      {filterOption &&
                        filterOption?.sensor?.map((item: any, ind: number) => (
                          <option key={ind} style={{textTransform: 'capitalize'}} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='w-100'>
                  <label className='form-label fw-bold'>
                    {intl.formatMessage({id: 'Host Filter'})}:
                  </label>

                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Host Filter'
                      data-allow-clear='true'
                      value={selected['domain'] || ''}
                      onChange={(e) => {
                        selectedFilter('domain', e.target.value)
                      }}
                    >
                      <option value={''}>{intl.formatMessage({id: 'No filter'})}</option>
                      {filterOption &&
                        filterOption?.domain?.map((item: any, ind: number) => (
                          <option key={ind} style={{textTransform: 'capitalize'}} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='w-100'>
                  <label className='form-label fw-bold'>{intl.formatMessage({id: 'Search'})}</label>

                  <div>
                    <input
                      type='text'
                      className='form-control w-100 mw-300px form-control-solid text-gray-700'
                      placeholder={intl.formatMessage({id: 'Search'})}
                      value={search}
                      onChange={(e) => {
                        handelSearch(e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* end filters */}

              {/* start titles */}
              <div className='w-100 m-0 p-0 pe-3'>
                <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-1 align-items-center'>
                  <div className='w-50 p-1 ps-2 h-auto'> {intl.formatMessage({id: 'Title'})} </div>
                  <div
                    className='w-25 p-1 ps-4 ms-3 h-auto d-flex  align-items-center pointer'
                    onClick={() => {
                      if (sortState === '-severity') {
                        sort('severity')
                      } else {
                        sort('-severity')
                      }
                    }}
                  >
                    {' '}
                    <span>{intl.formatMessage({id: 'Severity'})} </span>{' '}
                    <Sort
                      className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                      style={{rotate: sortState === '-severity' ? '180deg' : '0deg'}}
                    />
                  </div>
                  <div className='w-25 p-1 ms-3 h-auto d-flex  align-items-center pointer'>
                    <span>{intl.formatMessage({id: 'Target'})} </span>
                  </div>
                  <div
                    className='w-25 p-1 ms-3 h-auto d-flex  align-items-center pointer'
                    onClick={() => {
                      if (sortState === '-host') {
                        sort('host')
                      } else {
                        sort('-host')
                      }
                    }}
                  >
                    <span>{intl.formatMessage({id: 'Affected Asset'})} </span>
                    <Sort
                      className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                      style={{rotate: sortState === '-host' ? '180deg' : '0deg'}}
                    />
                  </div>
                  <div
                    className='w-25 p-1 ms-3 h-auto d-flex  align-items-center pointer'
                    onClick={() => {
                      if (sortState === '-timestamp') {
                        sort('timestamp')
                      } else {
                        sort('-timestamp')
                      }
                    }}
                  >
                    <span>{intl.formatMessage({id: 'Last Seen'})}</span>
                    <Sort
                      className='svg-icon svg-icon-1x ms-2 svg-icon-white '
                      style={{rotate: sortState === '-timestamp' ? '180deg' : '0deg'}}
                    />
                  </div>

                  <div className='w-50px p-0 ms-2 h-auto d-flex  pe-3'>
                    {' '}
                    {intl.formatMessage({id: 'View'})}{' '}
                  </div>
                </div>
              </div>
              {/* end titles */}
              {/* start body */}

              <div
                className={`w-100 h-100 d-flex flex-column gap-3 overflow-auto pe-3 m-0 min-h-200px`}
              >
                {(!vulnerabilityData?.data?.results?.length && !user?.is_user_scan
                  ? VUL_DATA.results
                  : vulnerabilityData?.data?.results
                )?.map((item: any, index: number) => (
                  <div
                    style={
                      !vulnerabilityData?.data?.results?.length
                        ? {filter: 'blur(3px)', cursor: 'pointer', minHeight: 64}
                        : {cursor: 'pointer', minHeight: 64}
                    }
                    key={item.id}
                    onClick={() => {
                      if (vulnerabilityData?.data?.results?.length) setId(item.id)
                    }}
                    className={`w-100 m-0 p-0 h-auto bg-gray-200 rounded-1 d-flex align-items-center  hover_Costume`}
                  >
                    <div className='w-100 d-flex p-1 align-items-center'>
                      <div className='w-50 p-1 h-auto d-flex align-items-center text_hide_width text-nowrap'>
                        {' '}
                        {lang === 'fa' ? item?.title_fa || 'منتظر داده باشید' : item.title}{' '}
                      </div>
                      <div className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center  text-capitalize text_hide_width text-nowrap'>
                        <div className=' d-flex justify-content-end'>
                          <div
                            className={`w-15px h-15px me-2 rounded-3 border-i${item.severity} border border-2 `}
                          />
                        </div>
                        {lang === 'fa' ? item?.severity_fa || 'منتظر داده باشید' : item.severity}
                      </div>
                      <span className='w-25 p-1 ms-3 h-auto d-flex flex-row align-items-center   text_hide_width text-nowrap'>
                        {item.target}
                      </span>
                      <div className='w-25 p-1 ms-3 h-auto d-flex  align-items-center text_hide_width text-nowrap'>
                        {item.host}
                      </div>
                      <div className='w-25 p-1 ms-3 h-auto d-flex  align-items-center text_hide_width text-nowrap'>
                        <ConvertDate date={item.timestamp} jalali={item.timestamp_jalali} />
                      </div>
                      <button
                        className={`w-50px border border-0 text-white bg-gray-200 p-0 ms-2 pe-3 d-flex-justify-content-center visible_icon`}
                        onClick={() => {
                          if (vulnerabilityData?.data?.results?.length)
                            navigate({
                              pathname: '/vulnerability-details',
                              search: `?id=${item.id}`,
                            })
                        }}
                      >
                        <Visibel className='svg-icon svg-icon-2x ms-2 svg-icon-white image_change_theme2' />
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {/* end body */}
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end bg-body pb-4 pe-4'>
          <ReactPaginate
            nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={vulnerabilityData?.data?.total_pages || 1}
            forcePage={vulnerabilityData?.data?.current_page - 1 || 0}
            previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        </div>
      </>
    </>
  )
}
