import { createSlice } from '@reduxjs/toolkit'
import { demoData } from './DEMO_DATA'
import { UserModel } from '../models/UserModel'
import axios from 'axios'
interface DATA {
  data: undefined | any,
  expireTime: null | number
}
interface BasicFilter {
  options: any,
  selected: any,
  sortAsc: string,
  sortDes: string,
  search: { name: string, value: string }[],
}
export interface IAuthState {
  data: {
    dataDashboard: DATA
    dataScope: DATA
    dataAsset: DATA
    dataVulnerability: DATA
    dataSSL: DATA
    dataDns: DATA
    dataSmtp: DATA
    dataScanDAG: DATA
    dataScan: DATA
    dataExposures: DATA
    dataDnsRecords: DATA
    dataEmails: DATA,
    dataPricePlans: DATA
    dataIp: DATA
    dataPorts: DATA
    dataPaymentHistory: DATA
    dataIntegrations: DATA
    dataTicket: DATA
    dataTargetTypes: DATA
    dataLeaked: DATA
    
    dataServices: DATA
    dataNotification: undefined | any[]
  }
  filterOption: {
    assets: BasicFilter,
    ssl: BasicFilter
    dnsRecord: BasicFilter,
    email: BasicFilter,
    port: BasicFilter,
    vulnerabilities: {
      options: any,
      selected: any,
      search: string
    },
    ip: BasicFilter,
    ticket: string,
  }
  aiAssistant: {
    vulnerabilitiesSelected: {
      id: null | string,
      title: null | string
    }
    questionList: { id: string, content: string }[]
  },
  user: UserModel | undefined | null
  accessToken: string | undefined | null
  newNotification: boolean,
}
const initialAuthState: IAuthState = {
  data: {
    dataDashboard: {
      data: undefined,
      expireTime: null
    },
    dataScope: {
      data: undefined,
      expireTime: null
    },
    dataAsset: {
      data: undefined,
      expireTime: null
    },
    dataVulnerability: {
      data: undefined,
      expireTime: null
    },
    dataDns: {
      data: undefined,
      expireTime: null
    },
    dataSSL: {
      data: undefined,
      expireTime: null
    },
    dataSmtp: {
      data: undefined,
      expireTime: null
    },
    dataScanDAG: {
      data: undefined,
      expireTime: null
    },
    dataScan: {
      data: undefined,
      expireTime: null
    },
    dataExposures: {
      data: undefined,
      expireTime: null
    },
    dataDnsRecords: {
      data: undefined,
      expireTime: null
    },
    dataEmails: {
      data: undefined,
      expireTime: null
    },
    dataPricePlans: {
      data: undefined,
      expireTime: null
    },
    dataIp: {
      data: undefined,
      expireTime: null
    },
    dataPorts: {
      data: undefined,
      expireTime: null
    },
    dataPaymentHistory: {
      data: undefined,
      expireTime: null
    },
    dataIntegrations: {
      data: undefined,
      expireTime: null
    },
    dataTicket: {
      data: undefined,
      expireTime: null
    },
    dataServices: {
      data: undefined,
      expireTime: null
    },
    dataTargetTypes: {
      data: undefined,
      expireTime: null
    },
    dataLeaked: {
      data: undefined,
      expireTime: null
    },
    dataNotification: undefined
  },
  filterOption: {
    assets: {
      options: null,
      selected: {},
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    ssl: {
      options: null,
      selected: {},
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    dnsRecord: {
      options: null,
      selected: {},
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    email: {
      options: null,
      selected: {},
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    port: {
      options: null,
      selected: {},
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    vulnerabilities: {
      options: null,
      selected: {},
      search: ''
    },
    ip: {
      options: null,
      selected: {},
      sortAsc: '',
      sortDes: '',
      search: [],
    },
    ticket: 'open',
  },
  aiAssistant: {
    vulnerabilitiesSelected: {
      id: null,
      title: null
    },
    questionList: []
  },
  user: null,
  accessToken: null,
  newNotification: false,
}


function generateExpireTime(time: number = 5) {
  const expireTime = new Date().getTime() + (time * 60 * 1000)
  return expireTime
}
const rootSlice = createSlice({
  name: 'Root',
  initialState: process.env.REACT_APP_MODE === 'demo' ? demoData : initialAuthState,
  reducers: {
    FilterSelectedAssets(state, action) {
      state.filterOption.assets.selected = action.payload
    },
    FilterOptionAssets(state, action) {
      state.filterOption.assets.options = action.payload
    },
    FilterSelectedSSL(state, action) {
      state.filterOption.ssl.selected = action.payload
    },
    FilterOptionSSL(state, action) {
      state.filterOption.ssl.options = action.payload
    },
    FilterSelectedPort(state, action) {
      state.filterOption.port.selected = action.payload
    },
    FilterOptionPort(state, action) {
      state.filterOption.port.options = action.payload
    },
    FilterSelectedDnsRecord(state, action) {
      state.filterOption.dnsRecord.selected = action.payload
    },
    FilterOptionDnsRecord(state, action) {
      state.filterOption.dnsRecord.options = action.payload
    },
    FilterSelectedEmail(state, action) {
      state.filterOption.email.selected = action.payload
    },
    FilterOptionEmail(state, action) {
      state.filterOption.email.options = action.payload
    },
    FilterSelectedIp(state, action) {
      state.filterOption.ip.selected = action.payload
    },
    FilterOptionIp(state, action) {
      state.filterOption.ip.options = action.payload
    },
    FilterSelectedVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.selected = action.payload
    },
    FilterSelectedTicket(state, action) {
      state.filterOption.ticket = action.payload
    },
    FilterOptionVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.options = action.payload
    },
    SearchVulnerabilities(state, action) {
      state.filterOption.vulnerabilities.search = action.payload
    },
    SearchAsset(state, action) {
      state.filterOption.assets.search = action.payload
    },
    SortAsset(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.assets.sortAsc = value
        state.filterOption.assets.sortDes = ''
      } else {
        state.filterOption.assets.sortDes = value
        state.filterOption.assets.sortAsc = ''
      }
    },
    SearchIp(state, action) {
      state.filterOption.ip.search = action.payload
    },
    SortIp(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.ip.sortAsc = value
        state.filterOption.ip.sortDes = ''
      } else {
        state.filterOption.ip.sortDes = value
        state.filterOption.ip.sortAsc = ''
      }
    },
    SearchEmail(state, action) {
      state.filterOption.email.search = action.payload
    },
    SortEmail(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.email.sortAsc = value
        state.filterOption.email.sortDes = ''
      } else {
        state.filterOption.email.sortDes = value
        state.filterOption.email.sortAsc = ''
      }
    },
    SearchDnsRecord(state, action) {
      state.filterOption.dnsRecord.search = action.payload
    },
    SortDnsRecord(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.dnsRecord.sortAsc = value
        state.filterOption.dnsRecord.sortDes = ''
      } else {
        state.filterOption.dnsRecord.sortDes = value
        state.filterOption.dnsRecord.sortAsc = ''
      }
    },
    SearchPort(state, action) {
      state.filterOption.port.search = action.payload
    },
    SortPort(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.port.sortAsc = value
        state.filterOption.port.sortDes = ''
      } else {
        state.filterOption.port.sortDes = value
        state.filterOption.port.sortAsc = ''
      }
    },
    SearchSSL(state, action) {
      state.filterOption.ssl.search = action.payload
    },
    SortSSL(state, action) {
      const { type, value } = action.payload
      if (type === 'asc') {
        state.filterOption.ssl.sortAsc = value
        state.filterOption.ssl.sortDes = ''
      } else {
        state.filterOption.ssl.sortDes = value
        state.filterOption.ssl.sortAsc = ''
      }
    },
    Logout_(state, action) {
      if (!(process.env.REACT_APP_MODE === 'demo')) {
        const fcmToken = localStorage.getItem('fcmToken')
        if (action.payload) {
          axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/jwt/logout/`,{
            fcm_token:fcmToken
          }).then((res) => {
          }).catch((e) => {
          })
          localStorage.removeItem("fcmToken")
          localStorage.removeItem("fcmTokenExpiry")
          return initialAuthState
        } else {
          localStorage.removeItem("fcmToken")
          localStorage.removeItem("fcmTokenExpiry")
          return initialAuthState
        }
      } else {
        localStorage.removeItem("fcmToken")
        localStorage.removeItem("fcmTokenExpiry")
        state.user = null
      }
    },
    UserLoaded(state, action) {
      const user = action.payload
      state.user = user
    },
    SetDataDashboard(state, action) {
      const payloadData = action.payload
      const newData: DATA = {
        data: payloadData,
        expireTime: generateExpireTime()
      }
      state.data.dataDashboard = newData
    },
    SetDataScope(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataScope = newData
    },
    SetDataAsset(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataAsset = newData
    },
    SetDataVulnerability(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataVulnerability = newData
    },
    SetDataSSL(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataSSL = newData
    },
    SetDataDNS(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataDns = newData
    },
    SetDataSmtp(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataSmtp = newData
    },
    SetDataScan(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataScan = newData
    },
    SetDataScanDAG(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataScanDAG = newData
    },
    SetDataExposures(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataExposures = newData
    },
    SetDataDnsRecords(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataDnsRecords = newData
    },
    SetDataEmails(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataEmails = newData
    },
    SetDataPricePlans(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataPricePlans = newData
    },
    SetDataServices(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataServices = newData
    },
    SetDataIp(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataIp = newData
    },
    SetDataNotification(state, action) {
      const data = action.payload
      state.data.dataNotification = data

    },
    SetDataTargetTypes(state, action) {
      const data = action.payload
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
        state.data.dataTargetTypes = newData
      
    },
    SetDataLeaked(state, action) {
      const data = action.payload
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
        state.data.dataLeaked = newData
      
    },
    SetDataTicket(state, action) {
      const data = action.payload
      if (data === null) {
        state.data.dataTicket = {
          data: null,
          expireTime: null
        }
      } else {
        const newData: DATA = {
          data: data,
          expireTime: generateExpireTime()
        }
        state.data.dataTicket = newData
      }
    },

    SetDataIntegrations(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataIntegrations = newData
    },
    SetDataPorts(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataPorts = newData
    },
    SetDataPaymentHistory(state, action) {
      const data = action.payload
      const newData: DATA = {
        data: data,
        expireTime: generateExpireTime()
      }
      state.data.dataPaymentHistory = newData
    },
    SetAiAssistantVulnerabilitiesID(state, action) {

      state.aiAssistant.vulnerabilitiesSelected = action.payload
    },
    SetAiAssistantQuestionList(state, action) {
      state.aiAssistant.questionList = action.payload
    },
    ChangeUserStatus(state, action) {
      if (state.user)
        state.user.is_user_new = action.payload
    },
    ChangeUserHasNewNotification(state, action) {
      if (state.user)
        state.user.has_new_notification = action.payload
    },
    ChangeNotificationStatus(state, action) {
      const status = action.payload
      state.newNotification = status
    }
  },
})

export const {
  Logout_,
  UserLoaded,
  SetDataDashboard,
  SetDataScope,
  SetDataAsset,
  SetDataVulnerability,
  SetDataSSL,
  SetDataDNS,
  SetDataSmtp,
  SetDataScan,
  SetDataScanDAG,
  SetDataExposures,
  SetDataDnsRecords,
  SetDataEmails,
  SetDataPricePlans,
  FilterOptionAssets,
  FilterSelectedAssets,
  FilterSelectedVulnerabilities,
  FilterOptionVulnerabilities,
  SearchVulnerabilities,
  SetAiAssistantVulnerabilitiesID,
  SetAiAssistantQuestionList,
  ChangeUserStatus,
  FilterSelectedDnsRecord,
  FilterOptionDnsRecord,
  FilterSelectedEmail,
  FilterOptionEmail,
  FilterSelectedIp,
  FilterOptionIp,
  SetDataIp,
  SearchAsset,
  SortAsset,
  SearchIp,
  SortIp,
  SearchEmail,
  SortEmail,
  SearchDnsRecord,
  SortDnsRecord,
  SearchSSL,
  SortSSL,
  FilterSelectedSSL,
  FilterOptionSSL,
  SetDataIntegrations,
  FilterSelectedPort,
  FilterOptionPort,
  SetDataPorts,
  SearchPort,
  SortPort,
  SetDataPaymentHistory,
  SetDataTicket,
  SetDataServices,
  FilterSelectedTicket,
  ChangeNotificationStatus,
  ChangeUserHasNewNotification,
  SetDataNotification,
  SetDataTargetTypes,
  SetDataLeaked,
} = rootSlice.actions
export default rootSlice.reducer


