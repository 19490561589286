import React from 'react'

export default function FormatAndConvertAmount(input: string | number) {
  let number: number 
  if (typeof input === 'string') {
    number = parseFloat(input);
    if (isNaN(number)) {
      return "ورودی نامعتبر است";
    }
  } else {
    number = input;
  }

  const tomanValue = number / 10;

  return new Intl.NumberFormat().format(tomanValue);
}



