import React from 'react'

const BarChartAsset = ({data}:{data:any[]}) => {
  const trustData = [...data]
  trustData.splice(3,1)
  const maxChartWidth = Math.max(...trustData?.map((item) => item.finding))

  return (
    <div className={`bg-body h-100 card p-2`}>
      <div className={`w-100 h-100 p-0 pt-4 d-flex flex-column align-items-center`}>
        {trustData?.map((item) => (
          <div
            key={item.title}
            className='w-100 d-flex flex-row row align-items-center pt-3 pb-3'
          >
            <div className='col-3 fw-bolder  d-flex  justify-content-end'>
              {item.title}
            </div>
            <div className='col-6 d-flex flex-row align-items-center'>
              <div className='progress rounded-start-0 w-100 h-20px'>
                <div
                  className='progress-bar m-0 d-flex align-items-center px-1 h-20px'
                  role='progressbar'
                  style={{
                    height: 12,
                    width: `${(item.finding / maxChartWidth) * 100}%`,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    backgroundColor: item.finding > 80 ? '#00fa00' : '#ffff00',
                    color: '#000',
                    fontWeight:'bolder'
                  }}
                >
                  {item.finding}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BarChartAsset
