import axios from 'axios'
import useErrorHandling from './useErrorHandling'
import { useDispatch } from 'react-redux'
import { Logout_, UserLoaded } from '../app/modules/auth'

export default function useGetUserData() {
    const errorHandling = useErrorHandling()
    const dispatch = useDispatch()
    const getUserData = async () => {
        if (!(process.env.REACT_APP_MODE === 'demo')) {
            try {
                const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/auth/users/me/`)
                errorHandling(data.status) //logout when invalid token && redirect 404
                if (data.status >= 200 && data.status < 300) {
                    dispatch(
                        UserLoaded({
                            created_at: '2022-07-14T11:37:39.000000Z',
                            email: data.data.email,
                            email_verified_at: '2022-07-14T11:37:39.000000Z',
                            first_name: '',
                            id: data.data.id,
                            last_name: '',
                            updated_at: '2022-07-14T11:37:39.000000Z',
                            is_user_scan: data.data.first_scan,
                            is_user_new: false,
                            has_new_notification:data.data.has_new_notification
                            // is_user_new: data.data.is_user_new
                        })
                    )
                    return data.data
                } else {
                    console.error(
                        `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/auth/users/me/ \n`,
                        data
                    )
                    dispatch(Logout_(false))
                    return false
                }
            } catch (e) {
                console.log(e)
                dispatch(Logout_(false))
                return false
            }
        } else {
            dispatch(
                UserLoaded({
                    created_at: '2022-07-14T11:37:39.000000Z',
                    email: ' demo@netbaan.net',
                    email_verified_at: '2022-07-14T11:37:39.000000Z',
                    first_name: 'matin',
                    id: '1',
                    last_name: 'chaghaei',
                    updated_at: '2022-07-14T11:37:39.000000Z',
                    is_user_new:true
                })
            )
        }
    }
    return getUserData
}
