import axios from 'axios'
import React, {useLayoutEffect, useState} from 'react'
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom'
import useErrorHandling from '../../../Utils/useErrorHandling'
import './verify.scss'
import PageLoading from '../loading/PageLoading'
import {useIntl} from 'react-intl'
export default function Verify () {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(0)
  const errorHandling = useErrorHandling()
  const navigate = useNavigate()
  const location = useLocation().search
  const intl = useIntl()
  const fetchData = async (params: string) => {
    setLoading(true)
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/subscription/verify-payment/${params}/`
      )
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        setData(1)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/subscription/verify-payment/${params} \n`,
          data
        )
        setData(-1)
      }
      setLoading(false)
    } catch (e) {
      setData(-1)
      setLoading(false)
    } finally {
      setTimeout(() => {
        navigate('/payment-history')
      }, 5000)
    }
  }

  useLayoutEffect(() => {
    fetchData(location)
  }, [])
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <div>
          <div className='container'>
            {data === 1 && (
              <div className='row justify-content-center'>
                <div className='col-md-5 w-50'>
                  <div className='message-box _success '>
                    <i className='fa fa-check-circle' aria-hidden='true'></i>
                    <h2>
                      {intl.formatMessage({id: 'Your payment has been successfully completed'})}
                    </h2>
                  </div>
                </div>
              </div>
            )}

            {data === -1 && (
              <div className='row justify-content-center'>
                <div className='col-md-5'>
                  <div className='message-box _success _failed'>
                    <i className='fa fa-times-circle' aria-hidden='true'></i>
                    <h2>{intl.formatMessage({id: 'Payment failed'})}</h2>
                    <p>
                      {intl.formatMessage({
                        id: 'The amount deducted from your account will be returned to your account within 72 hours',
                      })}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
