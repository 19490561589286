

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.withCredentials = true
  axios.interceptors.request.use(
    (config: any) => {
      config.validateStatus = function (status: number) {
        if (status === 401 || (status >= 200 && status < 300) || status === 404 || status === 403) {
          return status
        }
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
}
