import React, {useLayoutEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import ConvertDate from '../../../Utils/ConvertDate'
import {useIntl} from 'react-intl'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {useDispatch} from 'react-redux'
import {SetDataPaymentHistory, SetDataPricePlans} from '../../modules/auth'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import HistoryDetails from './HistoryDetails'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'

export default function History () {
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  const direction = useLang()?.lang.dir
  const dispatch = useDispatch()
  const paymentHistory = useSelector((state: RootState) => state.root.data.dataPaymentHistory)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState<string | number>('')
  const fetchPaymentHistory = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/payments/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataPaymentHistory(data.data))
        console.log(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/payments/ \n`,
          data
        )
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    // const nowDate = new Date().getTime()
    // if (paymentHistory?.expireTime && paymentHistory.expireTime >= nowDate) {
    // } else {
    // }
    fetchPaymentHistory()
  }, [])
  return (
    <div className='w-100 h-100'>
      <div className=' card w-100'>
        <h1 className='p-5 pb-0 directions-rtl'> {intl.formatMessage({id: 'Payment history'})}</h1>
        <hr />
        <div className={`p-5 ${loading && 'skeleton'}`}>
          <div className='d-flex gap-10 justify-content-between mb-5 px-5'>
            <div className='w-100 mw-300px'>
              <input
                type='text'
                className='form-control w-100  form-control-solid text-gray-700'
                placeholder={intl.formatMessage({id: 'Search'})}
              />
            </div>
            <div className='w-100 mw-300px'>
              <div>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Severity Filter'
                  data-allow-clear='true'
                >
                  <option value='all'>{intl.formatMessage({id: 'All'})}</option>
                </select>
              </div>
            </div>
          </div>
          <table className='table '>
            <thead>
              <tr className='bg-gray-300'>
                <th
                  scope='col'
                  className={`${
                    direction !== 'rtl' ? 'rounded-start-pill' : 'rounded-end-pill'
                  }  text-center fs-5`}
                >
                  <strong> # {intl.formatMessage({id: 'Plan name'})}</strong>
                </th>
                <th scope='col' className='text-center fs-5'>
                  <strong> {intl.formatMessage({id: 'Amount'})}</strong>
                </th>
                <th scope='col' className='text-center fs-5'>
                  <strong> {intl.formatMessage({id: 'Discount amount'})}</strong>
                </th>
                <th scope='col' className='text-center fs-5'>
                  <strong> {intl.formatMessage({id: 'Final amount'})}</strong>
                </th>
                <th scope='col' className='text-center fs-5'>
                  {' '}
                  <strong>{intl.formatMessage({id: 'Payment status'})}</strong>
                </th>
                <th
                  scope='col'
                  className={`rounded-0 ${
                    direction === 'rtl' ? 'rounded-start-pill' : 'rounded-end-pill'
                  }   text-center fs-5`}
                >
                  {' '}
                  <strong>{intl.formatMessage({id: 'Action'})}</strong>{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory?.data?.map((item: any) => (
                <tr key={item.id} className='border-bottom border-bottom-1'>
                  <th scope='row' className='text-center '>
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center min-h-35px'>
                      {' '}
                      <strong>{item.plan_name}</strong>
                    </div>
                  </th>
                  <td className='text-center '>
                    {' '}
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center h-100'>
                      <p>
                        {FormatAndConvertAmount(item.amount)}/{intl.formatMessage({id: 'IR-T'})}
                      </p>
                    </div>
                  </td>
                  <td className='text-center '>
                    {' '}
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center min-h-35px'>
                      <p>
                        {FormatAndConvertAmount(item.discount_amount)}/
                        {intl.formatMessage({id: 'IR-T'})}
                      </p>
                    </div>
                  </td>
                  <td className='text-center '>
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center min-h-35px'>
                      {' '}
                      {FormatAndConvertAmount(item.final_amount)}/{intl.formatMessage({id: 'IR-T'})}
                    </div>
                  </td>
                  <td className='text-center '>
                    <div className=' h-auto d-flex justify-content-center p-1 align-items-center min-h-35px'>
                      <strong
                        className={`border ${
                          item.payment_status === 'pending'
                            ? 'border-warning bg-warning'
                            : item.payment_status === 'failed'
                            ? 'border-danger bg-danger'
                            : 'border-success bg-success'
                        }  rounded bg-opacity-25 p-1 px-3`}
                      >
                        {intl.formatMessage({id: item.payment_status || 'Failed'})}
                      </strong>
                    </div>
                  </td>
                  <td className='d-flex gap-2 justify-content-center border-0'>
                    <Button
                      type='button'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_invoice'
                      size='sm'
                      variant='outline'
                      onClick={() => {
                        setId(item.id)
                      }}
                    >
                      {intl.formatMessage({id: 'Invoice details'})}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <HistoryDetails id={id} />
    </div>
  )
}
