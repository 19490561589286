import React from 'react'
import NotPermission from '../app/pages/components/NotPermission'

export default function usePermission () {
  const [isNotPermission, setIsNotPermission] = React.useState(false)
  function getPermission (status: number | string) {
    if (status === 403 || status === '403') {
      setIsNotPermission(true)
    }
  }
  function isPermission () {
    if (isNotPermission) {
      return <NotPermission />
    } else {
      return null
    }
  }

  return {isPermission, getPermission}
}
