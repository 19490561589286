import axios from "axios";


export const sendFCMToServer = async (token: string): Promise<boolean> => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/fcm/`, {
      registration_id: token,
      type: "web",
    });
    return true;
  } catch (error: any) {
    throw error;
  }
};
