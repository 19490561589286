import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import image0 from '../../images/1.webp'
import list from '../../images/list.png'
import px500 from '../../images/500px.png'
import ReactPaginate from 'react-paginate'
import {LeakedData} from './Data'
import usePermission from '../../../../Utils/usePermission'

export default function Leaked () {
  const [isOpen, setIsOpen] = useState<null | number>(null)
  const intl = useIntl()
  const { isPermission, getPermission } = usePermission()
  const handlePageClick = (event: {selected: number}) => {
    // fetchDomainsData(`?page=${event.selected + 1}`)
  }
  return (
    <>
      <div className='w-100  p-4 bg-body overflow-hidden position-relative'>
      {isPermission()}
        <h1>{intl.formatMessage({id: 'Leaked Data'})}</h1>
        <div className='w-100  d-flex pt-5 position-relative'>
          <div className=' w-100 '>
            <div className={`w-100  overflow-visible  m-0 pb-8`}>
              <div className='d-flex flex-column align-items-center'>
                {/* start titles */}
                <div className='w-100 m-0  '>
                  <div className='w-100 d-flex fs-5 fw-bold bg-gray-400 rounded-1 mb-4 p-2 pt-1 pb-1 justify-content-between align-items-center'>
                    <div className='w-50 p-1 h-auto'>
                      <div className='w-100  h-auto d-flex justify-content-center align-items-center pointer'>
                        <span>{intl.formatMessage({id: 'Email'})}</span>
                      </div>
                    </div>
                    <div className='w-50 p-1 h-auto justify-content-center'>
                      <div className='w-100  h-auto d-flex justify-content-center align-items-center pointer'>
                        {' '}
                        <span>{intl.formatMessage({id: 'Leaked in Databases'})}</span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end titles */}
                {LeakedData.map((item, index: number) => (
                  <div
                    key={index}
                    className='w-100 m-0 p-0 h-auto bg-gray-200 rounded-1   mb-3 d-flex flex-column gap-2 overflow-hidden hover_Costume'
                    onClick={() => {
                      if (index === isOpen) {
                        setIsOpen(null)
                      } else {
                        setIsOpen(index)
                      }
                    }}
                  >
                    <div className={` d-flex justify-content-start align-items-center`}>
                      <div className='w-100 d-flex p-2 pt-1 pb-1 justify-content-between align-items-center'>
                        <div className='w-50 p-1 d-flex  h-auto justify-content-center'>
                          {' '}
                          {item.mail}{' '}
                        </div>
                        <div className='w-50 p-1 h-auto d-flex justify-content-center'>
                          {item.leaked}
                        </div>
                      </div>
                    </div>
                    <div className={`w-100 px-2 ${index !== isOpen ? 'dns_items_dont_show' : ''}`}>
                      
                      <div className='bg-gray-400 w-100 card d-flex mb-2 p-5  gap-10'>
                        <div className='w-115px h-100px'>
                          <img src={list} width={115} height={100} />
                        </div>

                        <div>
                          <p className=' fs-4'>
                            <strong>Data Enrichment Exposure From PDL Customer :</strong> In October
                            2019,{' '}
                            <span className='custom_text_decoration'>
                              security researchers Vinny Troia and Bob Diachenko identified an
                              unprotected Elasticsearch server holding 1.2 billion records of
                              personal data
                            </span>{' '}
                            The exposed data included an index indicating it was sourced from data
                            enrichment company People Data Labs (PDL) and contained 622 million
                            unique email addresses. The server was not owned by PDL and it's
                            believed a customer failed to properly secure the database. Exposed
                            information included email addresses, phone numbers, social media
                            profiles and job history data.
                          </p>
                          <p className=' fs-4'>
                            <strong>Compromised data:</strong> Email addresses, Employers,
                            Geographic locations, Job titles, Names, Phone numbers, Social media
                            profiles
                          </p>
                        </div>
                      </div>
                      <div className='bg-gray-400 w-100 card d-flex mb-2 p-5  gap-10'>
                        <div className='w-115px h-100px'>
                          <img src={image0} width={115} height={100} />
                        </div>
                        <div>
                          <p className=' fs-4'>
                            <strong>Raychat:</strong> In January 2021, the now defunct Iranian
                            social media platform{' '}
                            <span className='custom_text_decoration'>
                              Raychat suffered a data breach that exposed 939 thousand unique email
                              addresses.
                            </span>{' '}
                            The data included names, IP addresses, browser user agent strings and
                            passwords stored as bcrypt hashes. The data was provided to HIBP by{' '}
                            <span className='custom_text_decoration'>dehashed.com.</span>{' '}
                          </p>
                          <p className=' fs-4'>
                            <strong>Compromised data:</strong> Browser user agent details, Email
                            addresses, IP addresses, Names, Passwords
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-flex justify-content-end bg-body  pe-4 rounded-bottom'>
                <ReactPaginate
                  nextLabel={`${intl.formatMessage({id: 'Next'})} >`}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  //   pageCount={assetData?.data?.total_pages}
                  //   forcePage={assetData?.data?.current_page - 1}
                  pageCount={1}
                  forcePage={0}
                  previousLabel={`< ${intl.formatMessage({id: 'Previous'})}`}
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  breakLabel='...'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
