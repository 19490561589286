

import SubSection from './SubSection'
import Arr80 from '../../images/Arr80'
import { useIntl } from 'react-intl'
interface Props {
  isOpen: null|number
  setIsOpen: React.Dispatch<React.SetStateAction<null | number>>
  ind: number
  data:any
}
const Section = ({data, ind, isOpen, setIsOpen}:Props) => {
  const subsections = Object.keys(data)
    .filter((item) => item !== 'title')
    ?.map((item) => data[item])
  const pass = subsections.filter((item) => item.status === 'pass')
  const fail = subsections.filter((item) => item.status === 'fail')
  const intl = useIntl()
  return data?(
    <div
      className={` bg-body mb-5 card p-5 `}
    >
      <div className='w-100 d-flex fs-5 fw-bold rounded-1  justify-content-between align-items-center'>
        <h2 className='  p-0 m-0'>
          <strong>{data.title}</strong>
        </h2>
        <div className='d-flex  align-items-center gap-4'>
          <div className='d-flex gap-1 align-items-center'>
            <img alt='' className='w-20px h-20px' src='/media/pass.svg' />
            <span>{intl.formatMessage({id: 'Pass'})} : {pass?.length}</span>
          </div>
          <div className='d-flex gap-1 align-items-center'>
            <img alt='' className='w-20px h-20px' src='/media/fail.svg' />
            <span>{intl.formatMessage({id: 'Fail'})} : {fail?.length}</span>
          </div>
          <div
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle svg_provider'
            onClick={() => {
              ind === isOpen ? setIsOpen(null) : setIsOpen(ind)
            }}
          >
            <Arr80 className={`svg-icon-1 transition image_change_theme2 ${
                isOpen === ind ? 'icon_items' : 'icon_items-90'
              } `}/>
          </div>
        </div>
      </div>
      <div id='dns_items'  className={` overflow-hidden dns_items ${!(isOpen === ind)&&'dns_items_dont_show'}`}>
        <div className='d-flex  flex-column gap-5 overflow-hidden mt-5'>
          {subsections?.map((subsection,ind) => (
            <SubSection key={subsection?.title+ind} data={subsection} />
          ))}
        </div>
      </div>
    </div>
  ):null
}

export default Section
