import React, {memo, useState} from 'react'

import axios from 'axios'

import {useIntl} from 'react-intl'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {KTSVG} from '../../../_metronic/helpers'
import {Alert, Button} from 'react-bootstrap'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'

interface Props {
  details: null | any
  setDetails: React.Dispatch<React.SetStateAction<any | null>>
}
const Modal = memo(({details, setDetails}: Props) => {
  const [loadingDiscount, setLoadingDiscount] = useState(false)
  const [err, setErr] = useState('')
  const [loadingPay, setLoadingPay] = useState(false)
  const [copen, setCopen] = useState('')
  const [newPrice, setNewPrice] = useState('')
  const [savedCopen, setSavedCopen] = useState('')
  const errorHandling = useErrorHandling()
  const intl = useIntl()
  function createBodyPay () {
    if (savedCopen) {
      return {
        plan: details.id,
        payment_method: 'zarinpal',
        discount_code: savedCopen,
      }
    } else {
      return {
        plan: details.id,
        payment_method: 'zarinpal',
      }
    }
  }
  function PostPay () {
    setLoadingPay(true)

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/subscription/subscribe/`, createBodyPay())
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          PostPayId(res.data.payment_id)
        } else {
          setErr(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
          setLoadingPay(false)
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data?.error)
        setErr(
          e.response.data.detail.replace(/[\[\]"']/g, ' ') ||
            intl.formatMessage({id: 'Sorry, an error has occurred'})
        )
        setLoadingPay(false)
      })
  }
  function PostPayId (id: string) {
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/subscription/pay/`, {
        payment: id,
      })
      .then((res) => {
        errorHandling(res.status)
        if (res.status >= 200 && res.status < 300) {
          setDetails(null)
          setCopen('')
          setNewPrice('')
          const URL = res.data.payment_url
          if (URL) {
            window.location.href = URL
          }
        } else {
          setErr(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e?.response?.data)
        setErr(
          e.response.data.detail.replace(/[\[\]"']/g, ' ') ||
            intl.formatMessage({id: 'Sorry, an error has occurred'})
        )
      })
      .finally(() => {
        setLoadingPay(false)
      })
  }
  function applyDiscount () {
    setLoadingDiscount(true)
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/plans/apply-discount/`, {
        plan: details.id,
        discount_code: copen,
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setNewPrice(res.data.discounted_price)
          setSavedCopen(copen)
        } else {
          console.log('err', res?.data?.detail)
          setErr(res?.data?.detail || intl.formatMessage({id: 'Sorry, an error has occurred'}))
        }
      })
      .catch((e) => {
        console.log('message \n', e.response.data.detail)

        setErr(
          e.response.data.detail.replace(/[\[\]"']/g, ' ') ||
            intl.formatMessage({id: 'Sorry, an error has occurred'})
        )
      })
      .finally(() => {
        setLoadingDiscount(false)
      })
  }
  return (
    <>
      <div
        className='modal fade '
        id='kt_modal_edit'
        style={{backdropFilter: 'blur(3px)'}}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          setDetails(null)
          setCopen('')
          setNewPrice('')
        }}
      >
        <>
          <Alert
            show={!!err}
            variant='danger'
            className=' position-absolute '
            style={{width: 'fit-content', top: 20, left: '50%', transform: 'translateX(-50%)'}}
          >
            <Alert.Heading
              className='w-100  d-flex gap-10  justify-content-between '
              style={{width: 'fit-content'}}
            >
              {err}
              <div onClick={() => setErr('')}>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </Alert.Heading>
          </Alert>
        </>
        <div
          className='modal-dialog '
          style={{minWidth: '300px ', top: '100px'}}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className='modal-content '>
            <div className='modal-header'>
              <h3 className='modal-title'> {'1403/08/20 10:30'}</h3>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  setDetails(null)
                  setCopen('')
                  setNewPrice('')
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 image_change_theme2'
                />
              </div>
            </div>

            <div className='modal-body'>
              <div className='d-flex flex-column gap-5 align-items-center'>
                <h1 className=' text-primary'>{details?.name}</h1>

                <h3>
                  {newPrice
                    ? FormatAndConvertAmount(newPrice)
                    : details?.discounted_price
                    ? FormatAndConvertAmount(details?.discounted_price)
                    : FormatAndConvertAmount(details?.price)}{' '}
                  {intl.formatMessage({id: 'IR-T'})}
                </h3>

                <div className='fv-row mb-10 d-flex gap-1'>
                  <input
                    onChange={(e) => {
                      setCopen(e.target.value)
                    }}
                    value={copen}
                    placeholder={intl.formatMessage({id: 'Discount code'})}
                    className={'form-control form-control-lg form-control-solid'}
                    autoComplete='off'
                  />
                  <Button disabled={!copen || loadingDiscount} onClick={applyDiscount}>
                  {intl.formatMessage({id: 'Use'})}
                  </Button>
                </div>
                {process.env.REACT_APP_MODE === 'demo' && (
                  <p className='text-danger'>
                    {' '}
                    {intl.formatMessage({id: 'This feature is not available in demo mode'})}
                  </p>
                )}
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-success'
                  //   data-bs-dismiss='modal'
                  //   aria-label='Close'
                  disabled={process.env.REACT_APP_MODE === 'demo' || loadingPay}
                  onClick={PostPay}
                >
                  {intl.formatMessage({id: 'payment'})}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
export default Modal
