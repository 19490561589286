import clsx from 'clsx'
import {FC, useLayoutEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useIntl} from 'react-intl'
import {RootState} from '../../../../setup/redux/Store'
import {useSelector} from 'react-redux'
import useErrorHandling from '../../../../Utils/useErrorHandling'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {SetDataNotification} from '../../../../app/modules/auth'
import ConvertDate from '../../../../Utils/ConvertDate'
const HeaderNotificationsMenu: FC = () => {
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  // const HasNewNotification = useSelector(
  //   (state: RootState) => state.root.user?.has_new_notification
  // )
  const isNotification = useSelector((state: RootState) => state.root.newNotification)
  const data = useSelector((state: RootState) => state.root.data.dataNotification)
  const [loading, setLoading] = useState(false)
  const fetchNotificationData = async () => {
    setLoading(true)
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/notification/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataNotification(data.data))
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/notification/ \n`,
          data
        )
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  useLayoutEffect(() => {
    if (isNotification) {
      fetchNotificationData()
    }
  }, [isNotification])
  useLayoutEffect(() => {
    fetchNotificationData()
  }, [])

  const intl = useIntl()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className=' fw-bold px-9 mt-10 mb-6'>
          {intl.formatMessage({id: 'Notifications'})} <span className='fs-8 opacity-75 ps-3'></span>
        </h3>
      </div>
      <hr className='m-0' />

      <div className={`${loading && 'skeleton'}`}>
        <div className='scroll-y mh-325px min-h-100px my-5 px-8'>
          {(data || [])?.map((alert, index) => (
            <div key={`alert${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span
                    className={clsx(
                      'symbol-label',
                      `bg-light-${alert?.is_read ? 'primary' : 'warning'}`
                    )}
                  >
                    {' '}
                    <KTIcon
                      iconName={alert.is_read ? 'directbox-default' : 'sms'}
                      className={`fs-2 text-${alert?.is_read ? 'primary' : 'warning'}`}
                    />
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    {alert?.title}
                  </a>
                  <div className='text-gray-500 fs-7'>{alert?.description}</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>
                <ConvertDate date={alert?.created_at} />
              </span>
            </div>
          ))}
        </div>

        {/* <div className='py-3 text-center border-top'>
            <Link
              to='/crafted/pages/profile'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              {intl.formatMessage({id: 'View All'})}
              <KTIcon iconName='arrow-right' className='fs-5' />
            </Link>
          </div> */}
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
