import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {PRICE_PLAN_CARD_DATA, PRICE_PLAN_TABLE} from '../../modules/auth/redux/DEMO_DATA'
import Joyride, {CallBackProps} from 'react-joyride'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup/redux/Store'
import Modal from './Modal'
import axios from 'axios'
import useErrorHandling from '../../../Utils/useErrorHandling'
import {SetDataPricePlans} from '../../modules/auth'
import {useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import InfoIcon from '../images/InfoIcon'
import {Tooltip as ReactTooltip} from 'react-tooltip'
import Question from '../images/Question'
import Chat from '../images/Chat'
import FormatAndConvertAmount from '../../../Utils/FormatAndConvertAmount'
import Arr001 from '../components/Arr001'
import Check from '../images/Ckeck'
const steps = [
  {
    target: '.service-feature-one',
    content: 'این ویژگی 1 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-two',
    content: 'این ویژگی 2 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-three',
    content: 'این ویژگی 3 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-4',
    content: 'این ویژگی 4 در صفحه سرویس است.',
  },
  {
    target: '.service-feature-5',
    content: 'این ویژگی 5 در صفحه سرویس است.',
  },
]

function find (name: string) {
  if (name.toLowerCase() === 'basic') {
    return 0
  }
  if (name.toLowerCase() === 'pro') {
    return 1
  }

  return 2
}
export default function PricePlan () {
  const intl = useIntl()
  const isUserNew = useSelector((state: RootState) => state.root.user?.is_user_new)
  const pricePlanData = useSelector((state: RootState) => state.root.data.dataPricePlans)
  const [details, setDetails] = useState<any>()
  const [ind, setInd] = useState<undefined | number>()
  const [run, setRun] = useState(false)
  const [productSelectedIndex, setProductSelectedIndex] = useState<null | number>(null)
  const navigate = useNavigate()
  const errorHandling = useErrorHandling()
  const dispatch = useDispatch()
  const front = useRef<null | any>(null)
  const back = useRef<null | any>(null)
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status} = data

    // When the tour is finished, redirect to the scan Page
    if (status === 'finished' || status === 'skipped') {
      setRun(false)
      navigate('/scan')
    }
  }
  const fetchPricePlans = async () => {
    try {
      const data = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/plans/`)
      errorHandling(data.status) //logout when invalid token && redirect 404
      if (data.status >= 200 && data.status < 300) {
        dispatch(SetDataPricePlans(data.data))
        console.log(data.data)
      } else {
        console.error(
          `error in get data from ${process.env.REACT_APP_API_ENDPOINT}/plans/ \n`,
          data
        )
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    const nowDate = new Date().getTime()
    if (pricePlanData?.expireTime && pricePlanData.expireTime >= nowDate) {
    } else {
      fetchPricePlans()
    }
    if (isUserNew) {
      setRun(true)
      setInd(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div
      className='mb-10 overflow-hidden h-750px'
      style={{
        minHeight:
          productSelectedIndex === null
            ? front?.current?.clientHeight
            : back?.current?.clientHeight,
      }}
    >
      <div
        className='w-100 position-relative  inner_box'
        style={productSelectedIndex !== null ? {transform: 'rotateY(180deg)'} : {transform: 'rotateY(0deg)'}}
      >
        <div className=' w-100 vh-100  front_card card p-5 ' ref={front}>
          <div className=' '>
            <h1 className='fs-2hx fw-bold mb-5 text-center'>
              {' '}
              {intl.formatMessage({id: 'Choose Your Product'})}
            </h1>
            <div className='text-gray-600 fw-semibold fs-5 text-center'>
              {intl.formatMessage({id: 'If you need more info about our products, please check'})}{' '}
              <Link to={'/'} className='link-primary fw-bold'>
                {intl.formatMessage({id: 'Products Guidelines'})}
              </Link>
              .
            </div>
          </div>
          <div className='row g-10 my-10 align-items-stretch w-100'>
            {(PRICE_PLAN_CARD_DATA).map(
              (items: any, ind: number) => (
                <div
                  className='col-xl-4'
                  key={items.id}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setProductSelectedIndex(ind)
                  }}
                >
                  <div className='service-feature-4 h-100'>
                    <div className='d-flex h-100 align-items-center h-100'>
                      <div className='w-100 h-100  d-flex flex-column justify-content-between rounded-3 bg-light bg-opacity-75 py-15 px-10'>
                        <div>
                          <div className='mb-7 text-center d-flex flex-column align-items-center'>
                            <h1 className='text-gray-900 mb-5 fw-bolder text-capitalize'>
                              {items.name}
                            </h1>
                            <div
                              className='text-gray-600 fw-semibold mb-5'
                              style={{
                                maxWidth: '50ch',
                                height: '60px',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                whiteSpace: 'normal',
                              }}
                            >
                              {items.description}
                            </div>
                          </div>

                          <div className='w-100 '>
                            {PRICE_PLAN_CARD_DATA[find(items.name)].features?.map((item, index) => (
                              <div className='d-flex align-items-center mb-5' key={index}>
                                <span className='fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3'>
                                  {intl.formatMessage({id: item.title})}
                                  {/* {item.title} */}
                                </span>
                                {item.status ? (
                                  <i className='ki-duotone ki-check-circle fs-1 text-success'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                ) : (
                                  <i className='ki-duotone ki-cross-circle fs-1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className='w-100 card p-5 back_card_y' ref={back}>
          {/* title */}
          <div className='service-feature-one'>
            <h1 className='fs-2hx fw-bold mb-5 text-center'>
              {' '}
              {intl.formatMessage({id: 'Choose Your Plan'})}
            </h1>
            <div className='text-gray-600 fw-semibold fs-5 text-center'>
              {intl.formatMessage({id: 'If you need more info about our pricing, please check'})}{' '}
              <Link to={'/'} className='link-primary fw-bold'>
                {intl.formatMessage({id: 'Pricing Guidelines'})}
              </Link>
              .
            </div>
          </div>
          {/* plan card */}
          <div className='service-feature-two'>
            <div className='d-flex flex-column gap-4  w-100'>
              <button
                className={`col-auto border border-0   p-0 m-0 position-absolute `}
                style={{rotate: '180deg', background: 'unset'}}
                onClick={() => {
                  setProductSelectedIndex(null)
                }}
              >
                <Arr001 fill={'#fff'} className={'image_change_theme'} />
              </button>
              <div className='row mt-10 align-items-stretch justify-content-center w-100'>
                {(pricePlanData.data?.length && pricePlanData.data )?.map(
                  (items: any, ind: number, arr: any[]) => (
                    <div
                      className='col-xl-4 p-0 p-xl-0 position-relative'
                      key={items.id}
                      style={{
                        filter: !pricePlanData.data?.length ? 'blur(3px)' : 'blur(0px)',
                        transform:
                          ind === 0
                            ? 'translateX(15px)'
                            : ind ===  1
                            ? 'translateX(0) scale(1.04)'
                            : 'translateX(-15px) ',
                            zIndex: ind === 1?100:1
                      }}
                    >
                      <div className='service-feature-4 h-100 '>
                        <div className='d-flex h-100 align-items-center h-100'>
                          <div
                            className={`w-100 h-100  d-flex flex-column justify-content-between ${
                              ind === 1 ? 'bg-primary-subtle' : 'bg-light-subtle'
                            }   py-15 px-10 border shadow-sm`}
                            style={{borderRadius: '30px'}}
                          >
                            <div>
                              <div className='mb-7  d-flex flex-column '>
                                <h1
                                  className='text-gray-900 mb-5 fw-bolder text-capitalize'
                                  style={{width: 'fit-content', fontSize: '35px'}}
                                >
                                  {items.name}
                                </h1>
                                <div
                                  className='text-gray-600 fw-semibold mb-5'
                                  style={{
                                    // textAlign:'start',
                                    maxWidth: '50ch',
                                    height: '40px',
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    whiteSpace: 'normal',
                                  }}
                                >
                                  {items.description}
                                </div>
                                {!items.is_active ? (
                                  <>
                                    {!!items.discounted_price && (
                                      <span
                                        className=' fw-bold  text-gray-600'
                                        style={{textDecoration: 'line-through', fontSize: 20}}
                                      >
                                        {FormatAndConvertAmount(items.price)}
                                        {}
                                      </span>
                                    )}
                                    <div className='text-center d-flex align-items-end '>
                                      <span className='fs-2x fw-bold text-primary'>
                                        {items.discounted_price
                                          ? FormatAndConvertAmount(items.discounted_price)
                                          : FormatAndConvertAmount(items.price)}
                                      </span>
                                      <span className='mb-2 text-primary'>
                                        {intl.formatMessage({id: 'IR-T'})}
                                      </span>
                                      <span className='fs-7 fw-semibold opacity-50 mb-2'>
                                        /
                                        <span data-kt-element='period' className=' text-capitalize'>
                                          {items.duration_days} {intl.formatMessage({id: 'day'})}
                                        </span>
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <div style={{height: 67}}>
                                    <Chat className='svg-icon-primary svg-icon-3x image_change_theme2' />
                                    <p className=' fw-bold p-0 m-0'>
                                      {intl.formatMessage({id: 'Contact us for more information'})}
                                    </p>
                                  </div>
                                )}
                                <div className='mt-5'>
                                  {!items.is_active ? (
                                    <button
                                      onClick={() => {
                                        setDetails(items)
                                      }}
                                      className='btn btn-sm btn-primary align-self-center rounded-pill px-5'
                                      type='button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_edit'
                                      disabled={!pricePlanData.data?.length}
                                    >
                                      {intl.formatMessage({id: 'Select'})}
                                    </button>
                                  ) : (
                                    <button
                                      className='btn btn-sm btn-info align-self-center  rounded-pill px-5'
                                      onClick={() => {
                                        navigate('/ticket')
                                      }}
                                    >
                                      {intl.formatMessage({id: 'Contact us'})}
                                    </button>
                                  )}
                                </div>
                              </div>

                              <div className='w-100 mb-10'>
                                {productSelectedIndex !== null&&PRICE_PLAN_CARD_DATA[productSelectedIndex].plans[find(items.name)].features?.map(
                                  (item, index) => (
                                    <div
                                      className='d-flex gap-2 align-items-center mb-5'
                                      key={index}
                                    >
                                      <Check className='svg-icon-md image_change_theme2 ' />
                                      <span className='fw-semibold  text-gray-800  pe-3'>
                                        {intl.formatMessage({id: item.title})}
                                        {/* {item.title} */}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {/* plan table */}
          <div className='service-feature-5'>
            <table className='table align-middle table-striped gy-7 mt-7'>
              <tbody>
                <tr>
                  <th className='card-rounded-start'>
                    <div className='fw-bolder d-flex align-items-center ps-9 fs-3'>
                      {intl.formatMessage({id: 'Feature'})}
                    </div>
                  </th>
                  {PRICE_PLAN_CARD_DATA.map((item, ind, arr) => (
                    <td key={ind} className={`${ind === arr.length - 1 && 'card-rounded-end'}`}>
                      <div className='flex-root d-flex justify-content-center text-capitalize fs-3 fw-bolder'>
                        {item.name}
                      </div>
                    </td>
                  ))}
                </tr>

                {PRICE_PLAN_TABLE.map((item, ind) => (
                  <tr key={ind}>
                    <th className={'card-rounded-start'}>
                      <div
                        className='fw-bolder gap-2 d-flex align-items-center ps-9 fs-3'
                        style={item.group ? {color: 'red'} : {}}
                      >
                        {intl.formatMessage({id: item.title})}
                        {!item.group && (
                          <>
                            <span
                              data-tooltip-id={'my-tooltip' + ind}
                              data-tooltip-content={intl.formatMessage({
                                id: item.description,
                              })}
                            >
                              <Question className='svg-icon-2x image_change_theme2' />
                            </span>
                            <ReactTooltip
                              id={'my-tooltip' + ind}
                              style={{maxWidth: 250, fontSize: 12, textAlign: 'center'}}
                            />
                          </>
                        )}
                      </div>
                    </th>
                    {item.data.map((i, index, arr) => (
                      <td
                        key={index}
                        className={`${
                          index === arr.length - 1 ? 'card-rounded-end ' : 'no_reduce'
                        }`}
                      >
                        <div className='flex-root d-flex justify-content-center'>
                          {i === true ? (
                            <i className='ki-duotone ki-check-circle fs-1 text-success'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          ) : i === false ? (
                            <i className='ki-duotone ki-cross-circle fs-1'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          ) : (
                            <span>{intl.formatMessage({id: i || ' '})}</span>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Joyride Component */}
      <Joyride
        stepIndex={ind}
        steps={steps}
        run={run}
        disableOverlay={false}
        disableScrolling={true}
        showProgress={true}
        disableOverlayClose={true}
        continuous={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{next: 'بعدی', skip: 'رد کردن'}}
        spotlightPadding={5}
        styles={{
          options: {
            zIndex: 10000, // Ensure Joyride is above other elements
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark overlay on the whole page
          },
        }}
      />
      <Modal details={details} setDetails={setDetails} />
    </div>
  )
}
